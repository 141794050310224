import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientManagmentRoutingModule } from './client-managment-routing.module';
import { ClientManagmentComponent } from './client-managment.component';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { ClientInfoComponent } from './client-info/client-info.component';
import { TimeLineComponent } from './time-line/time-line.component';
import { NotesComponent } from './notes/notes.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FilesComponent } from './files/files.component';
import { ProductsComponent } from './products/products.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ActivityComponent } from './activity/activity.component';
import { TaskBoardComponent } from './task-board/task-board.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PipesModule } from 'src/app/core/layouts/pipes/pipes.module';
import { PagesModule } from "../../pages.module";
import { MentionModule } from 'angular-mentions';


@NgModule({
  declarations: [
    ClientManagmentComponent,
    ClientInfoComponent,
    TimeLineComponent,
    NotesComponent,
    FilesComponent,
    ProductsComponent,
    ConversationsComponent,
    ActivityComponent,
    TaskBoardComponent
  ],
  exports: [
    ActivityComponent,
    TaskBoardComponent,
    ConversationsComponent
  ],
  imports: [
    CommonModule,
    ClientManagmentRoutingModule,
    MaterialModule,
    NgbNavModule,
    NgxDropzoneModule,
    InfiniteScrollModule,
    NgSelectModule,
    PipesModule,
    CKEditorModule,
    PagesModule,
    MentionModule
  ]
})
export class ClientManagmentModule { }
