import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit,OnDestroy {
  activeId = 1;
  loggedInUser: any;
  settings_menu = [
    {
      title: "General",
      slug: "general_settings",
      route: "../settings",
      icon: "mib",
      id: 1
    },
    {
      title: "Users & Controls",
      slug: "user_controls",
      route: "users&controls",
      icon: "mib",
      id: 2
    },
    // { <!--only for chatbot website-->
    //   title: "Communication Settings",
    //   slug: "communications",
    //   route: "sms",
    //   icon: "mib",
    //   id: 3
    // },
    // { <!--only for chatbot website-->
    //   title: "Payment Settings",
    //   slug: "payments_settings",
    //   route: "payments",
    //   icon: "mib",
    //   id: 4
    // },
    {
      title: "Custom Fields",
      slug: "form_fields",
      route: "custom-fields",
      icon: "mib",
      id: 6
    },
    {
      title: "Tags",
      slug: "tag",
      route: "tags",
      icon: "mib",
      id: 7
    },
    // {
    //   title: "Custom Doman URL",
    //   slug: "custom_domain",
    //   route: "",
    //   icon: "mib",
    //   id: 8
    // },
    // {
    //   title: "Integrations",
    //   slug: "integration",
    //   route: "integrations",
    //   icon: "mib",
    //   id: 9
    // },
    // {
    //   title: "Data Administration",
    //   slug: "data_administration",
    //   route: "data-administration",
    //   icon: "mib",
    //   id: 10
    // },
    // {
    //   title: "Email",
    //   slug: "email",
    //   route: "",
    //   icon: "mib",
    //   id: 11
    // },
    // {
    //   title: "Whats app",
    //   slug: "whats_app",
    //   route: "",
    //   icon: "mib",
    //   id: 12
    // },
    // {
    //   title: "Developer",
    //   slug: "developer",
    //   route: "",
    //   icon: "mib",
    //   id: 12
    // },
    // {
    //   title: "Security",
    //   slug: "security",
    //   route: "",
    //   icon: "mib",
    //   id: 13
    // },
    // {
    //   title: "Biling",
    //   slug: "biling",
    //   route: "billing",
    //   icon: "mib",
    //   id: 14
    // },
    // {
    //   title: "SMS Notifications",
    //   slug: "sms_notifications",
    //   route: "sms",
    //   icon: "mib",
    //   id: 15
    // },
    {
      title: "Email notifications",
      slug: "email_notifications",
      route: "email-notifications",
      icon: "mib",
      id: 16
    },
    // {
    //   title: "Whatsapp",
    //   slug: "whatsapp",
    //   route: "whatsapp-notificaions",
    //   icon: "mib",
    //   id: 17
    // },
    {
      title: "Activity",
      slug: "activity",
      route: "activity",
      icon: "mib",
      id: 18
    },
    {
      title: "Lost Reasons",
      slug: "lost-reasons",
      route: "lost-reasons",
      icon: "mib",
      id: 19
    },
    {
      title: "Manage My Plans",
      slug: "features",
      route: "subscription",
      icon: "mib",
      id: 5
    },
    {
      title: "My Subscriptions",
      slug: "plan_details",
      route: "plan_details",
      icon: "mib",
      id: 20
    }
  ];

  constructor(private router: Router) {
    this.settings_menu.forEach((item: any) => {
      if (this.router.url.includes(item.route) && item.route !== '') {
        this.activeId = item.id;
      }
    });
    this.handlePopState = this.handlePopState.bind(this);
    window.addEventListener('popstate', this.handlePopState);
  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }
  handlePopState() {
    const arrayOfUrl = window.location.pathname.split('/').filter(item => item !== '');
    this.settings_menu.forEach((data) => {
      if (arrayOfUrl.length === 1) {
        this.activeId = 1;
      } else if (arrayOfUrl[arrayOfUrl.length - 1] === data.route) {
        this.activeId = data.id;
      }
    });
  }
  ngOnDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  }
}
