import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { mobile_json, time_zone_json } from 'src/app/core/data/mobile_json';
import { DEVELOPERJSON, STAFF, TEAMS } from 'src/app/core/data/settings_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { DayOffModelComponent } from '../day-off-model/day-off-model.component';
import { WorkHoursComponent } from '../work-hours/work-hours.component';
import { BadgesComponent } from '../../shared/badges/badges.component';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { NAVIGATION_KEYS, maximumNumberAllowed, onOnlyNumbers } from 'src/app/core/common/common-functions';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-users-control',
  templateUrl: './users-control.component.html',
  styleUrls: ['./users-control.component.scss']
})
export class UsersControlComponent implements OnInit {
  errorjson = ERRORJSON[0];
  staffjson = STAFF[0];
  teamjson = TEAMS[0];
  developerjson = DEVELOPERJSON[0];
  working_hours_model!: NgbModalRef;
  badges_model!: NgbModalRef;
  activeId = 1;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  table_cols = [
    {
      name: 'Employee Id',
      is_visible: true
    },
    {
      name: 'Name',
      is_visible: true
    },
    {
      name: 'Email',
      is_visible: true
    },
    {
      name: 'Department',
      is_visible: true
    },
    {
      name: 'Branch',
      is_visible: true
    },
    {
      name: 'Role',
      is_visible: true
    },
    {
      name: 'Actions',
      is_visible: this.loggedInUser && (this.loggedInUser.data.role === 'ADMIN' || this.loggedInUser.data.role === 'MANAGER')
    }];//'Last Login',
  day_table_cols = ['Day Off Title', 'Staff Name', 'Date Range', 'Time Range', 'Status'];//'Action'
  developer_table_cols = ['Name', 'Email', 'Phone Number', 'API key', 'Action'];
  staffListArray: any[] = [];
  templateStaffListType = true;
  addStaffForm: any;
  submited = false;
  mobilejson = mobile_json;
  public bankFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  timezonejson = time_zone_json;
  rolesList: any[] = [];
  teamsList: any[] = [];
  table_teams_cols = [
    {
      name: 'Department',
      is_visible: true
    },
    {
      name: 'Users',
      is_visible: true
    },
    {
      name: 'Actions',
      is_visible: (this.loggedInUser && this.loggedInUser.data.role === 'ADMIN')
    }];
  passwordBox = false;
  templateTeamsList = true;
  isteamSubmitted = false;
  addTeamForm: any;
  selected_group: any;
  teamsSelectedStaff: any[] = [];
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  @ViewChild('myFirstStaffInput') myFirstStaffInput!: ElementRef;
  pageCount = 1;
  hide = true;
  branches: any;
  daysListArray: any[] = [
    // {
    //   title: "Health Issues",
    //   date: new Date(),
    //   staff_name: "Rockey",
    //   status: true
    // }
  ];
  badgesList: any = [];
  dayoffModel!: NgbModalRef;
  startValue: any;
  endValue: any;
  timeFilter: any;
  aptServiceList: any[] = [];
  staff_id = '';
  setupData: any;
  totalStaff: any;
  name_search = '';
  staffList: any[] = [];
  staff_details: any;
  noDataFound = false;
  noDeptFound = false;
  display_no_data = false;
  tableSkeletonLoader = true;
  developerInfo: any;
  addDeveloperForm: any;
  developerPswRestForm: any;
  isdeveloperSubmitted = false;
  @ViewChild('tp') _matTooltip!: MatTooltip;

  constructor(private formBuilder: FormBuilder, private settingService: SettingsService,
    private modelservice: NgbModal, private confirmationMsz: ConfirmationMszService,
    private authService: AuthService, private setupService: SetUpService) { }

  ngOnInit() {
    // this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.filteredBanks.next(this.mobilejson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.getTeams(false);
    this.getStaff();
  }

  tabChange(ev: any) {
    this.activeId = ev.nextId;
    this.name_search = '';
    if (this.activeId === 1) {
      this.getTeams(false);
      this.templateTeamsList = true;
    } else if (this.activeId === 2) {
      this.getInfo();
      this.getStaff();
      this.templateStaffListType = true;
    } else if (this.activeId === 4) {
      this.getDayOffs();
      this.getStaffForDayoff();
    } else if (this.activeId === 5) {
      this.getDeveloperInfo();
    }
  }

  getStaff() {
    this.tableSkeletonLoader = true;
    this.settingService.getStaff_infoByPagination(this.pageCount).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.staffListArray = resp.data;
      if (this.staffListArray.length === 0) {
        this.display_no_data = true;
      } else {
        this.display_no_data = false;
      }
      console.log(this.staffListArray);
      this.totalStaff = resp.count;
    });
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.pageCount = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.pageCount -= 1;
    }
    this.getStaff();
  }

  addStaff(type: boolean, data?: any) {
    this.templateStaffListType = type;
    this.getRoles(true);
    this.getTeams(true);
    this.getBadges();
    if (!this.templateStaffListType) this.InitilizeSaff_Form();
    if (data) {
      this.settingService.getStaff_infoById(data.id).subscribe((resp: any) => {
        this.InitilizeSaff_Form(resp.data);
      });
    }
    setTimeout(() => {
      this.myFirstStaffInput?.nativeElement.focus();
    }, 0);
  }

  InitilizeSaff_Form(data?: any) {
    this.submited = false;
    this.addStaffForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      fname: [data ? data.fname : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z-'.\s]{1,}$/)]],
      lname: [data ? data.lname : '', [Validators.pattern(/^[A-Za-z][A-Za-z-'\s]*$/)]],
      email: [{ value: data ? data.email : '', disabled: data?.id ? true : false }, Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      phone_number: [data ? data.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])],
      ccode: [data ? data.ccode : this.authService.getCompanyData().ccode, [Validators.required]],
      password: [data ? data.password : '', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)])],
      // password_type: [''],
      role_id: [data ? data.role : '', Validators.required],
      department: [data ? data.department : '', Validators.required],
      designation: [data ? data.designation : ''],
      specialisation: [data ? data.specialisation : ''],
      qualification: [data ? data.qualification : '', Validators.pattern(/^[A-Za-z][A-Za-z0-9&-'.,\s]*$/)],
      experience: [data ? data.experience : ''],
      gender: [data ? data.gender : ''],
      age: [data ? data.age : ''],
      badges: [data ? data.badges : []],
      enable_for_booking: [data ? data.enable_for_booking : true],
      locations: [data ? data.locations[0] : '', Validators.required],
      time_zone: [data ? data.time_zone : this.authService.getCompanyData()?.timezone, Validators.required]
    });
    if (!data) this.getLocationDetails();
    if (data?.id) {
      const badge_ids = data.badges.map((item: any) => item.id);
      this.addStaffForm.controls['badges'].setValue(badge_ids);
      this.addStaffForm.controls['password'].clearValidators();
      this.addStaffForm.controls['password'].updateValueAndValidity();
    }
  }

  get f() {
    return this.addStaffForm.controls;
  }

  getBadges() {
    this.settingService.getBadges('STAFF').subscribe({
      next: (resp: any) => {
        this.badgesList = resp.data;
        // this.addStaffForm.patchValue({ badge: this.badgesList[0].id });
      }
    });
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    const filteredTimezones = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);

    this.filteredTimezoneBanks.next(filteredTimezones);
  }

  addnewStaffSubmit() {
    this.submited = true;
    if (this.addStaffForm.valid) {
      if (this.addStaffForm.controls['locations']?.length > 0) this.addStaffForm.controls['locations'].setValue('');
      this.addStaffForm.patchValue({ locations: [this.addStaffForm.value.locations], email: this.addStaffForm.value.email?.toLowerCase() });
      if (this.addStaffForm.value.id) {
        this.settingService.putStaffInfo(this.addStaffForm.value.id, this.addStaffForm.value).subscribe(() => {
          this.templateStaffListType = true;
          this.addStaffForm.reset();
          this.getStaff();
          this.settingService.getStaff_info('force').subscribe(() => { //@typescript-eslint/no-empty-function
          });
        });
      } else {
        this.settingService.postStaffInfo(this.addStaffForm.value).subscribe(() => {
          this.templateStaffListType = true;
          this.addStaffForm.reset();
          this.getStaff();
          this.settingService.getStaff_info('force').subscribe(() => { //@typescript-eslint/no-empty-function
          });
        });
      }
    }
  }

  getLocationDetails() {
    this.settingService.getLocationSettings().subscribe((resp: any) => {
      this.branches = resp.data.filter((ele: any) => ele.status);
    });
  }

  onStaffStatusChange(data: any) {
    const body = {
      status: data.status
    };
    this.settingService.putStaffInfo(data.id, body).subscribe(() => {//@typescript-eslint/no-empty-function
    });
  }

  addDayoff() {
    this.dayoffModel = this.modelservice.open(DayOffModelComponent);
    this.dayoffModel.componentInstance.dayoff_type = 'organisation';
    this.dayoffModel.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === 'organisation') this.getDayOffs();
    });
  }

  onDateChange(ev: any, type: string) {
    if (type === 'start') {
      this.startValue = this.convert(ev.value);
    } else if (type === 'end') {
      this.endValue = this.convert(ev.value);
    } else {
      this.timeFilter = ev;
    }
    if (this.startValue && this.endValue) {
      this.tableSkeletonLoader = true;
      this.settingService.getDayoffFilters(this.startValue, this.endValue, this.timeFilter).subscribe((resp: any) => {
        this.tableSkeletonLoader = false;
        this.daysListArray = resp.data;
        if (this.daysListArray.length === 0) {
          this.noDataFound = true;
        } else {
          this.noDataFound = false;
        }
      });
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  protected filterBanks() {
    if (!this.mobilejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.mobilejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.mobilejson.filter(bank => (bank.country_code.toLowerCase().indexOf(search) > -1) ||
        bank.country.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onTimezoneDestroy.next();
    this._onTimezoneDestroy.complete();
  }

  isPassword() {
    // if (this.addStaffForm.value.password_type === "auto") {
    //   this.passwordBox = false;
    //   this.addStaffForm.controls['password'].clearValidators();
    //   this.addStaffForm.controls['password'].updateValueAndValidity();
    // } else {
    //   this.addStaffForm.controls['password'].setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)]));
    //   this.addStaffForm.controls['password'].updateValueAndValidity();
    //   this.passwordBox = true;
    // }
  }

  addTeam(type: boolean, data?: any) {
    this.templateTeamsList = type;
    if (!this.templateTeamsList) this.InitilizeTeam_Form(data);
    if (data) {
      // this.settingService.getStaff_infoById(data.id).subscribe((resp: any) => {
      //   this.InitilizeTeam_Form(resp.data);
      // })
    }
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }

  InitilizeTeam_Form(data?: any) {
    this.addTeamForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)]],
      description: [data ? data.description : '']
    });
    if (data) {
      this.teamsSelectedStaff = this.staffListArray.filter(item => {
        return data.users.some((ele: any) => {
          return ele === item.id;
        });
      });
    }
  }

  get teamForm() {
    return this.addTeamForm.controls;
  }

  addnewTeamSubmit() {
    this.isteamSubmitted = true;
    const staff: any[] = [];
    this.teamsSelectedStaff.forEach((ele) => {
      staff.push(ele.id);
    });
    const body = {
      // staff: staff,
      ...this.addTeamForm.value
    };
    if (this.addTeamForm.valid) {
      this.templateTeamsList = true;
      if (this.addTeamForm.value.id) {
        this.settingService.putTeams(body).subscribe(() => {
          this.getTeams(false);
        });
      } else {
        this.settingService.postTeams(body).subscribe(() => {
          this.getTeams(false);
        });
      }
    }
  }

  getTeams(active: boolean) {
    this.tableSkeletonLoader = true;
    this.settingService.getTeams(active).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.teamsList = resp.data;
      this.teamsList.length === 0 ? this.noDeptFound = true : this.noDeptFound = false;
    });
  }

  confirmTeams(deleteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteTeams(deleteId);
      }
    });
  }

  deleteTeams(id: string) {
    this.settingService.deleteTeams(id).subscribe(() => {
      this.getTeams(false);
    });
  }

  openStaffModel(model: any) {
    if (this.teamsSelectedStaff) this.selected_group = this.teamsSelectedStaff;
    this.modelservice.open(model, { size: 'md' });
  }

  addToList() {
    this.teamsSelectedStaff = this.staffListArray.filter(item => {
      return this.selected_group.some((ele: any) => {
        return ele === item.id;
      });
    });
    this.modelservice.dismissAll();
  }

  getRoles(active: boolean) {
    this.settingService.getRolesPermissions(active).subscribe((resp: any) => {
      this.rolesList = [];
      resp.data.filter((ele: any)=>{//<!--only for chatbot website-->
        if(ele.name !== 'STAFF' && ele.name !== 'MANAGER') this.rolesList.push(ele);
      });
    });
  }

  openWorkingHoursModel(staff_id: string) {
    this.working_hours_model = this.modelservice.open(WorkHoursComponent, { size: 'lg', scrollable: true, windowClass: 'workinghours-modal' });
    this.working_hours_model.componentInstance.type = 'appointment';
    this.working_hours_model.componentInstance.staff_id = staff_id;
  }

  openDayOffModel(staffId: any) {
    this.dayoffModel = this.modelservice.open(DayOffModelComponent, { scrollable: true });
    this.dayoffModel.componentInstance.dayoff_type = 'staff';
    this.dayoffModel.componentInstance.staffId = staffId;
  }

  getDayOffs() {
    this.tableSkeletonLoader = true;
    this.settingService.getDayoff().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.daysListArray = resp.data;
      this.daysListArray.length === 0 ? (this.noDataFound = true) : this.noDataFound = false;
    });
  }

  getStaffForDayoff() {
    this.settingService.getStaff_info().subscribe((resp: any) => {
      this.staffList = resp?.data;
    });
  }

  onStaffSelect(ev: any) {
    if (!ev.value) return;
    this.tableSkeletonLoader = true;
    this.settingService.getDayoffFilterBasedStaff(ev.value).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.daysListArray = resp.data;
      this.daysListArray = resp.data;
      if (this.daysListArray.length === 0) {
        this.noDataFound = true;
      } else {
        this.noDataFound = false;
      }
    });

  }

  manageBadges() {
    this.badges_model = this.modelservice.open(BadgesComponent, { scrollable: true, windowClass: 'badge-modal', size: 'lg' });
    this.badges_model.componentInstance.badge_type = 'STAFF';
    this.badges_model.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      console.log(receivedEntry);
      this.getBadges();
    });
  }

  openAptSerivce(model: any, staff: any) {
    this.staff_id = staff.id;
    this.staff_details = staff;
    this.getAptServices();
    this.modelservice.open(model, { size: 'lg' });
  }

  getAptServices() {
    this.aptServiceList = [];
    this.settingService.getAssignedServices(this.staff_id).subscribe((resp: any) => {
      resp.data.forEach((element: any) => {
        element.consultation_data.forEach((data: any) => {
          this.aptServiceList.push({ consultation_data: data, name: element.name });
        });
      });
    });
  }

  onChange(ev: any, item: any) {
    const body = {
      "consultation_id": item.consultation_data.consultation_id,
      "mode": item.consultation_data.mode,
      "staff_special_price": item.consultation_data.staff_data[0].staff_special_price,
      "status": ev ? ev?.target?.checked : item.consultation_data.staff_data[0].status,
      "staff_name": this.staff_details.fname + " " + this.staff_details.lname
    };
    this.settingService.putAssignedServices(item.consultation_data.staff_data[0].id, body).subscribe(() => {
      this.getAptServices();
    });
  }

  onStatusChange(item: any) {
    this.settingService.putDayoff(item).subscribe();
  }

  getInfo() {
    this.setupService.getSetupInformation().subscribe((data: any) => {
      this.setupData = data;
    });
  }

  // onFilterChange(e: any) {
  //   this.settingService.getStaffFilters(e.value, 'department', this.pageCount).subscribe((resp: any) => {
  //     this.staffListArray = resp.data;
  //     this.totalStaff = resp.count;
  //   });
  // }
  validateDecimalPoints(event: any) {
    if (event.target.value.length === 0) {
      return onOnlyNumbers(event);
    } else {
      if (event.key === '+' || event.key === '-' || event.key === 'e' || event.keyCode <= 45 || event.keyCode >= 58) {
        return false;
      }
      if (NAVIGATION_KEYS.indexOf(event.key) > -1) {
        return true;
      }

      const splittedValue = event.target.value.split('');
      if ((splittedValue && splittedValue.includes('.')) || event.key === '.') {
        const index = splittedValue.findIndex((el: any) => el === '.');
        if (index === -1) {
          return true;
        } else {
          const sampleText = splittedValue.slice(index, splittedValue.length);
          if (splittedValue[splittedValue.length - 1] === '.') {
            return true;
          } else if (splittedValue[splittedValue.length - 1] === '1' && sampleText.length <= 2 && (event.key === '0' || event.key === '1')) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return event.target.value.length < 2;
      }
    }
  }

  searchName(e: any) {
    if (e.length >= 3) {
      this.staffListArray = [];
      this.settingService.getStaffFilters(e, 'namesearch', this.pageCount).subscribe((resp: any) => {
        this.staffListArray = resp.data;
        this.totalStaff = resp.count;
        if (this.staffListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      });
    }
    else if (e.length === 0) {
      this.getStaff();
    }
  }

  getDeveloperInfo() {
    this.tableSkeletonLoader = true;
    this.settingService.getDeveloperInfo().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.developerInfo = resp.data;
      if (this.developerInfo.length === 0) this.noDataFound = true;
      else this.noDataFound = false;
    });
  }

  addDeveloperUser(modal: any, item?: any) {
    this.modelservice.open(modal);
    this.initilizeDeveloperFrom(item);
  }

  initilizeDeveloperFrom(data?: any) {
    this.addDeveloperForm = this.formBuilder.group({
      id: new FormControl(data ? data.id : ''),
      fname: new FormControl(data ? data.fname : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z-'.\s]{1,}$/)]),
      lname: new FormControl(data ? data.lname : '', [Validators.pattern(/^[A-Za-z][A-Za-z-'\s]*$/)]),
      // eslint-disable-next-line no-useless-escape
      email: new FormControl({ value: data ? data.email : '', disabled: data?.id ? true : false }, Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),
      phone_number: new FormControl(data ? data.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      ccode: new FormControl(data ? data.ccode : this.authService.getCompanyData().ccode, [Validators.required]),
      password: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)]))
    });
    if(data) this.addDeveloperForm.removeControl('password');
  }

  get d() {
    return this.addDeveloperForm.controls;
  }

  submitDeveloperUser() {
    this.isdeveloperSubmitted = true;
    if (this.addDeveloperForm.valid) {
      if (this.addDeveloperForm.value.id) {
        this.settingService.putDeveloperInfo(this.addDeveloperForm.value).subscribe(() => {
          this.getDeveloperInfo();
          this.modelservice.dismissAll();
        });
      } else {
        this.settingService.postDeveloperInfo(this.addDeveloperForm.value).subscribe(() => {
          this.getDeveloperInfo();
          this.modelservice.dismissAll();
        });
      }
    }
  }

  regenerateAPIkey(id: string) {
    this.settingService.putDeveloperInfo({ id: id, generate_new_api_key: true }).subscribe(() => {
      this.getDeveloperInfo();
    });
  }

  onStatusChangeDeveloperUser(id: string, status: boolean) {
    this.settingService.putDeveloperInfo({ id: id, status: !status }).subscribe(() => {
      this.getDeveloperInfo();
    });
  }

  confirmDeveloperUser(deleteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.settingService.deleteDeveloperInfo(deleteId).subscribe(() => {
          this.getDeveloperInfo();
        });
      }
    });
  }
  initilizeResetPsw(data: string) {
    this.developerPswRestForm = this.formBuilder.group({
      id: new FormControl(data),
      password: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)]))
    });
  }

  get rest_form() {
    return this.developerPswRestForm.controls;
  }

  openModelPswChange(modal: any, id: string) {
    this.initilizeResetPsw(id);
    this.modelservice.open(modal);
  }

  developerPswChange() {
    this.isdeveloperSubmitted = true;
    if (this.developerPswRestForm.valid) {
      this.settingService.pswRestForDeveloper(this.developerPswRestForm.value).subscribe(() => {
        this.modelservice.dismissAll();
      });
    }
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
}
