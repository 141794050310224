import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CONTACTSJSON } from 'src/app/core/data/contacts_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-groups.component.html',
  styleUrls: ['./contact-groups.component.scss']
})
export class ContactGroupsComponent implements OnInit {
  contactsJson = CONTACTSJSON[0];
  groupList: any[] = [];
  selectedItem: any;
  groupName: any;
  @Input() isInnerScreen!:boolean;

  constructor(private service: CrmService, private modalService: NgbModal, private confirmationMsz: ConfirmationMszService) {

  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.service.getGroupList().subscribe((resp: any) => {
      this.groupList = resp.data;
    });
  }

  onGroupEdit(addtolistModel: any, item: any) {
    this.selectedItem = item;
    this.modalService.open(addtolistModel, { size: 'md' });
  }

  updateToList() {
    this.service.updateGroup(this.selectedItem).subscribe((result: any) => {
      console.log(result, "result");
      this.getList();
      this.modalService.dismissAll();
    });
  }

  confirmDelete(group_id: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteGroup(group_id);
      }
    });
  }

  deleteGroup(id: string) {
    this.service.deleteGroup(id).subscribe(() => {
      this.getList();
    });
  }
}
