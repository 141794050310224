<div class="container-fluid home-page-sec" *ngIf="featuresData">
  <div class="row" style="flex-wrap: nowrap;">
    <div class="col-md-12 ps-0 main-panel pe-0">
      <div class="" [ngClass]="selectedSortOptions?.sort_by || column_filters.length > 0 ? 'pt-1' : '' ">
        <div class="position-relative"
          style="overflow: hidden;flex-wrap: nowrap;box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.13);">
          <div class="ps-4 nav-tabs-custom">
            <div class="ps-0 home-page-view home-tab-sec  col-8">
              <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs mt-0 pb-2 pt-1 tab-sec-height"
                (navChange)="tabChange($event)">
                <ng-container *ngFor="let tab of tabs;let i = index">
                <li [ngbNavItem]="tab.id" class="me-2" [id]="'li'+(i+1)" *ngIf="featuresData[tab.launch_dkl_key]">
                  <a ngbNavLink class="d-flex" >
                    <span class="d-sm-block font-size-14">{{tab.title}}</span>
                  </a>
                  <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                  </ng-template>
                </li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="search-customised-sec expand home_new_ui p-0  d-flex justify-content-end align-items-center"
            style="bottom: 2px;">
            <mat-form-field appearance="outline"
              class="margin-right-14 main-filter position-relative mat-hint-none main-filter ms-2"
              style="max-width: 150px;min-width: auto;">
              <mat-select (valueChange)="onSelectHomeTabs($event)" [(ngModel)]="homeTabs">
                <mat-option class="font-size-12" [value]="'view_all'">View All</mat-option>
                <mat-option class="font-size-12" [value]="tab.id" *ngFor="let tab of tabs">{{tab.title}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
              style="width: 212px;">
              <input style="outline: none" class="font-size-16" id="search-box" matInput placeholder="Search Here..."
                [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
              <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                  alt=""></mat-icon>
            </mat-form-field>
            <div matTooltip="Sort By" class="btn custom-filter-btn margin-right-14 d-flex align-items-center font-size-16"
              (click)="openFiltersModal('sort')">
              <img src="assets/images/sort_logo.svg" alt="">
              <!-- <span class="ms-2 limit-line-length-1">Sort By</span> -->
            </div>
            <div matTooltip="Filter" class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
              (click)="openFiltersModal('filters')">
              <img src="assets/images/filter_log.svg" alt="">
              <!-- <span class="ms-2 limit-line-length-1">Filter By</span> -->
            </div>
            <mat-form-field appearance="outline"
              class="dropDownfilter main-filter position-relative mat-hint-none main-filter"
              style="max-width: 150px;min-width: auto; margin-right:2px ;">
              <mat-select (valueChange)="onFilterChange($event)" [(ngModel)]="filter">
                <mat-option class="font-size-12" [value]="opt.label"
                  *ngFor="let opt of select_type">{{opt.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="selectedSortOptions?.sort_by || column_filters.length > 0" class="d-flex align-items-center px-4">
          <div *ngIf="selectedSortOptions?.sort_by" class="mt-3">
            <div class="dummy-search-box d-flex border-radius-12 width-fit-content px-2 py-1 me-2 align-items-center">
              <p class="m-0" style="font-size: 12px;">Sort By:
                <span>{{selectedSortOptions?.sort_by_label}}</span>
              </p>
              <button type="button" class="btn-close" aria-label="Close" (click)="onClearSortBy()"></button>
            </div>
          </div>
          <div *ngFor="let infoList of column_filters;let i = index;"
            class="dummy-search-box d-flex border-radius-12 width-fit-content mt-4 px-2 py-1 me-2 align-items-center">
            <p class="m-0" style="font-size: 12px;">Filtered By: <span>{{infoList?.label}}</span>
            </p>
            <button type="button" class="btn-close" aria-label="Close" (click)="onClearFilters(i)"></button>
          </div>
        </div>
      </div>

      <div [ngbNavOutlet]="customNav" class="home-page-table-view"
        [ngClass]="selectedSortOptions?.sort_by || column_filters.length > 0 ? 'custom-filter-height' : 'custom-height' ">
      </div>
    </div>
    <div class="notifications-sections cursor p-0" *ngIf="featuresData && featuresData?.notifications"
      [ngStyle]="{'bottom': selectedSortOptions?.sort_by || column_filters.length > 0 ? '62px' : '78px'}">
      <div (click)="onExpandNotifications($event)"
        class="notifications-header bg-white d-flex align-items-center justify-content-between position-sticky pt-3 pb-2">
        <div class="d-flex align-items-center">
          <p class="line-height-1 font-size-16 fw-600  m-0">Notifications</p>
          <span class="notifications-count border-radius-50 ms-2 font-size-8 fw-500"
            style="padding: 2px 6px;">{{notitficationsCount}}</span>
        </div>
        <div class="d-flex align-item-center">
          <div (click)="markAllasRead($event)" id="mark_as_read_notification"
            class="mark_completed me-2 px-1 line-height-1 align-items-center mark-as-button">
            <input type="checkbox" class="cursor" id="mark_as_read">&nbsp;<label
              class="m-auto my-0 cursor ms-1 label-text-clr font-size-12 fw-500 limit-line-length-1">Mark
              all as
              read</label>
          </div>
          <mat-icon class="arrow-icons">keyboard_arrow_up</mat-icon>
        </div>
      </div>
      <div class="notifications-body" #notificationList>
        <div class="overflow-auto side-noti-sec">
          <div class="row m-0" infinite-scroll [scrollWindow]="false" (scrolled)="onScroll()"
            [infiniteScrollContainer]="notificationList" [infiniteScrollUpDistance]="2">
            <div *ngFor="let item of notification_list;let i = index" class="notification-msg py-2 pb-0">
              <div class="d-flex align-items-start">
                <div class="home-avatar-xss border-radius-50 d-flex justify-content-between position-relative mb-1">
                  <span
                    class="avatar-title fw-500 rounded-circle text-uppercase font-size-13 text-secondary">{{item?.updated_by_name?.charAt(0)
                    || item?.created_by_name?.charAt(0) || 'U'}}</span>
                  <div class="mention-svg position-absolute " *ngIf="item?.mentioned">
                    <img class="rounded-circle bg-white" style="padding: 2px;" src="assets/images/mention.svg" alt="">
                  </div>
                </div>
                <div class="ms-2 flex-grow-1 mb-2" style="width: calc(100% - 40px);">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="m-0 font-size-14 fw-900 text-dark limit-line-length-1" style="max-width: 80%;">{{item?.updated_by_name || item?.created_by_name || 'Unknown'}}</p>
                    <p class="m-0 font-size-12 fw-400" style="color: #8B8B8B;">{{item.customTime}}</p>
                  </div>
                  <div class="d-flex align-items-center position-relative">
                    <p class="font-size-14 m-0 pb-0 notification-text w-100 limit-line-length-1" #pRef
                      (click)="item?.redirection && onEventDeligation(item)"
                      [ngClass]="{'text-decoration-line-through': item.is_read,'cursor':item?.redirection}"
                      [innerHTML]="item?.message">
                    </p>
                    <div class="see-more position-absolute" (click)="onClickSeemore(pRef,i)">
                      <span *ngIf="pRef.scrollHeight > 38" class="font-size-13 cursor text-underline ps-1"
                        style="color: black;"><span class="" style="color:#000000b2">...
                        </span>see more</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-2">
                    <p class="border-radius-12 source-type font-size-12 fw-600 position-relative text-capitalize width-fit-content py-1 m-0"
                      [ngStyle]="{'background':sourceColorCode[item?.source_type]?.bgColor,'color':sourceColorCode[item?.source_type]?.color}">
                      {{item?.source_type === 'lead' ? 'Lead' : item?.source_type }}</p>
                    <div (click)="markasRead(item,'lbl')"
                      class="mark_completed px-2  line-height-1 d-flex align-items-center mark-as-button">
                      <input [(ngModel)]="item.is_read" type="checkbox" class="cursor" id="mark_as_read">&nbsp;<label
                        class="m-auto my-0 cursor ms-1 label-text-clr font-size-12 fw-500 limit-line-length-1">Mark
                        as
                        read</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dummy-hider-div"
      [ngClass]="selectedSortOptions?.sort_by || column_filters.length > 0 ?'notifications-hider-filter-div':'notifications-hider-div'">

    </div>
  </div>
</div>