import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ClientsService } from 'src/app/core/service/clients.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { ContactsModalComponent } from '../../shared/contacts-modal/contacts-modal.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { InvoiceService } from 'src/app/core/service/invoice.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { finalize } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
  @Input() invoice_type = '';
  @Input() invoice_id = '';
  @Output() roleback = new EventEmitter();
  invoice_creation_form!: FormGroup;
  min_date = new Date();
  branchs: any = [];
  contactsArray: any = [];
  quotation_from: any = {};
  quotation_to_billing: any = {};
  activeId: any = 1;
  store!: string;
  productsArray: any = [];
  same_billing_address = false;
  isTermsAndCondition = false;
  isContactUs = false;
  address_type!: string;
  new_billing_address: any;
  allList: any = [];
  decimalValues: any;
  final_invoice_payload: any;
  quotation_to_shipping: any = {};
  total_cart_amount = 0;
  total_cart_discount = 0;
  total_taxable_amount = 0;
  terms_and_conditions_resp: any;
  company_details: any;
  selected_contact_info: any;
  total_products_price = 0;
  initialValue = '';
  sidePopupModal: any;
  errorSubmission = false;
  tabChange(event: any) {
    this.activeId = event.nextId;
  }
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  search_global: any;
  selected_type = 'PRODUCT';
  categories_list: any[] = [];
  onSelectedCategory: any = 'all';
  presignedurl = environment.preSendUrl;
  cartArray: any[] = [];
  new_address: any;
  new_shipping_address: any;
  on_popup: any = 'product_items';
  unit_type_list = ['piece', 'gm', 'ml', 'litre', 'mm', 'ft', 'meter', 'sq.ft', 'squre.meter', 'km', 'set', 'hour', 'day', 'bunch', 'bundle', 'month', 'year', 'service', 'work', 'packet', 'box', 'pound', 'dozen', 'gunta', 'pair', 'minute', 'quintal', 'ton', 'capsule', 'tablet', 'plate', 'inch', 'ounce', 'bottle', 'night', 'tour'];
  discount_type_list = [
    { id: 'PERCENTAGE', name: 'Percentage' },
    { id: 'AMOUNT', name: 'Flat' }
  ];
  product_submiteed = false;
  product_form!: FormGroup;
  listOfTaxTypes: any = [];
  loading = false;
  mode_selected = false;
  maxDate: any = new Date();
  addAdditionalCharges = false;
  addAdditionalDiscount = false;
  totalDiscount = 0;
  totalCharges = 0;
  final_amount = 0;
  company_currency: any;
  featuresData: any;
  constructor(private settings_service: SettingsService, private client_service: ClientsService, private modalService: NgbModal, private setup_service: SetUpService, private crm_service: CrmService, private invoice_service: InvoiceService, private authService: AuthService,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    console.log(this.invoice_type, this.invoice_id);
    this.company_currency = this.authService.getPriceData()?.currency?.split(',')[1];
    this.initilizeForm();
    if (this.invoice_id) this.getInvoiceById();
    this.getOrganisationTermsAndConditions();
    this.getCompanyDetails();
    this.getAllBranchs();
    this.getFeatues();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    // this.productsAndServicesForm();
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  triggerRoleBack(trigger_value: boolean) {
    this.invoice_creation_form.reset();
    this.roleback.emit(trigger_value);
  }

  initilizeForm(data?: any) {
    this.invoice_creation_form = new FormGroup({
      reference_id: new FormControl(),
      issue_date: new FormControl(data ? data.issue_date : this.maxDate),
      org_address: new FormControl(data ? data.org_address : ''),
      billing_address: new FormControl(data ? data.billing_address?.first_name : ''),
      shipping_address: new FormControl(data ? data.shipping_address : ''),
      items: new FormControl(data ? data.items : '', []),
      source_type: new FormControl(data ? data.source_type : 'ADMIN_PANEL'),
      terms_conditions: new FormControl(data ? data.terms_conditions : ''),
      footer_contact_us: new FormControl(data ? data.footer_contact_us : ''),
      is_clients_address: new FormControl(data ? data.is_clients_address : true),
      is_terms_and_condition: new FormControl(true),
      is_contact_us: new FormControl(data ? (data.footer_contact_us ? true : false) : false),
      additional_discount_label: new FormControl(),
      additional_charges_label: new FormControl(),
      additional_discount_value: new FormControl(),
      additional_charges_value: new FormControl(),
      additional_discount_type: new FormControl(),
      additional_charges_type: new FormControl(),
      additional_price: new FormControl([]),
      invoice_type: new FormControl(data ? data.invoice_type : this.invoice_type),
      total: new FormControl(data ? data.total : ''),
      total_discount: new FormControl(data ? data.total_discount : ''),
      total_tax: new FormControl(data ? data.total_tax : ''),
      sub_total: new FormControl(data ? data.sub_total : ''),
      id: new FormControl(data ? data.id : ''),
      is_bank_details: new FormControl(true),
      bank_details: new FormControl(data ? data.bank_details : ''),
      payment_online: new FormControl(data ? data.payment_online : true),
      payment_name: new FormControl(data ? data.payment_name : 'razorpay')
    });
    if (data) {
      // this.cartArray = data.items;
      data.items.forEach((item: any) => {
        this.cartArray.push(item);
      });
      console.log(this.cartArray, "cartArray");
      this.calculateCart();

      this.quotation_to_billing = data.billing_address;
      this.quotation_to_shipping = data.shipping_address;
      this.quotation_to_shipping.billing_address = data.shipping_address.shipping_address;
    }
  }

  getInvoiceById() {
    this.invoice_service.getInvoiceById(this.invoice_id).subscribe((resp: any) => {
      console.log(resp, "invoiceByid");
      this.initilizeForm(resp.data);
    });
  }

  get products_and_services() {
    return this.invoice_creation_form.controls["invoice_items"] as FormArray;
  }

  productsAndServicesForm() {
    const invoice_products_and_services: any = new FormGroup({
      item_name: new FormControl(),
      quantity: new FormControl(),
      price: new FormControl(),
      discount: new FormControl(),
      tax: new FormControl(),
      amount: new FormControl()
    });

    this.products_and_services.push(invoice_products_and_services);
  }

  removeItem(i: number) {
    this.products_and_services.removeAt(i);
  }

  getAllBranchs() {
    this.settings_service.getLocationSettings().subscribe({
      next: (resp: any) => {
        console.log(resp.data);
        this.branchs = resp.data;
        this.branchs.filter((ele: any) => {
          if (ele.default && !this.invoice_id) {
            this.quotation_from = ele;
            this.invoice_creation_form.patchValue({ org_address: ele.id });
          } else if (this.invoice_id && this.invoice_creation_form.controls['org_address'].value === ele.id) {
            this.quotation_from = ele;
          }
        });
      }
    });
  }

  openContactDialog(data?: any) {
    this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
  }

  selectedBranchAddress(data: any) {
    console.log(data);
    this.quotation_from = data;
    if (!this.invoice_id) this.invoice_creation_form.patchValue({ org_address: data.id });
  }

  searchTriggerContact(change: any) {
    if (change.term.length >= 3) {
      this.crm_service.getContactsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.contactsArray = resp.data;
        }
      });
    }
  }

  selectedCustomer(client: any) {
    console.log(client);
    this.selected_contact_info = client;
    this.new_billing_address = this.new_shipping_address = '';
    if (Object.keys(client).length > 0) {
      this.quotation_to_billing = Object.assign({}, client);
      this.quotation_to_shipping = Object.assign({}, client);
      if (!this.invoice_creation_form.value.is_clients_address) {
        this.quotation_to_shipping.billing_address = '';
      }
    } else this.quotation_to_billing = this.quotation_to_shipping = {};
  }

  clearContact() {
    this.invoice_creation_form.patchValue({ billing_address: '', shipping_address: '' });
    this.quotation_to_billing = {};
    this.quotation_to_shipping = {};
    this.new_billing_address = this.new_shipping_address = '';
  }

  // sameBussinessAddress(event: any) {
  //   console.log(event);
  //   if (event.checked && Object.keys(this.quotation_from).length !== 0) {
  //     this.invoice_creation_form.patchValue({ shippment_from_name: this.quotation_from.name, shippment_from_address: this.quotation_from.location, shippment_from_contact_no: '' });
  //   }
  // }
  // sameClientsAddress(event: any) {
  //   console.log(event);
  //   if (event.checked && Object.keys(this.quotation_to_billing).length !== 0) {
  //     this.invoice_creation_form.patchValue({ shippment_to_name: this.quotation_to_billing.name, shippment_to_address: this.quotation_to_billing.country, shippment_to_contact_no: this.quotation_to_billing.phone_number });
  //   }
  // }

  onSubmit() {
    // console.log(this.invoice_creation_form);
  }

  openProductsAndServicesModal(modal: any, screen_to_show: string) {
    this.getStoreId();
    this.on_popup = screen_to_show;
    this.modalService.open(modal, { size: 'lg', scrollable: true, modalDialogClass: 'products-board-modal', backdrop: 'static' });
    // this.productsAndServicesForm();
  }

  closeModal() {
    this.modalService.dismissAll('');
  }

  getStoreId() {
    this.setup_service.getStore().subscribe((resp: any) => {
      this.store = resp.data.id;
      this.getCategory();
      this.getAllProducts();
    });
  }

  getCategory() {
    this.setup_service.getProductCategories(this.store, this.selected_type).subscribe((resp: any) => {
      this.categories_list = resp.data;
      this.categories_list.unshift({ name: 'All', id: 'all' });
    });
  }

  getAllProducts() {
    this.setup_service.getProduct(this.store, this.selected_type).subscribe((prod_resp: any) => {
      this.allList = prod_resp.data;
      if (this.onSelectedCategory === 'all') this.productsArray = prod_resp.data;
      else this.productsArray = prod_resp.data.filter((ele: any) => ele.type === this.selected_type && this.onSelectedCategory === ele.category);
      // this.productsArray = prod_resp.data;
      // console.log(this.productsArray);

    });
  }

  toggleVisibility(e: any) {
    // console.log(e.checked);
    // console.log(this.quotation_to_billing);
    if (e.checked) {
      this.quotation_to_shipping.billing_address = this.quotation_to_billing.billing_address;
    } else {
      this.quotation_to_shipping.billing_address = '';
      this.new_shipping_address = '';
    }
  }

  toggleVisibilityForTermsCondition(e: any, type: string) {
    this.isTermsAndCondition = e.checked;
    if (type === 'terms&conditions') {
      if (this.isTermsAndCondition) {
        if (!this.invoice_id) this.invoice_creation_form.patchValue({ terms_conditions: this.terms_and_conditions_resp.terms_conditions });
      } else {
        this.invoice_creation_form.patchValue({ terms_conditions: '' });
      }
    } else {
      if (!this.isTermsAndCondition) {
        this.invoice_creation_form.patchValue({ bank_details: '' });
      }
    }
  }

  toggleVisibilityForContactUs(e: any) {
    this.isContactUs = e.checked;
    if (this.isContactUs) {
      this.invoice_creation_form.patchValue({ footer_contact_us: this.terms_and_conditions_resp.footer_contact_us });
    } else {
      this.invoice_creation_form.patchValue({ footer_contact_us: '' });
    }
  }

  search(event: any) {
    if (event.length >= 3) {
      this.setup_service.getProduct(this.store, this.selected_type, event).subscribe((resp: any) => {
        if (this.onSelectedCategory !== 'all') this.productsArray = resp.data.filter((ele: any) => ele.type === this.selected_type && this.onSelectedCategory === ele.category);
        else this.productsArray = resp.data.filter((ele: any) => ele.type === this.selected_type);
        // this.productsArray = resp.data;
      });
    } else if (event.length === 0) {
      if (this.onSelectedCategory === 'all') this.productsArray = this.allList;
      else this.productsArray = this.allList.filter((ele: any) => this.onSelectedCategory === ele.category);
    }
  }

  selectedType(type: any) {
    this.getAllProducts();
    this.getCategory();
  }

  onSelectFilterCategory(category_type: any) {
    this.onSelectedCategory = category_type;
    if (this.onSelectedCategory === 'all') this.productsArray = this.allList;
    else this.productsArray = this.allList.filter((ele: any) => ele.type === this.selected_type && this.onSelectedCategory === ele.category);
  }

  addCartItems(item: any) {
    if (this.cartArray.some(cartItem => cartItem.id === item.id)) { /* empty */ }
    else {
      const newItem: any = item;
      newItem['item_quantity'] = 1;
      newItem['is_quantity_decrease'] = false;
      this.cartArray.push(newItem);
    }

  }

  removeCartItem(index: number) {
    this.cartArray.splice(index, 1);
  }

  cartProductQuantity(param: any, index: number, current_quntity: number, text_ongo?: string) {
    if (param === 'plus') {
      this.cartArray[index].item_quantity += 1;
    } else if (param === 'minus' && current_quntity > 1) {
      this.cartArray[index].item_quantity -= 1;
    }
    if (text_ongo) this.calculateCart();
  }

  openEditAddress(modal: any, address_type: string) {
    const modalRef = this.modalService.open(modal, { size: 'md', scrollable: true });
    this.address_type = address_type;
    this.new_billing_address = this.quotation_to_billing.billing_address;
    this.new_shipping_address = this.quotation_to_shipping.shipping_address;
    if (modalRef) {
      modalRef?.result?.then((receivedEntry: any) => {
        if (this.address_type === 'shipping_address') {
          this.quotation_to_shipping.shipping_address = receivedEntry;
        } else {
          this.quotation_to_billing.billing_address = receivedEntry;
          this.quotation_to_shipping.billing_address = receivedEntry;
          if (this.invoice_creation_form.get('is_clients_address')?.value) {
            this.quotation_to_shipping.billing_address = receivedEntry;
          }
        }
      });
    }
  }


  getListOfTaxes() {
    this.invoice_service.getTaxes().subscribe({
      next: (resp: any) => {
        // this.listOfTaxTypes = resp.data;
        this.listOfTaxTypes = resp?.data?.filter((ele: any) => ele.status);
        this.listOfTaxTypes = [...this.listOfTaxTypes];
      }
    });
  }

  createNewProduct() {
    this.on_popup = 'new_product';
    this.initializeProductForm();
    this.getPriceDetails();
    this.getListOfTaxes();

  }

  initializeProductForm(data?: any) {
    this.product_form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      type: new FormControl('', Validators.required),
      category: new FormControl(''),
      price: new FormControl(0, Validators.required),
      discount_value: new FormControl(0),
      discount_type: new FormControl('AMOUNT'),
      measure: new FormControl('', Validators.required),
      unit: new FormControl('', [Validators.required, Validators.pattern("[0-9]+")]),
      image: new FormControl([]),
      currency: new FormControl(this.authService.getPriceData()?.currency),
      variants: new FormArray([]),
      tax: new FormControl(data ? data.tax : [])
    });
  }

  get f() {
    return this.product_form.controls;
  }

  onChangeDiscountType(type: string) {
    this.product_form.patchValue({ [type]: 0 });
  }


  keyDownEvent(event: any, formField: string) {
    let decimalPoints = 0;
    let type = '';
    if (formField === 'valid') {
      return onOnlyNumbers(event);
    } else {
      if (this.product_form.get(formField)?.value === 'PERCENTAGE') {
        decimalPoints = 1;
        type = 'PERCENTAGE';
      } else {
        decimalPoints = this.decimalValues['no_of_decimals'] - 1;
        type = 'any';
      }
      if (event.target.value.length === 0) {
        return onOnlyNumbers(event);
      } else {
        return onlyNumberDecimal(event, decimalPoints, type);
      }
    }

  }

  getPriceDetails() {
    this.settings_service.getPriceSettings().subscribe((resp: any) => {
      this.decimalValues = resp['data'];
    });
  }

  onProductSubmit() {
    // console.log(this.product_form);
    this.product_submiteed = true;
    if (this.product_form.valid) {
      this.setup_service.postProduct(this.store, { ...this.product_form.value }).pipe(finalize(() => this.loading = false)).subscribe(() => {
        this.getAllProducts();
        this.on_popup = 'product_items';
        this.product_form.reset();
      });
    }
  }
  onFocus(event: FocusEvent): void {
    this.initialValue = (event.target as HTMLInputElement).value;
  }

  calculateCart(ev?: any) {
    const currentValue = (ev?.target as HTMLInputElement)?.value;
    if (ev && currentValue === this.initialValue) {
      return;
    }
    this.total_cart_amount = this.total_cart_discount = this.total_taxable_amount = this.total_products_price = 0;
    this.cartArray.forEach((ele: any, i: any) => {
      if (!ele.price) ele.price = 0;
      const net_total: any = ele.item_quantity * ele.price; // total price multiplied with number of quantities of same product
      let discount_amount: any = 0; // discount price
      let discounted_amount: any = 0; // total amount after removing discount price
      if (ele.discount_type === 'AMOUNT') {
        if (ele.price < ele.discount_value) {
          this.errorSubmission = true;
          this.toaster.error("Discount Value is more then the Actual price"); return;
        }
        this.errorSubmission = false;
        discount_amount = ele.discount_value * ele.item_quantity;
        discounted_amount = net_total - discount_amount;
      } else if (ele.discount_type === 'PERCENTAGE') {
        const overall_discount: any = (ele.price * ele.discount_value) / 100;
        if (ele.price < overall_discount) {
          this.errorSubmission = true;
          this.toaster.error("Discount Value is more then the Actual price"); return;
        }
        this.errorSubmission = false;
        discount_amount = overall_discount * ele.item_quantity;
        discounted_amount = net_total - discount_amount;
      } else {
        discount_amount = null;
        discounted_amount = net_total - discount_amount;
      }
      let summed_tax = 0;
      let taxable_amount = 0;
      if (ele.tax_info?.length > 0) {
        ele.tax_info.forEach((tax_ele: any) => {
          if (tax_ele.status) summed_tax += tax_ele.percentage; // sum of all types of taxes for that particular product
        });
        taxable_amount = ((discounted_amount * summed_tax) / 100); // tax amount in rupees
      } else {
        taxable_amount = 0; // tax amount in rupees
      }

      this.cartArray[i]['product_quantity_price'] = net_total;
      this.cartArray[i]['product_discount_price'] = discount_amount;
      this.cartArray[i]['product_discounted_price'] = discounted_amount;
      this.cartArray[i]['product_taxable_amount'] = taxable_amount;
      this.total_cart_amount += (discounted_amount + taxable_amount);
      this.total_products_price += this.cartArray[i]['product_quantity_price'];
      this.total_cart_discount += this.cartArray[i]['product_discount_price'];
      this.total_taxable_amount += this.cartArray[i]['product_taxable_amount'];
      this.cartArray[i]['product_total_price'] = discounted_amount + taxable_amount;
      console.log(this.total_products_price, "total_products_price");
    });
    // console.log(this.cartArray, "AFTER CALUCULATION");

  }

  isItemInCart(item: any): boolean {
    return this.cartArray.some(cartItem => cartItem.id === item.id);
  }
  CreateManualInvoice() {

    // if (this.quotation_to_billing?.billing_address) {
    //   // console.log(this.quotation_to_billing);
    // } else {
    //   this.quotation_to_billing.billing_address = this.new_billing_address;
    //   this.quotation_to_shipping.shipping_address = this.new_shipping_address;
    // }
    if (this.errorSubmission) {
      this.toaster.error("Invalid Data");
      return;
    }
    const uuid = Date.now();
    let payment_uuid: any;
    if (this.company_details?.slug.length === 1) {
      payment_uuid = this.company_details?.slug.substring(0, 1) + '00' + uuid.toString().substring(0, 10);
    } else if (this.company_details?.slug.length === 2) {
      payment_uuid = this.company_details?.slug.substring(0, 2) + '0' + uuid.toString().substring(0, 10);
    } else {
      payment_uuid = this.company_details?.slug.substring(0, 3) + uuid.toString().substring(0, 10);
    }
    const issue_date = this.convert(this.invoice_creation_form.controls['issue_date'].value);
    this.invoice_creation_form.controls['issue_date'].setValue(issue_date);
    const payment_payload: any = {
      "gateway_name": "",
      "type": "OFF_LINE",
      "reference_id": payment_uuid.toUpperCase(),
      "source_type": "ADMIN_PANEL",
      "source_id": payment_uuid.toUpperCase(),
      "post_transaction": { "amount": this.final_amount || this.total_cart_amount, "currency": "INR" },
      "user_info": this.selected_contact_info ? this.selected_contact_info : {},
      "mode": 'COD'
    };
    if ((this.quotation_to_shipping.shipping_address || this.quotation_to_shipping.billing_address) && this.quotation_to_billing.billing_address) {
      const additional_charges_array: any = [];
      if (this.invoice_creation_form.value.additional_charges_label) {
        const charges_instance_obj = {
          name: this.invoice_creation_form.value.additional_charges_label,
          addon: "CHARGES",
          type: this.invoice_creation_form.value.additional_charges_type,
          value: this.invoice_creation_form.value.additional_charges_value
        };
        additional_charges_array.push(charges_instance_obj);
      }
      if (this.invoice_creation_form.value.additional_discount_label) {
        const discount_instance_obj = {
          name: this.invoice_creation_form.value.additional_discount_label,
          addon: "DISCOUNT",
          type: this.invoice_creation_form.value.additional_discount_type,
          value: this.invoice_creation_form.value.additional_discount_value
        };
        additional_charges_array.push(discount_instance_obj);
      }
      const billing_address_body = {
        id: this.quotation_to_billing.id,
        company_name: this.quotation_to_billing.company_name,
        organisation: this.quotation_to_billing.organisation,
        first_name: this.quotation_to_billing.first_name,
        last_name: this.quotation_to_billing.last_name,
        email: this.quotation_to_billing.email,
        phone_number: this.quotation_to_billing.phone_number,
        ccode: this.quotation_to_billing.ccode,
        billing_address: this.quotation_to_billing.billing_address
      };
      const shipping_address_body = {
        id: this.quotation_to_shipping.id,
        company_name: this.quotation_to_shipping.company_name,
        organisation: this.quotation_to_shipping.organisation,
        first_name: this.quotation_to_shipping.first_name,
        last_name: this.quotation_to_shipping.last_name,
        email: this.quotation_to_shipping.email,
        phone_number: this.quotation_to_shipping.phone_number,
        ccode: this.quotation_to_shipping.ccode,
        shipping_address: this.quotation_to_shipping.billing_address ? this.quotation_to_shipping.billing_address : this.quotation_to_shipping.shipping_address
      };
      this.invoice_creation_form.patchValue({
        total: this.final_amount === 0 ? this.total_cart_amount : this.final_amount,
        total_discount: this.total_cart_discount, total_tax: this.total_taxable_amount, sub_total: this.total_products_price
      });
      const body = {
        ...this.invoice_creation_form.value,
        billing_address: billing_address_body,
        shipping_address: shipping_address_body,
        items: this.cartArray,
        additional_price: additional_charges_array
      };
      if (this.invoice_creation_form.value.id) {
        this.invoice_service.putEstimateAndProformaInvoice(body).subscribe({
          next: () => {
            this.triggerRoleBack(true);
          }
        });
      } else {
        if (this.invoice_creation_form.value.invoice_type !== 'INVOICE') {
          this.invoice_service.postEstimateAndProformaInvoice(body).subscribe({
            next: () => {
              this.triggerRoleBack(true);
            }
          });
        } else {
          this.invoice_service.postPaymentTransaction(payment_payload).subscribe({
            next: (payment_resp: any) => {
              // console.log(payment_resp);
              this.invoice_creation_form.patchValue({
                reference_id: payment_resp.data.reference_id
              });
              this.invoice_service.postManualInvoice({ ...body, reference_id: payment_resp.data.reference_id }).subscribe({
                next: () => {
                  // console.log(resp);
                  this.triggerRoleBack(true);
                }
              });
            }
          });
        }
      }
      this.modalService.dismissAll();
    } else {
      this.toaster.error("Please add your address");
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getOrganisationTermsAndConditions() {
    this.invoice_service.getTermsAndConditions().subscribe({
      next: (resp: any) => {
        // console.log(resp);
        this.terms_and_conditions_resp = resp.data[0];
        if (!this.invoice_id) this.invoice_creation_form.patchValue({ terms_conditions: this.terms_and_conditions_resp.terms_conditions });
      }
    });
  }

  getCompanyDetails() {
    this.settings_service.getCompanySettings().subscribe({
      next: (resp: any) => {
        this.company_details = resp.data;
      }
    });
  }

  clearAdditionalCharges() {
    this.addAdditionalCharges = false;
    this.invoice_creation_form.patchValue({
      additional_charges_label: '',
      additional_charges_value: '',
      additional_charges_type: ''
    });
  }

  clearExtraDiscount() {
    this.addAdditionalDiscount = false;
    this.invoice_creation_form.patchValue({
      additional_discount_label: '',
      additional_discount_value: '',
      additional_discount_type: ''
    });
  }

  onAdditionCharges(type: string) {
    this.final_amount = 0;
    if (type === 'additional_charges') {
      if (this.invoice_creation_form.value.additional_charges_type === 'PERCENTAGE') {
        this.totalCharges = (this.total_cart_amount * this.invoice_creation_form.value.additional_charges_value) / 100;
      } else {
        this.totalCharges = this.invoice_creation_form.value.additional_charges_value;
      }
    } else {
      if (this.invoice_creation_form.value.additional_discount_type === 'PERCENTAGE') {
        this.totalDiscount = (this.total_cart_amount * this.invoice_creation_form.value.additional_discount_value) / 100;
      } else {
        this.totalDiscount = this.invoice_creation_form.value.additional_discount_value;
      }
    }
    if (this.totalDiscount) {
      this.final_amount = this.total_cart_amount + this.totalCharges - this.totalDiscount;
    } else {
      this.final_amount = this.total_cart_amount + this.totalCharges;
    }
  }

  onPaymentModeChange(ev: any) {
    if (!ev.value) {
      this.invoice_creation_form.controls['payment_name'].setValue('');
    } else {
      this.invoice_creation_form.controls['payment_name'].setValue('razorpay');
    }
  }
}
