<div class="d-flex no-gutters details-modal" [ngStyle]="{'padding-top':headerHeight - 14 +'px'}">
    <div class="col-md-3 col-lg-3 col-xl-3 pe-0 h-100">
        <app-crm-info></app-crm-info>
    </div>
    <div class="col-md-9 col-lg-9 col-xl-9 h-100 ps-0 py-3 ps-1 bg-white" style="padding-right: 54px;">
        <div class="crm-client-content">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom px-3 mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1" *ngIf="timeline" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Timeline</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-0">
                            <app-time-line></app-time-line>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="task" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Task Board</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="pt-2 pb-0">
                            <app-task-board></app-task-board>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" *ngIf="notes" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Notes</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-notes-crm></app-notes-crm>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" *ngIf="selected_type !== 'deal' && activity" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Activity</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-2">
                            <app-activity-crm></app-activity-crm>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" *ngIf="files" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Files</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-3">
                            <app-files-modal></app-files-modal>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="6" *ngIf="selected_type !== 'lead' && selected_type !== 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Pipelines</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-deals-modal></app-deals-modal>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="7" *ngIf="selected_type !== 'lead' && selected_type !== 'deal' && projects"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Projects</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3">
                            <app-project-modal></app-project-modal>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="11" *ngIf="selected_type !== 'lead' && selected_type !== 'deal' && enquiry"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Leads</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-3">
                            <div class="custom-table-div-crm-height d-flex flex-column justify-content-between" style="height: calc(100vh - 224px);">
                                <div class="table-responsive table-height fixed-table">
                                    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                                        [fromLocation]="'CRM'"></app-table-skeleton>
                                    <table *ngIf="!tableSkeletonLoader"
                                        class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                                        <thead>
                                            <tr class="border-top-table bg-white" style="z-index: 999;">
                                                <ng-container *ngFor="let col of originalTableColumnLists">
                                                    <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                        scope="col"
                                                        class="text-primary font-size-14 pb-3 table-sticky-header bg-white text-capitalize"
                                                        style="z-index: 999;min-width: 100px;max-width: 150px;">
                                                        {{col.field_name!.split("_").join(" ")}}</th>
                                                </ng-container>
                                                <th class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                                    style="min-width: 60px;">Move To</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="!display_no_data">
                                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                                [routerLink]="['../crm-details']" [ngClass]="{'inactiveClass': !item?.is_contact_active }"
                                                [queryParams]="{ id: item.id, showtype: 'lead',contact_id: item.contact,contact_active: item?.is_contact_active }">
                                                <ng-container *ngFor="let col of originalTableColumnLists">
                                                    <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                                        style="max-width: 200px;"
                                                        [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '80px' : '150px'}">
                                                        <div *ngIf="col.system_field">
                                                            <span
                                                                *ngIf="col.field_name === 'phone_number' && item[col.field_name]">{{item[col.field_name]
                                                                ? item.ccode : ''}}&nbsp;{{item[col.field_name] || 'NA'}}</span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
                                                                || '-') + ' ' + (item?.owner_info?.lname || '')}}</span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name === 'company'">{{item.company_name || 'NA'}}</span>
                                                            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
                                                                {{item[col.field_name] | dateConversion }} {{ item[col.field_name] | date: 'h:mm a' || 'NA'}}
                                                            </span>
                                                            <span class="limit-line-length-1" *ngIf="col.field_name === 'contact'">
                                                                {{ item.name || 'NA'}}
                                                            </span>
                                                            <span class="limit-line-length-1"
                                                                *ngIf="col.field_name !== 'contact' && col.field_name !== 'phone_number' && col.data_type !== 'DATE_TIME' && col.field_name !== 'ccode' && col.field_name !== 'owner' && col.field_name !== 'company'">{{item[col.field_name]
                                                                || 'NA'}}</span>
                                                        </div>
                                                        <div *ngIf="!col.system_field">
                                                            <ng-container
                                                                *ngFor="let customField of item?.custom_contact_fields">
                                                                <ng-container
                                                                    *ngIf="customField.label === col.field_name">
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'DATE'">
                                                                        {{customField.data ? (customField.data |
                                                                        dateConversion) : 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'DATE_TIME'">
                                                                        {{customField.data
                                                                        | dateConversion }} {{ customField.data | date:
                                                                        'h:mm a' || 'NA'}}
                                                                    </span>
                                                                    <span *ngIf="col.data_type === 'DATE_RANGE'">
                                                                        <span class="limit-line-length-1"
                                                                            *ngIf="customField.data && customField.data.split('&').length > 1">
                                                                            {{customField?.data.split("&")[0].split("-").reverse().join("/")
                                                                            + ' - ' +
                                                                            customField?.data.split("&")[1].split("-").reverse().join("/")}}
                                                                        </span>
                                                                        <span *ngIf="!customField.data">
                                                                            NA
                                                                        </span>
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'PHONE_NUMBER'">
                                                                        {{customField?.data?.custom_ccode ?
                                                                        customField?.data?.custom_ccode :
                                                                        ''}}&nbsp;{{customField?.data?.custom_phone_number
                                                                        || 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type === 'FILE'">
                                                                        {{customField?.data?.file_name || 'NA'}}
                                                                    </span>
                                                                    <span class="limit-line-length-1"
                                                                        *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'">
                                                                        {{customField.data || 'NA'}}
                                                                    </span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <td (click)="$event.stopPropagation()"
                                                    class="mat-hint-none mat-customised-sec">
                                                    <mat-form-field appearance="outline" class="w-75">
                                                        <mat-select placeholder='Select' [(ngModel)]="item.type"
                                                            [disabled]="!item?.is_contact_active">
                                                            <mat-option (click)="moveLeadTo(item,'ACTIVE')"
                                                                value="ACTIVE" *ngIf="item.type !== 'ACTIVE'">Move to Open</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'LOST')"
                                                                value="LOST" *ngIf="item.type !== 'LOST'">Move to Lost</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'WON')"
                                                                value="WON" *ngIf="item.type !== 'WON'">Move to Won</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'JUNK')"
                                                                value="JUNK" *ngIf="item.type !== 'JUNK'">Move to Junk</mat-option>
                                                            <mat-option (click)="moveLeadTo(item,'PIPELINE')"
                                                                value="PIPELINE" *ngIf="item.type === 'ACTIVE'">Move to Flow</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- later appointments tab should be there for enquires only -->
                <!-- <li [ngbNavItem]="8" *ngIf="selected_type === 'lead'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Appointments</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem overflow-auto">
                            <app-appointment></app-appointment>
                        </div>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="8" *ngIf="selected_type === 'company' && contact" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Contacts</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div>
                            <app-contacts [companyId]="selected_id" [isInnerScreen]="true"></app-contacts>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="9" *ngIf="conversations && (selected_type === 'lead' || selected_type === 'deal')"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Conversations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <app-client-conversations></app-client-conversations>
                            </div>
                            <!-- <app-conversations></app-conversations> -->
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="10" *ngIf="emails && (selected_type === 'lead' || selected_type === 'deal')"
                    class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Emails</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <div class="p-3">
                                <app-conversations></app-conversations>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="10">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Tickets</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li> -->
                <!-- <li [ngbNavItem]="11" *ngIf="selected_type === 'lead' || selected_type === 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Email Drafts</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <app-email-draft></app-email-draft>
                        </div>
                    </ng-template>
                </li> -->
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>