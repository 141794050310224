import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AddTaskComponent } from 'src/app/pages/shared/add-task/add-task.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TaskBoardService } from 'src/app/core/service/task-board.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TaskDetailModalComponent } from 'src/app/pages/shared/task-detail-modal/task-detail-modal.component';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

// export interface Tile {
//   color: string;
//   cols: number;
//   rows: number;
//   text: string;
// }

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss']
})
export class TaskBoardComponent implements OnInit {
  @Input() crm_type!: string;
  @Input() source_id!: string;
  pipeline_id!: string;
  modalRef!: NgbModalRef;
  task_modal_ref!: NgbModalRef;
  selected_id!: string;
  selected_type!: string;
  submitStageForm = false;
  stageForm!: FormGroup;
  listOfStages: any = [];
  taskBoardArray: any = [];
  todayDate = new Date();
  loggedInUser: any;
  source_name: any;
  addNew = false;
  selectedStage: any;
  readOnly = false;
  search_global = '';
  searchSubject$ = new Subject();

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private service: TaskBoardService, private client_service: ClientsService, private crm_service: CrmService) {
    this.searchSubject$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      if (searchTerm.length >= 3) {
        this.getTaskByStage(searchTerm);
      } else if (searchTerm.length < 1) {
        this.getTaskByStage('');
      }
    });
  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      console.log(this.crm_type);

      this.selected_id = params.id;
      this.pipeline_id = params?.pipeline_id;
      this.selected_type = params.showtype;
      this.readOnly = params.contact_active === 'false' ? true : false;
      if (this.selected_id) {
        if (this.selected_type === 'project') {
          this.getProjectInfo(this.selected_id);
        } else if (this.selected_type === 'contact') {
          this.getContactInfo(this.selected_id);
        } else if (this.selected_type === 'lead') {
          this.getEnquiryInfo(this.selected_id);
        } else if (this.selected_type === 'company') {
          this.getCompanyInfo(this.selected_id);
        }
      }
      if (this.crm_type) {
        if (this.crm_type !== 'deal') this.selected_id = "";
        if (this.crm_type && this.source_id) {
          this.selected_type = this.crm_type;
          this.selected_id = this.source_id;
          this.getProjectInfo(this.source_id);
        }
        this.selected_type = this.crm_type;
      }
      this.getRelatedIdInfo();
    });
    this.initlizeForm();
  }

  getProjectInfo(id: string) {
    this.client_service.getProjectDetailsById(id).subscribe({
      next: (proj_resp: any) => {
        this.source_name = proj_resp.data.name;
      }
    });
  }

  getContactInfo(id: string) {
    this.crm_service.getContactById(id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].first_name + " " + resp.data[0].last_name;
      }
    });
  }

  getCompanyInfo(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (com_resp: any) => {
        this.source_name = com_resp.data[0].name;
      }
    });
  }

  getEnquiryInfo(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].name;
        if (resp.data[0].type === 'PIPELINE') this.readOnly = true;
      }
    });
  }

  initlizeForm() {
    this.stageForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      is_closed: new FormControl(false),
      source_name: new FormControl('')
    });
  }

  get f() {
    return this.stageForm.controls;
  }

  onSubmitStageForm() {
    this.submitStageForm = true;
    if (this.stageForm.valid) {
      if (!this.stageForm.value.is_closed) this.stageForm.value.is_closed = false;
      if (this.stageForm.value.id) {
        this.service.updateStage(this.selected_id, this.selected_type, this.stageForm.value).subscribe({
          next: (resp: any) => {
            this.selectedStage = null;
            this.addNew = false;
            this.submitStageForm = false;
            this.stageForm.reset();
            this.initlizeForm();
            if (!this.crm_type) this.modalService.dismissAll();
            this.listOfStages.forEach((item: any, index: number) => {
              if (item.id === resp.data.id) {
                this.listOfStages[index].name = resp.data.name;
              }
            });
          }
        });
      } else {
        this.service.createStage(this.selected_id, this.selected_type, { ...this.stageForm.value, source_name: this.source_name }).subscribe({
          next: (resp: any) => {
            this.selectedStage = null;
            this.addNew = false;
            this.submitStageForm = false;
            this.stageForm.reset();
            this.initlizeForm();
            if (!this.crm_type) this.modalService.dismissAll();
            resp.data['tasks'] = [];
            this.listOfStages.push(resp.data);
          }
        });
      }
    }
  }

  getRelatedIdInfo() {
    this.taskBoardArray = [];
    this.service.getStages(this.pipeline_id ? this.pipeline_id : this.selected_id, this.selected_type).subscribe({
      next: (stages_resp: any) => {
        this.listOfStages = stages_resp.data;
        if (this.selected_id) {
          this.getTaskByStage('');
        }
        // console.log("stages and tasks array", this.taskBoardArray);
      }
    });
  }

  getTaskByStage(searchedString:string) {
    this.listOfStages.forEach((item: any, index: number) => {
      // console.log("stageeee - ", item.name);
      this.service.getTaskByStage(this.selected_id, this.selected_type, item.id,'', searchedString).subscribe({
        next: (resp: any) => {
          // console.log("task - ", resp.data);
          this.listOfStages[index]['tasks'] = resp.data;
        }
      });
    });
  }
  onCheckboxChecked(event: any, index: any) {
    this.listOfStages[index].is_closed = event.checked;
  }

  drop(event: CdkDragDrop<string[]>, stage: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      //console.log("item", stage);//stage data that the card moved into
      //console.log("event", event.container.data[event.previousIndex]); //card data which has moved
      const dragged_task: any = event.container.data[event.currentIndex];
      const dropped_stage: any = stage;
      this.service.updateTask(this.selected_id, this.selected_type, { id: dragged_task.id, current_stage: dropped_stage.id }).subscribe((resp: any) => { 
        this.getUpdatedBoard(resp.data);
      });
    }
  }

  openAddTaskModal(stage: any, task?: any) {
    this.modalRef = this.modalService.open(AddTaskComponent, { size: 'lg', scrollable: true });
    this.modalRef.componentInstance.task = task;
    this.modalRef.componentInstance.stage = stage;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      console.log("after model submit - ", receivedEntry);
      if (receivedEntry.update) {
        this.getUpdatedBoard(receivedEntry);
      }
    });
  }

  getUpdatedBoard(receivedEntry: any) {
    this.service.getTaskByStage(this.selected_id, this.selected_type, receivedEntry.current_stage).subscribe({
      next: (resp: any) => {
        if (resp.data) {
          this.listOfStages.forEach((item: any, index: number) => {
            if (item.id === receivedEntry.current_stage) {
              this.listOfStages[index].tasks = resp.data;
            }
          });
        }
      }
    });
    if (receivedEntry.previous_stage && (receivedEntry.previous_stage !== receivedEntry.current_stage)) {
      this.service.getTaskByStage(this.selected_id, this.selected_type, receivedEntry.previous_stage).subscribe({
        next: (resp: any) => {
          if (resp.data) {
            this.listOfStages.forEach((item: any, index: number) => {
              if (item.id === receivedEntry.previous_stage) {
                this.listOfStages[index].tasks = resp.data;
              }
            });
          }
        }
      });
    }
  }

  deleteStage(id: string, index: number) {
    this.service.deleteStage(id).subscribe((_) => {
      this.listOfStages.splice(index, 1);
    });
  }

  openAddStageModal(stageAdd?: any, stage?: any) {
    if (!this.crm_type && stageAdd !== "new") {
      this.modalService.open(stageAdd, { size: 'md' });
    } else if (this.crm_type && stage?.id) {
      if (this.selectedStage && this.selectedStage === stage) {
        this.onSubmitStageForm();
        return;
      } else {
        this.selectedStage = stage;
        this.addNew = false;
      }
    } else if (this.crm_type && stageAdd === "new") {
      this.onSubmitStageForm();
      return;
    } else if (stageAdd === "new") {
      this.onSubmitStageForm();
      return;
    }


    if (stage?.id) {
      this.stageForm.patchValue({ name: stage.name, id: stage.id, is_closed: stage.is_closed });
    } else {
      this.stageForm.reset();
      this.initlizeForm();
    }
  }

  openTaskDetailsModal(data: any) {
    this.task_modal_ref = this.modalService.open(TaskDetailModalComponent, { size: 'lg', scrollable: true, modalDialogClass: 'task-board-task' });
    this.task_modal_ref.componentInstance.details = data;
    this.task_modal_ref.componentInstance.is_task = 'task';
    this.task_modal_ref.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getUpdatedBoard(receivedEntry);
    });
  }


  onToggleChange(ev: any, item: any) {
    this.service.updateTask(this.selected_id, this.selected_type, { id: item.id, client_visibility: ev.target.checked }).subscribe(() => {
      this.getUpdatedBoard(item);
    });
  }
}
