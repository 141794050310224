import { FILEEXTENSIONS } from "../data/crm_modal_json";

export const NAVIGATION_KEYS = [
  'Backspace',
  'Delete',
  'Tab',
  'Escape',
  'Enter',
  'Home',
  'End',
  'ArrowLeft',
  'ArrowRight',
  'Clear',
  'Copy'
  // 'Paste'
];

export function maximumNumberAllowed(event: any, maxLength: number) {
  if (NAVIGATION_KEYS.indexOf(event.key) > -1) {
    return true;
  }
  if (event.target.value.length >= maxLength) {
    return false;
  } else {
    return true;

  }
}

export function onOnlyNumbers(event: any, allowed?: boolean) {
  if (NAVIGATION_KEYS.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
    (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
    (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
    // (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
    (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
    (event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
    (event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
    // (event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
    (event.key === 'x' && event.metaKey === true)) // Allow: Cmd+X (Mac)
  {
    return true;
  }
  if (allowed) {
    if (isNaN(Number(event.key)) && event.key !== '+') {
      return false;
    } else {
      return true;
    }
  }
  // Ensure that it is a number and stop the keypress
  if (isNaN(Number(event.key)) || event.code === 'Space') {
    return false;
  } else {
    return true;
  }
}

export function onlyNumberDecimal(event: any, decimalPoints: number, type: any) {
  if (NAVIGATION_KEYS.indexOf(event.key) > -1) {
    return true;
  }
  if (type === 'custom_field') {
    return event.key !== 'e';

  }
  if (event.key === '+' || event.key === '-' || event.key === 'e') {
    return false;
  }
  if (event.key !== 'e' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
    let regex: any;
    (decimalPoints > 0) ? type === 'PERCENTAGE' ? regex = new RegExp(`^[0-9]{0,2}(\\.[0-9]{1,${decimalPoints}})?$`) : regex = new RegExp(`^[0-9]+(\\.[0-9]{1,${decimalPoints}})?$`) : regex = new RegExp(`^[0-9]+(\\.[0-9]{0})?$`);
    const inputElement = event.target as HTMLInputElement;
    return (regex.test(inputElement.value));
  } else {
    return false;
  }
}

export function onParseDomValue(inputData: string) {
  const parser = new DOMParser();
  const parsedData = parser.parseFromString(inputData, 'text/html');
  const dataWithoutTags = parsedData.body.innerText;
  return dataWithoutTags;
}

export function pasteEventInCkeditor(editorComponent: any, pasteContent: any) {
  const exsitData = editorComponent.editorInstance.getData();
  const dataWithoutTags = onParseDomValue(exsitData);
  const setContentData = (dataWithoutTags + pasteContent);
  const returnedObj = {
    actualLimit: setContentData.length,
    type: false
  };
  if ((setContentData.length) > 1000) {
    const editorElement = document.querySelector('.ck-editor__editable_inline');
    if (editorElement) {
      editorElement.scrollTop = editorElement.scrollHeight;
    }
    returnedObj['type'] = true;
    return returnedObj;
  }
  return returnedObj;
}


export function getExtensions(data: any) {
  const filtereData: any = FILEEXTENSIONS.find((ele: any) => ele.type === data);
  return filtereData['file'];
}

export function ErrorLoadImage(target: any, file: any, url?: any) {
  let extensions = '';
  const files = file?.split('.');
  const fileExt = files && files.length > 0 ? files[files?.length - 1] : '';
  if (fileExt) {
    switch (fileExt) {
      case 'pdf':
        extensions = getExtensions('pdf');
        break;

      case 'csv':
        extensions = getExtensions('csv');
        break;

      case 'doc':
      case 'docx':
        extensions = getExtensions('doc');
        break;

      case 'txt':
        extensions = getExtensions('txt');
        break;

      case 'mp4':
      case 'mov':
        extensions = getExtensions('mp4');
        break;

      case 'mp3':
        extensions = getExtensions('mp3');
        break;

      case 'jpeg':
      case 'jpg':
      case 'png':
        extensions = url ? target + url : target + file;
        break;

      default:
        extensions = getExtensions('unknown');
    }
  }
  return extensions;
}

export function customDateTimeConversion(inputDateTime: any) {
  const date = new Date(inputDateTime);

  // Check if the given date is today
  const today = new Date();
  const isToday = date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isToday) {
    // Format time
    let hours = date.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const formattedTime = `${hours}:${minutes} ${amOrPm}`;
    return formattedTime;
  } else {
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
}

export function onCheckRequiredFieldStatus(allFieldData: any, featuresData: any, showErrorMessage: boolean) {
  const revokedSysFieldsList = {};
  const requiredFieldLists = Object.entries(allFieldData)?.filter((elem: any) => (!(Array.isArray(elem[1]))));
  requiredFieldLists.forEach((elem: any) => {
    if (elem[0] !== 'pipeline' && elem[0] !== 'contact' && elem[0] !== 'company') {
      if (featuresData) {
        Object.keys(featuresData).find((ele: any) => {
          const elementName = elem[0]?.split("_")[0];
          if (ele.startsWith(elementName.slice(0, -1))) {
            if (!featuresData[ele]) {
              if (elem[1].is_mandatory) {
                showErrorMessage = true;
              }
              Object.assign(revokedSysFieldsList, {
                [elem[0]]: {
                  is_visible: elem[1].is_mandatory
                }
              });
            }
          }
        });
      }
    }
  });
  return { revokedSysFieldsList, showErrorMessage };
}

