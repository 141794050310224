<div class="container-fluid p-0 contact-screen bg-white" *ngIf="featuresData?.contact"
  [ngClass]="isInnerScreen ? 'innerScreenWidth' : 'normalScreenWidth'">
  <div class="d-flex align-items-center justify-content-between bg-white pb-2 pt-1 mb-2"
    [ngClass]="isInnerScreen ? 'px-3' : 'new-design-padding'"
    style="box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.13);border-bottom: 1px solid #E1E1E1;">
    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" (navChange)="tabChange($event)"
      class="nav-tabs nav-tabs-custom mt-0 border-none auto-hide-scroll-bar leftMaxWidth flex-nowrap"
      [ngClass]="activeId !== 1 ? 'width-fit-content' : ''">
      <li [ngbNavItem]="1" class="me-2" style="min-width: fit-content;">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-14">Active Contacts</span>
        </a>
        <ng-template ngbNavContent>
          <div class="custom-table-div-crm-height d-flex contacts-screen flex-column justify-content-between"
            [ngClass]="isInnerScreen ? 'px-3' : 'new-design-padding'">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7"
                [fromLocation]="'CRM'"></app-table-skeleton>
              <table class="table align-middle table-nowrap table-hover mb-0 w-100" *ngIf="!tableSkeletonLoader">
                <thead>
                  <tr class="">
                    <th scope="col" class="">
                      <mat-checkbox (change)="$event ? masterToggle(currentPage) : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" class="mat_checkbox"></mat-checkbox>
                    </th>
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class="text-capitalize">
                        {{col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                    <th style="min-width: 60px;">
                      Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr class="cursor" *ngFor="let item of contactListArray; let i = index"
                    [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'contact'}">
                    <td style="padding:12px 16px !important;">
                      <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(item) && individualRecordToogle(item) : null"
                        [checked]="selection.isSelected(item)" class="mat_checkbox"></mat-checkbox>
                    </td>
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                    <!-- <td>{{item?.display_id ? item.display_id : "-"}}</td> -->
                    <!-- <td class="home-text-color"> -->
                    <!-- {{ item.first_name ? item.first_name : "-" }} {{item.last_name}} -->
                    <!-- <app-table-popover [editEnable]="item?.allowAccess" [inputType]="'text'" [inputValue]="item?.first_name"
                      [labelName]="'Name'" [formName]="'first_name'" (update)="onUpdatePopOver($event, item)"></app-table-popover> -->
                    <!-- </td> -->
                    <!-- <td>{{ item.email ? item.email : "-" }}</td> -->
                    <!-- <td> -->
                    <!-- {{ item.phone_number ? item.phone_number : "-" }} -->
                    <!-- <div class="d-flex align-items-center">
                      <span *ngIf="item?.phone_number">{{item.ccode}}&nbsp;</span>
                      <app-table-popover [editEnable]="item?.allowAccess" [inputType]="'text'" [cCode]="item?.ccode ? item?.ccode :''"
                        [inputValue]="item?.phone_number" [labelName]="'Mobile Number'" [formName]="'phone_number'"
                        (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </div>
                  </td>
                  <td>
                    <app-table-popover [editEnable]="item?.allowAccess" [inputType]="'dropdown'" [inputValue]="item.contact_type"
                      [formName]="'contact_type'" [labelName]="'Contact Type'" [dropDownItems]="contactTypeItems"
                      [selectedItem]="item.value" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                  </td>
                  <td> -->
                    <!-- {{item.company_name ? item.company_name : "-"}} -->
                    <!-- <app-table-popover [editEnable]="item?.allowAccess" [inputType]="'dropdown'" [inputValue]="item?.company_name"
                      [formName]="'company_id'" [labelName]="'Company Name'" [dropDownItems]="companiesListArray"
                      [selectedItem]="item.company" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                  </td>
                  <td>
                    <app-table-popover
                      [editEnable]="item?.allowAccess && item?.owner === loggedInUser.data?.id || loggedInUser.data?.role === 'ADMIN'"
                      [inputType]="'dropdown'" [inputValue]="(item?.owner_info?.fname || '-') + ' ' + (item?.owner_info?.lname || '')"
                      [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                      (update)="onUpdatePopOver($event, item)"></app-table-popover>
                  </td>
                  <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
                      [ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
                      item.channel ? (item.channel.replace("_", " ") |
                      titlecase) : "NA" }}</span></td>
                  <td *ngIf="item.created_at">{{ item.created_at | dateConversion }}</td>
                  <td *ngIf="!item.created_at">NA</td> -->
                    <!-- <td>{{item.first_name}}</td>
                              <td>{{item.last_name}}</td> -->
                    <!-- <td>Last Activity Date & Time</td>
                              <td>{{item.updated_by}}</td>
                              <td>{{item.updated_at | date: 'M/d/yy, h:mm a'}}</td>
                              <td>Mailing Street</td>
                              <td>Mailing Address</td>
                              <td>Mailing City</td>
                              <td>Mailing Country</td>
                              <td>Mailing State</td>
                              <td>Shipping Address</td>
                              <td>Shipping Street</td>
                              <td>Shipping City</td>
                              <td>Shipping Country</td>
                              <td>Zip Code</td>
                              <td>Title</td> -->
                    <td (click)="$event.stopPropagation()"
                      [ngStyle]="{'cursor':!isSelected.multiple ? 'pointer' : 'auto'}">
                      <div class="dropdown" ngbDropdown>
                        <img class="action-btn bg-white border-radius-8" width="32"
                          src="assets/images/newUI/CRM/action_icon.svg" style="z-index: 99;"
                          [ngStyle]="{'opacity':!isSelected.multiple ? '1' : '0.5'}"
                          [matMenuTriggerFor]="!isSelected.multiple ? menuadd : null" alt="">
                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                          <button mat-menu-item (click)="openContactsModalDialog(item)" class="py-2">
                            Edit
                          </button>
                          <button mat-menu-item (click)="confirmDelete(item.id)" class="py-2">
                            InActive
                          </button>
                        </mat-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

            </div>
            <div class="mat-page-custom">
              <mat-paginator [length]="contactsObj.total" [pageSize]="page_size" [pageIndex]="pageIndex"
                (page)="page($event)" aria-label="Select page" showFirstLastButtons
                [pageSizeOptions]="[20, 40, 60, 80, 100]">
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" class="me-2" style="min-width: fit-content;">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-14">InActive Contacts</span>
        </a>
        <ng-template ngbNavContent>
          <div class="row mt-3 mb-4 customised-sec" *ngIf="activeId === 1">
            <!-- <div class="col-md-6 d-flex align-items-center height-max-content">
              <p class="m-0 heading font-size-18 fw-700 text-primary" *ngIf="!companyId">{{contactsJson.heading_label}}
              </p>
            </div> -->
          </div>
          <div class="custom-table-div-crm-height d-flex flex-column justify-content-between"
            [ngClass]="isInnerScreen ? 'px-3' : 'new-design-padding'">
            <div class="table-responsive table-height fixed-table">
              <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="inActivetableColumns?.length"
                [fromLocation]="'CRM'"></app-table-skeleton>
              <table class="table align-middle table-nowrap table-hover mb-0 w-100" *ngIf="!tableSkeletonLoader">
                <thead>
                  <tr class="">
                    <ng-container *ngFor="let col of originalTableColumnLists">
                      <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                        scope="col" class="text-capitalize">
                        {{col.field_name!.split("_").join(" ")}}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                  <tr *ngFor="let item of contactListArray; let i = index">
                    <ng-container *ngTemplateOutlet="table_lists;context:{item:item}"></ng-container>
                  </tr>
                  <!-- <tr class="cursor" 
                    [routerLink]="['../crm-details']"
                    [queryParams]="{ id: item.id, showtype: 'contact',contact_active: item?.is_active }">
                    <td>{{item?.display_id ? item.display_id : "-"}}</td>
                    <td class="home-text-color">
                      {{ item.first_name ? item.first_name : "-" }} {{item.last_name}}

                    </td>
                    <td>{{ item.email ? item.email : "-" }}</td>
                    <td>
                      {{ item.phone_number ? (item.ccode + item.phone_number) : "-" }}
                    </td>
                    <td> {{item?.contact_type ?item.contact_type : "-"}}</td>
                    <td>
                      {{item.company_name ? item.company_name : "-"}}
                    </td>
                    <td>
                      {{(item?.owner_info?.fname || '-') + ' ' + (item?.owner_info?.lname || '')}}
                    </td>
                    <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
                        [ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
                        item.channel ? (item.channel.replace("_", " ") |
                        titlecase) : "NA" }}</span></td>
                    <td *ngIf="item.created_at">{{ item.created_at | dateConversion }}</td>
                    <td *ngIf="!item.created_at">NA</td>
                  </tr> -->
                </tbody>
              </table>
              <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

            </div>
            <div class="mat-page-custom">
              <mat-paginator [length]="contactsObj.total" [pageSize]="page_size" [pageIndex]="pageIndex"
                (page)="page($event)" aria-label="Select page" showFirstLastButtons
                [pageSizeOptions]="[20, 40, 60, 80, 100]">
              </mat-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" class="me-2" style="min-width: fit-content;">
        <a ngbNavLink class="d-flex">
          <span class="d-sm-block font-size-14">Contacts List</span>
        </a>
        <ng-template ngbNavContent>
          <app-contact-groups [isInnerScreen]="isInnerScreen"></app-contact-groups>
        </ng-template>
      </li>
    </ul>
    <div
      class="right-controls-sec d-flex align-items-center auto-hide-scroll-bar ps-1 pb-1 bg-white customised-sec position-fixed"
      *ngIf="!isInnerScreen">
      <div class="d-flex p-0 align-items-center height-max-content">
        <mat-form-field appearance="outline" *ngIf="activeId === 1 || activeId === 2"
          class="margin-right-14 main-filter position-relative mat-hint-none main-filter"
          style="max-width: 150px;min-width: auto;">
          <mat-select placeholder="Contacts" (ngModelChange)="onFilterChange($event)" [(ngModel)]="filter">
            <mat-option class="font-size-12" [value]="item.value"
              *ngFor="let item of select_type">{{item.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
          style="width: 212px;" *ngIf="activeId === 1 || activeId === 2">
          <input style="outline: none" class="font-size-16" id="search-box" matInput placeholder="Search Here..."
            [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
          <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
              alt=""></mat-icon>
        </mat-form-field>
        <div matTooltip="Sort By" *ngIf="activeId === 1 || activeId === 2"
          class="btn custom-filter-btn margin-right-14 d-flex align-items-center font-size-16"
          (click)="openFiltersModal('sort')">
          <img src="assets/images/sort_logo.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Sort By</span> -->
        </div>
        <div matTooltip="Filter" *ngIf="activeId === 1 || activeId === 2"
          class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="openFiltersModal('filters')">
          <img src="assets/images/filter_log.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Filter By</span> -->
        </div>
        <button class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="clearCustomFilter()" *ngIf="activeId === 1 && (selectedSortOptions || column_filters.length > 0)"
          [disabled]="isSelected.multiple">
          <span class="limit-line-length-1 width-max">Clear filters</span>
        </button>
        <div matTooltip="In Active" class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="confirmForInactivation()" *ngIf="activeId === 1">
          <img src="assets/images/newUI/CRM/inActive_icon.svg" alt="">
        </div>
        <div (click)="downloadContacts($event)" *ngIf="activeId === 1" matTooltip="Export"
          class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14 cursor">
          <img src="assets/images/newUI/CRM/export_icon.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Export</span> -->
        </div>
        <div *ngIf="!companyId && featuresData?.contact_add && activeId === 1" matTooltip="Import"
          (click)="importEexportModalDialog(importExportModel, 'import')"
          class="btn custom-filter-btn d-none align-items-center font-size-16 margin-right-14 cursor">
          <img src="assets/images/newUI/CRM/import.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Import</span> -->
        </div>
        <div matTooltip="Uploaded Files" *ngIf="activeId === 1" [routerLink]="['/crm/import-files']" [queryParams]="{type: 'contact'}"
          class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14 cursor">
          <img src="assets/images/newUI/CRM/uploaded_files.svg" width="20" alt="">

        </div>
        <div class="btn custom-filter-btn px-2 margin-right-14 d-flex align-items-center contacts-filter-lable"
          *ngIf="activeId === 1 || activeId === 2">
          <!-- <span class="me-3 " (click)="checkedContacts()">{{contactsJson.Filter_label}}</span> -->
          <span mat-raised-button class="menuTrigger  material-icons cursor" [matMenuTriggerFor]="filter_search_menu"
            matTooltip="Table Columns">filter_list</span>

          <mat-menu #filter_search_menu="matMenu" class="tableColumnLists customised-sec">
            <mat-form-field (click)="$event.stopPropagation()" appearance="outline" id="inputEle"
              class="px-3 py-2 mat-hint-none searching-box">
              <input (keyup)="onColumnFilterSearch($event)" [(ngModel)]="columnFilterSearch" style="outline: none"
                matInput placeholder="Search Column" />
              <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                  alt=""></mat-icon>
            </mat-form-field>
            <div class="list-sec" (click)="$event.stopPropagation()">
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div cdkDrag *ngFor="let item of tableColumns;let i = index">
                  <button mat-menu-item id="{{ i }}"
                    [disabled]="['display_id','first_name','email','contact_type','tags'].includes(item?.field_name)"
                    (click)="$event.stopPropagation()"
                    *ngIf="item.field_name !== 'ccode' && item.field_name !== 'assignee'">
                    <mat-checkbox [checked]="item?.status" (change)="selectFilterLable($event,item,i)"
                      class="text-capitalize">{{(item?.field_name!.split("_").join(" ") | titlecase) ||
                      ''}}</mat-checkbox>
                  </button>
                </div>
              </div>
              <div *ngIf="tableColumnNoResFound" class="d-flex align-items-center justify-content-center"
                style="height:100%">
                No Result Found
              </div>
            </div>
            <div class="d-flex justify-content-end apply-btn-sec px-3">
              <button class="btn btn-primary d-flex align-items-center font-size-13" [disabled]="tableColumnNoResFound"
                (click)="!tableColumnNoResFound && onUpdateTableColumns()">Apply
                & Save</button>
            </div>
          </mat-menu>
        </div>
        <button class="btn btn-primary d-flex align-items-center margin-right-14 font-size-14"
          *ngIf="!companyId && featuresData?.contact_add && activeId === 1" [disabled]="isSelected.multiple"
          (click)="openContactsModalDialog()">
          <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
          <span class="ms-1 limit-line-length-1 text-left">{{'Contact'}}</span>
        </button>
        <button class="btn btn-primary d-flex align-items-center margin-right-14 font-size-14"
          (click)="openDialog(addtolistModel)" *ngIf="activeId === 1 || activeId === 3">
          <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
          <span class="ms-1 limit-line-length-1 text-left">{{'List'}}</span>
        </button>
        <button class="btn btn-primary d-flex align-items-center me-1" [disabled]="isSelected.multiple"
          (click)="openTaskBoardPreview()" *ngIf="activeId === 1">
          <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
          <span class="ms-1 limit-line-length-1 text-left">Stage</span>
        </button>
      </div>
      <div class="d-flex p-0 justify-content-end align-items-center height-max-content">
      </div>
    </div>
  </div>
  <div [ngbNavOutlet]="customNav"></div>
</div>

<ng-template let-item="item" #table_lists>
  <ng-container *ngFor="let col of originalTableColumnLists">
    <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')" style="max-width: 200px;"
      [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '100px' : '150px'}">
      <div *ngIf="col.system_field">
        <span *ngIf="col.field_name === 'phone_number'">{{item[col.field_name]
          ?
          item.ccode :
          ''}}&nbsp;{{item[col.field_name] || 'NA'}}</span>
        <span class="limit-line-length-1" *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
          || '-') + ' ' +
          (item?.owner_info?.lname || '')}}</span>
        <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
          {{item.created_at ? (item.created_at | dateConversion) : 'NA'}}
        </span>
        <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
          {{item.created_at
          | dateConversion }} {{ item.created_at | date: 'h:mm a' || 'NA'}}
        </span>
        <div *ngIf="col.field_name === 'tags'" class="d-flex">
          <p class="text-name m-0" *ngIf="!item['tag_name']" style="width: -webkit-fill-available;">NA</p>
          <p class="text-name text-wrap w-100 cursor" style="margin-bottom: 0px !important;">
            <app-table-popover [inputType]="'dropdown'" [inputValue]="item?.tag_name" [formName]="'tags'"
              [labelName]="'Tag'" [dropDownItems]="tagsListArray" [selectedItem]="item.tags"
              (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </p>
        </div>
        <div *ngIf="col.field_name === 'contact_type'" class="d-flex">
          <p class="text-name m-0" *ngIf="!item['contact_type']" style="width: -webkit-fill-available;">NA</p>
          <p class="text-name text-wrap w-100 cursor" style="margin-bottom: 0px !important;">
            <app-table-popover [inputType]="'dropdown'" [inputValue]="item?.contact_type" [formName]="'contact_type'"
              [labelName]="'Contact Type'" [dropDownItems]="contact_type" [selectedItem]="item?.contact_type"
              (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </p>
        </div>
        <div *ngIf="col.field_name === 'channel'">
          <p *ngIf="item['channel']" style="background-color: #FCEED8;color: #73321B;"
            class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
            <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
              [style]="{'background-color': '#73321B'}"></span>
            <span class="limit-line-length-1 word-break"> {{item['channel']}}</span>
          </p>
          <p class="text-name m-0" *ngIf="!item['channel']">NA</p>
        </div>
        <span class="limit-line-length-1" *ngIf="col.field_name === 'company'">{{item.company_name ||
          'NA'}}</span>
        <span class="limit-line-length-1"
          *ngIf="col.field_name !== 'phone_number' && col.field_name !== 'contact_type' && col.field_name !== 'first_name' && col.field_name !== 'ccode' && col.field_name !== 'tags' && col.field_name !== 'channel' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE' && col.field_name !== 'owner' && col.field_name !== 'company'"
          [innerHTML]="item[col.field_name] || 'NA'"></span>

        <span *ngIf="col.field_name === 'first_name'">
          <app-table-popover [inputType]="'text'" [inputValue]="item[col.field_name]" [labelName]="'Name'"
            [formName]="'first_name'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
        </span>
      </div>
      <div *ngIf="!col.system_field">
        <ng-container *ngFor="let customField of item?.custom_contact_fields">
          <ng-container *ngIf="customField.label === col.field_name">
            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
              {{customField.data ? (customField.data | dateConversion) : 'NA'}}
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
              {{customField.data
              | dateConversion }} {{ customField.data | date: 'h:mm a' || 'NA'}}
            </span>
            <span *ngIf="col.data_type === 'DATE_RANGE'">
              <span class="limit-line-length-1" *ngIf="customField.data && customField.data.split('&').length > 1">
                {{customField?.data.split("&")[0].split("-").reverse().join("/") + ' - ' +
                customField?.data.split("&")[1].split("-").reverse().join("/")}}
              </span>
              <span *ngIf="!customField.data">
                NA
              </span>
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'PHONE_NUMBER'">
              {{customField?.data?.custom_ccode ?
              customField?.data?.custom_ccode :
              ''}}&nbsp;{{customField?.data?.custom_phone_number || 'NA'}}
            </span>
            <span class="limit-line-length-1" *ngIf="col.data_type === 'FILE'">
              {{customField?.data?.file_name || 'NA'}}
            </span>
            <span class="limit-line-length-1"
              *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'"
              [innerHTML]="customField.data || 'NA'">
            </span>
          </ng-container>
        </ng-container>
      </div>
    </td>
  </ng-container>
</ng-template>

<ng-template #addtolistModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">{{contactsJson.add_to_list_label}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row px-2 mb-3">
      <label class="mb-2 fw-600 text-primary">{{contactsJson.lists_label}}<small class="text-danger">*</small></label>
      <ng-select class="form-control" [items]="grouplistArray" bindLabel="name" [addTag]="addOptionToList"
        [multiple]="false" placeholder='Select' [(ngModel)]="selected_group">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer border-0 d-flex justify-content-end mb-5">
    <button type="button" class="d-flex align-items-center btn btn-primary small" (click)="addToList()">
      <mat-icon class="me-1">playlist_add_check</mat-icon> {{contactsJson.add_to_list_label}}
    </button>
  </div>
</ng-template>

<ng-template #importExportModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">
      {{contactsJson.label_import}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeImportModal()"></button>
  </div>
  <div class="modal-body">
    <div class="" *ngIf="stepper === 1">
      <div class="contact-dotted-border text-center mb-3">
        <input type="file" class="form-control" (change)="onFileChange($event)" accept=".csv" />
      </div>
      <div class="d-flex justify-content-between">
        <div class="">
          <input type="checkbox" id="remember-me" data-md-icheck (change)="toggleVisibility($event)" />
          <label class="ms-1 text-secondary" for="remember-me">
            Add to list
          </label>
        </div>
        <a class="cursor" (click)="downloadSampleFile()">Download Sample
          File</a>
      </div>
      <div class="" *ngIf="bulkimport_addtoList">
        <label class="mb-2 fw-600 text-primary">{{contactsJson.lists_label}}</label>
        <ng-select class="form-control" [items]="grouplistArray" bindLabel="name" [multiple]="false"
          placeholder='Select' [(ngModel)]="selected_group">
        </ng-select>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button type="button" class="btn btn-primary" (click)="importContacts()">
          {{contactsJson.import_btn}} <!--[disabled]="!enableImportBtn"-->
        </button>
      </div>
    </div>
    <div class="" *ngIf="stepper === 2">
      <h6 class="fw-600">What do you want to do with the records in the file?</h6>
      <mat-radio-group [(ngModel)]="operation">
        <mat-radio-button value="CREATE">Add as new Contacts</mat-radio-button>
        <mat-radio-button value="UPDATE">Update existing Contacts only</mat-radio-button>
        <mat-radio-button value="BOTH">Both</mat-radio-button>
      </mat-radio-group>

      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-light me-2" (click)="closeImportModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="fieldsMapping()">
          Next
        </button>
      </div>
    </div>
  </div>
</ng-template>