import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/service/auth.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.scss']
})
export class WorkHoursComponent implements OnInit {
  form!: FormGroup;
  week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  time_slotes = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
  ];
  current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
  currentUserTimeZone = 'Asia/Calcutta';
  activatedRouter = '';
  @Output() onfinish: EventEmitter<any> = new EventEmitter();
  loggedInUser: any;
  @Input() type!: string;
  @Input() staff_id!: string;

  constructor(private settingService: SettingsService, private setupService: SetUpService, private authService: AuthService,
    private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.activatedRouter = this.router.url;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    // this.currentUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.currentUserTimeZone = this.authService.getCompanyData()?.timezone;
    this.initlizeWorkingHoursForm();
    this.getWorkinghours();
  }

  getWorkinghours() {
    this.setupService.getOrgWorkHours(this.type || 'organisation', this.staff_id || this.current_user.data.organisation).subscribe((resp: any) => {

      this.setWorkingHours(resp);

      // resp.data[0].slots.forEach(() => {
      //   this.addMonField();
      // });
      // resp.data[1].slots.forEach(() => {
      //   this.addTueField();
      // });
      // resp.data[2].slots.forEach(() => {
      //   this.addWedField();
      // });
      // resp.data[3].slots.forEach(() => {
      //   this.addThuField();
      // });
      // resp.data[4].slots.forEach(() => {
      //   this.addFriField();
      // });
      // resp.data[5].slots.forEach(() => {
      //   this.addSatField();
      // });
      // resp.data[6].slots.forEach(() => {
      //   this.addSunField();
      // });
      // this.mondaySlots.patchValue(resp.data[0].slots);
      // this.tuesdaySlots.patchValue(resp.data[1].slots);
      // this.wednesdaySlots.patchValue(resp.data[2].slots);
      // this.thursdaySlots.patchValue(resp.data[3].slots);
      // this.fridaySlots.patchValue(resp.data[4].slots);
      // this.saturdaySlots.patchValue(resp.data[5].slots);
      // this.sundaySlots.patchValue(resp.data[6].slots);
    });
  }

  initlizeWorkingHoursForm(data?: any) {
    this.form = new FormGroup({
      "MONDAY": new FormGroup({
        days: new FormControl(data ? data.monday?.days : "MONDAY"),
        status: new FormControl(data ? data.monday?.status : false),
        slots: new FormArray([])
      }),
      "TUESDAY": new FormGroup({
        days: new FormControl(data ? data.tuesday?.days : "TUESDAY"),
        status: new FormControl(data ? data.tuesday?.status : false),
        slots: new FormArray([])
      }),
      "WEDNESDAY": new FormGroup({
        days: new FormControl(data ? data.wednesday?.days : "WEDNESDAY"),
        status: new FormControl(data ? data.wednesday?.status : false),
        slots: new FormArray([])
      }),
      "THURSDAY": new FormGroup({
        days: new FormControl(data ? data.thursday?.days : "THURSDAY"),
        status: new FormControl(data ? data.thursday?.status : false),
        slots: new FormArray([])
      }),
      "FRIDAY": new FormGroup({
        days: new FormControl(data ? data.friday?.days : "FRIDAY"),
        status: new FormControl(data ? data.friday?.status : false),
        slots: new FormArray([])
      }),
      "SATURDAY": new FormGroup({
        days: new FormControl(data ? data.saturday?.days : "SATURDAY"),
        status: new FormControl(data ? data.saturday?.status : false),
        slots: new FormArray([])
      }),
      "SUNDAY": new FormGroup({
        days: new FormControl(data ? data.sunday?.days : "SUNDAY"),
        status: new FormControl(data ? data.sunday?.status : false),
        slots: new FormArray([])
      })
    });
  }

  get mondayArray(): FormGroup {
    return this.form.get('MONDAY') as FormGroup;
  }

  get mondaySlots(): FormArray {
    return this.form.controls['MONDAY'].get("slots") as FormArray;
  }

  addMonField() {
    this.mondaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeMonField(i: number) {
    this.mondaySlots.removeAt(i);
  }

  get tuesdayArray(): FormGroup {
    return this.form.get("TUESDAY") as FormGroup;
  }

  get tuesdaySlots(): FormArray {
    return this.form.controls['TUESDAY'].get("slots") as FormArray;
  }

  addTueField() {
    this.tuesdaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeTueField(i: number) {
    this.tuesdaySlots.removeAt(i);
  }

  get wednesdayArray(): FormGroup {
    return this.form.get("WEDNESDAY") as FormGroup;
  }

  get wednesdaySlots(): FormArray {
    return this.form.controls['WEDNESDAY'].get("slots") as FormArray;
  }

  addWedField() {
    this.wednesdaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeWedField(i: number) {
    this.wednesdaySlots.removeAt(i);
  }

  get thursdayArray(): FormGroup {
    return this.form.get("THURSDAY") as FormGroup;
  }

  get thursdaySlots(): FormArray {
    return this.form.controls['THURSDAY'].get("slots") as FormArray;
  }

  addThuField() {
    this.thursdaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeThuField(i: number) {
    this.thursdaySlots.removeAt(i);
  }

  get fridayArray(): FormGroup {
    return this.form.get("FRIDAY") as FormGroup;
  }

  get fridaySlots(): FormArray {
    return this.form.controls['FRIDAY'].get("slots") as FormArray;
  }

  addFriField() {
    this.fridaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeFriField(i: number) {
    this.fridaySlots.removeAt(i);
  }

  get saturdayArray(): FormGroup {
    return this.form.get("SATURDAY") as FormGroup;
  }

  get saturdaySlots(): FormArray {
    return this.form.controls['SATURDAY'].get("slots") as FormArray;
  }

  addSatField() {
    this.saturdaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeSatField(i: number) {
    this.saturdaySlots.removeAt(i);
  }

  get sundayArray(): FormGroup {
    return this.form.get("SUNDAY") as FormGroup;
  }

  get sundaySlots(): FormArray {
    return this.form.controls['SUNDAY'].get("slots") as FormArray;
  }

  addSunField() {
    this.sundaySlots.push(new FormArray([new FormControl(''), new FormControl('')]));
  }

  removeSunField(i: number) {
    this.sundaySlots.removeAt(i);
  }

  setWorkingHours(resp: any) {
    // this.meetingId = resp?.data[0].id;

    const data: any = {};
    resp?.data[0].working_hours.forEach((day: any, i: any) => {
      console.log(day, day.days);
      data[day.days.toLowerCase()] = day;
    });

    this.initlizeWorkingHoursForm(data);

    Object.keys(data).forEach((d: any) => {
      data[d].slots.forEach(() => {
        switch (d) {
          case 'monday':
            this.addMonField();
            break;
          case 'tuesday':
            this.addTueField();
            break;
          case 'wednesday':
            this.addWedField();
            break;
          case 'thursday':
            this.addThuField();
            break;
          case 'friday':
            this.addFriField();
            break;
          case 'saturday':
            this.addSatField();
            break;
          case 'sunday':
            this.addSunField();
            break;
        }
      });
    });

    this.mondaySlots.patchValue(data.monday?.slots);
    this.tuesdaySlots.patchValue(data.tuesday?.slots);
    this.wednesdaySlots.patchValue(data.wednesday?.slots);
    this.thursdaySlots.patchValue(data.thursday?.slots);
    this.fridaySlots.patchValue(data.friday?.slots);
    this.saturdaySlots.patchValue(data.saturday?.slots);
    this.sundaySlots.patchValue(data.sunday?.slots);
  }

  onSubmit() {
    this.currentUserTimeZone = this.authService.getCompanyData()?.timezone;
    // this.settingService.postWorkingHours(this.form.value).subscribe((resp: any) => {
    this.setupService.postWorkingHours({ type: this.type || 'organisation', 
      staff_id: this.staff_id || this.current_user.data.organisation, working_hours: Object.keys(this.form.value).map(day => this.form.value[day]),
      time_zone: this.type === 'appointment' ? this.current_user.time_zone : this.currentUserTimeZone
    }).subscribe(() => {
      //@typescript-eslint/no-empty-function
      // });
      if(this.modalService){
        this.modalService.dismissAll();
      }
      this.getWorkinghours();
      if (this.activatedRouter === '/onboarding') this.onStepperChange();
    });
  }

  onStepperChange() {
    this.onfinish.emit(true);
  }
}
