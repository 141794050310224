import { Component, ChangeDetectorRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, DatesSetArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import { createEventId } from './event-utils';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { AuthService } from 'src/app/core/service/auth.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-calendar',
  templateUrl: './header-calendar.component.html',
  styleUrls: ['./header-calendar.component.scss']
})
export class HeaderCalendarComponent {
  calendarVisible = true;
  showModal: boolean | undefined;
  eventModal = false;
  title = 'ngularfullcalendarbootstrap';
  selected: any;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  data: EventInput[] = [];
  slots_data: any[] = [];
  dummytext = [];
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin
      // listPlugin
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialView: 'dayGridMonth',
    timeZone: 'America/Los_Angeles',
    initialEvents: this.data, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    events: this.dummytext,
    datesSet: this.handleDatesSet.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];
  @ViewChild('first', { read: ElementRef }) firstName!: ElementRef<HTMLElement>;
  @ViewChild('last', { read: ElementRef }) lastName!: ElementRef<HTMLElement>;
  firstNameAutofilled!: boolean;
  lastNameAutofilled!: boolean;
  calendarView = 'month';
  eventsPromise!: Promise<EventInput>;
  selectedEvent: any;
  upcomingEvents: any[] = [];
  selectedType = 'dayGridMonth';
  selectedDate!: string;
  EndDate!: string;
  calendarConnection: any;

  constructor(private changeDetector: ChangeDetectorRef, private _autofill: AutofillMonitor,
    private authService: AuthService, private settingsService: SettingsService, private renderer: Renderer2,
    private modalService: NgbModal) {
    this.checkConnection();
  }

  // ngAfterViewInit() {
  //   this._autofill
  //     .monitor(this.firstName)
  //     .subscribe(e => (this.firstNameAutofilled = e.isAutofilled));
  //   this._autofill
  //     .monitor(this.lastName)
  //   .subscribe(e => (this.lastNameAutofilled = e.isAutofilled));
  // }

  ngOnDestroy() {
    this._autofill.stopMonitoring(this.firstName);
    this._autofill.stopMonitoring(this.lastName);
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = ('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
      this.showModal = true;
    }
  }
  openModalBasedOnCursorPosition(event: any) {
    const full_calendar = document.getElementsByTagName('full-calendar');
    const cursorXPos = event?.jsEvent?.clientX;
    const cursorYPos = event?.jsEvent?.clientY;
    const eventBoxWidth = document.querySelector('.fc-daygrid-event-harness')?.clientWidth || 0;
    const eventBoxHeight = document.querySelector('.fc-daygrid-event-harness')?.clientHeight || 0;
    setTimeout(() => {
      const modal_dialog = document.querySelectorAll('.modal-dialog');
      const myModal = document.getElementById('myModal');
      const arrowSec = document.querySelector('.adding-arrow');
      if (myModal && modal_dialog) {
        const modalPositinY = (event.jsEvent.clientY - event.jsEvent.layerY) - eventBoxHeight;
        const modalPositionX = (event.jsEvent.clientX - event.jsEvent.layerX) + eventBoxWidth + 6;
        this.renderer.setStyle(modal_dialog[1], 'position', 'absolute');
        this.renderer.setStyle(modal_dialog[1], 'top', modalPositinY - 48 + 'px');
        if (cursorXPos / 2 > ((full_calendar[0] ? (full_calendar[0].clientWidth) / 2 : 0))) {
          this.renderer.setStyle(modal_dialog[1], 'left', (modalPositionX - (eventBoxWidth + 16 + modal_dialog[1].clientWidth)) + 'px');
          this.removeAllClass(arrowSec);
          this.renderer.addClass(arrowSec, 'triangle-right');
        } else {
          this.renderer.setStyle(modal_dialog[1], 'left', modalPositionX + 'px');
          this.removeAllClass(arrowSec);
          this.renderer.addClass(arrowSec, 'triangle-left');
        }
        if (cursorYPos > (full_calendar[0] ? (full_calendar[0].clientHeight - 40) / 2 : 0)) {
          this.renderer.setStyle(modal_dialog[1], 'transform', `translateY(-${modal_dialog[1].clientHeight - 24}px)`);
          this.removeAllClass(arrowSec);
          if (!(cursorXPos / 2 > ((full_calendar[0] ? (full_calendar[0].clientWidth) / 2 : 0)))) {
            this.renderer.addClass(arrowSec, 'triangle-bottom-left');
          } else {
            this.renderer.addClass(arrowSec, 'triangle-bottom-right');
          }
        } else {
          this.renderer.setStyle(modal_dialog[1], 'transform', 'translateY(0%)');
        }
      }
    }, 10);
  }

  removeAllClass(arrowSec: any) {
    this.renderer.removeClass(arrowSec, 'triangle-right');
    this.renderer.removeClass(arrowSec, 'triangle-left');
    this.renderer.removeClass(arrowSec, 'triangle-bottom-left');
    this.renderer.removeClass(arrowSec, 'triangle-bottom-right');

  }

  handleEventClick(clickInfo: EventClickArg) {
    this.eventModal = true;
    this.openModalBasedOnCursorPosition(clickInfo);
    this.selectedEvent = clickInfo.event;

    if (this.selectedEvent.extendedProps.type === 'Appointment') {
      this.selectedEvent.date = this.selectedEvent.extendedProps.startStr;
      this.selectedEvent.start_time = this.selectedEvent.extendedProps.startStr;
      this.selectedEvent.end_time = this.selectedEvent.extendedProps.endStr.split('T')[1];
      this.selectedEvent.email = this.selectedEvent.extendedProps.info?.user?.email;
      this.selectedEvent.name = this.selectedEvent.extendedProps.info?.user?.fname + " " + this.selectedEvent.extendedProps.info?.user?.lname;
      this.selectedEvent.guest_email = [];
      this.selectedEvent.meeting_link = this.selectedEvent.extendedProps?.online_meeting_medium === 'worke_url' ? this.selectedEvent.extendedProps?.staff_twilio_meeting_url :
        this.selectedEvent.extendedProps?.online_meeting_medium !== 'worke_url' ? this.selectedEvent.extendedProps?.video_meeting_url : '';
      this.selectedEvent.type = 'Appointment';
    } else if (this.selectedEvent.extendedProps.type === 'Meeting') {
      // this.selectedEvent.endtime = (clickInfo.event.endStr.split('T')[1]);
      this.authService.getSlotDetailsByid(clickInfo.event.id).subscribe((resp: any) => {
        if (resp.data.staff) {
          this.settingsService.getStaff_infoById(resp.data.staff).subscribe((staff: any) => {
            this.selectedEvent.staff = staff.data.fname;
          });
        } else {
          this.selectedEvent.staff = '';
        }

        this.selectedEvent.date = resp.data.startStr;
        // this.selectedEvent.start_time = resp.data.startStr.split('T')[1];
        // this.selectedEvent.end_time = resp.data.endStr.split('T')[1];
        this.selectedEvent.start_time = resp.data.start_time;
        this.selectedEvent.end_time = resp.data.end_time;
        this.selectedEvent.email = resp.data.email;
        this.selectedEvent.name = resp.data.name;
        this.selectedEvent.guest_email = resp.data.guest_email;
        this.selectedEvent.meeting_link = (resp.data?.booking_details[0]?.medium_type === 'worke_url' ? resp.data?.booking_details[0].staff_twilio_meeting_url : resp.data?.booking_details[0].meeting_link);
        this.selectedEvent.type = 'Meeting';
        console.log(this.selectedEvent, "event");
      });

    } else {
      const guest_email: any[] = [];
      this.selectedEvent.extendedProps.guest_email.forEach((ele: any) => {
        guest_email.push({ mail: ele.email });
      });
      this.selectedEvent.date = this.selectedEvent.extendedProps.startStr;
      this.selectedEvent.start_time = (this.selectedEvent.extendedProps.startStr.split('T')[1]).split('.')[0];
      this.selectedEvent.end_time = (this.selectedEvent.extendedProps.endStr.split('T')[1]).split('.')[0];
      this.selectedEvent.email = this.selectedEvent.extendedProps.email;
      this.selectedEvent.name = this.selectedEvent.extendedProps.name;
      this.selectedEvent.guest_email = guest_email;
      this.selectedEvent.meeting_link = this.selectedEvent.extendedProps?.meeting_link;
      this.selectedEvent.type = 'MailBox';
    }
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
  }

  handleEvents(events: any) {
    this.upcomingEvents = [];
    this.currentEvents = events;
    this.currentEvents.forEach((element: any) => {
      if (new Date() <= new Date(element.startStr)) this.upcomingEvents.push(element);
    });
    // this.upcomingEvents.reverse();
    console.log(this.currentEvents, "events");

    this.changeDetector.detectChanges();
  }

  handleDatesSet(arg: DatesSetArg) {
    const selectedDate = arg.view.currentStart;
    this.selectedType = arg.view.type;
    this.selectedDate = this.convert(selectedDate);
    this.EndDate = this.convert(arg.view.currentEnd);
    // Perform additional actions based on the selected month
    this.getCalendar();
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  hide() {
    this.showModal = false;
    this.eventModal = false;
  }

  getCalendar() {
    let type: any;
    if (this.selectedType === 'dayGridMonth') {
      type = 'month';
    } else if (this.selectedType === 'timeGridWeek') {
      type = 'week';
    } else if (this.selectedType === 'timeGridDay') {
      type = 'day';
    }
    this.authService.getAllSlotsData(type, this.selectedDate).subscribe((data: any) => {
      this.authService.getAptSlots(type, this.selectedDate, this.EndDate).subscribe((aptresp: any) => {
        this.authService.getChatbotCalenderSync(type, this.selectedDate).subscribe((connectmailResp: any) => {
          data.data.forEach((element: any) => {
            element.start = element.startStr;
            element.end = element.endStr;
            element.type = "Meeting";
          });
          aptresp.data.forEach((element: any) => {
            element.start = element.startStr;
            element.end = element.endStr;
            element.title = element.consultation_id?.name;
            element.type = "Appointment";
          });
          connectmailResp.data.forEach((element: any) => {
            element.start = element.startStr;
            element.end = element.endStr;
            element.type = "MailBox";
          });
          console.log(connectmailResp, "connectmailResp");

          this.slots_data = [...data.data, ...aptresp.data, ...connectmailResp.data];
          this.calendarOptions.events = this.slots_data;
          console.log(this.slots_data, "slots_data");

          // this.handleEvents(data.data)
          return this.slots_data;
        });
      });
    });
  }

  openIntegartionModel(modal: any) {
    this.modalService.open(modal);
  }

  connect(type: string) {
    if (type === "Gmail") {
      this.settingsService.get_Mail_connect().subscribe((resp: any) => {
        window.open(resp.data.url, "_self");
      });
    } else {
      this.settingsService.get_outlook_connect().subscribe((resp: any) => {
        window.open(resp.data.url, "_self");
      });
    }
  }

  checkConnection() {
    this.settingsService.checkCalenderConnection().subscribe((resp: any) => {
      this.calendarConnection = resp.data.isConnected;
    });
  }

  disconnectCalendar(){
    this.settingsService.calenderDisConnect().subscribe(() => {
      this.checkConnection();
    });
  }
}
