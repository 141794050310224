import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';
import { HomeService } from 'src/app/core/service/home.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/core/service/settings.service';
import { FILESJSON } from 'src/app/core/data/crm_modal_json';
import { PageEvent } from '@angular/material/paginator';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-bookedappointment-detail',
  templateUrl: './bookedappointment-detail.component.html',
  styleUrls: ['./bookedappointment-detail.component.scss']
})
export class BookedappointmentDetailComponent implements OnInit {
  @ViewChild('tp') _matTooltip!: MatTooltip;
  @ViewChild('customer') _matTooltipCustomer!: MatTooltip;
  presignedurl = environment.preSendUrl;
  appointment_status = 'pending';
  tabs = [
    {
      id: 1,
      title: 'Snooze'
    },
    {
      id: 2,
      title: 'Files'
    },
    {
      id: 3,
      title: 'Questionnaire'
    }
  ];
  files: File[] = [];
  @Input() appointmentId: any;
  appointmentdata: any;
  locationsDetails: any;
  questionnaire_list: any[] = [];
  display_no_data = false;
  filesJSON = FILESJSON[0];
  filesListArray: any = [];
  currentPage = 1;
  pageEvent!: PageEvent;
  filesTotal!: number;
  activeId = 3;
  visibility = false;
  show_loader = false;
  fileName!: string;
  isMeetingEnabled = false;
  validateUserAccess = false;
  loggedInUser: any;
  rescheduleBtnDisable = false;
  contactActive = false;
  disabledMetting = false;

  constructor(private modalService: NgbModal, private homeService: HomeService, private settingService: SettingsService, private crmService: CrmService,
    private confirmationMsz: ConfirmationMszService, private awsUtilService: AwsUtilsService, private toastrService: ToastrService, private router: Router) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getAppointmentbyId();
    this.getFiles(1);// if activeId change then this needs to be changed accordingly
  }

  getAppointmentbyId() {
    this.display_no_data = false;
    this.homeService.getAppointmentById(this.appointmentId).subscribe((resp: any) => {
      this.appointmentdata = resp.data;
      const date = new Date(this.appointmentdata.staff_timezone_date);
      const [hours, minutes] = this.appointmentdata.staff_timezone_time.split(':').map(Number);
      date.setHours(hours);
      date.setMinutes(minutes);
      if(date < new Date()) this.rescheduleBtnDisable = true;
      this.onCheckingMeetingLinkStatus();
      this.questionnaire_list = this.appointmentdata.questionnaire;
      this.getContactById(resp.data.contact_id);
      if (this.questionnaire_list.length === 0) this.display_no_data = true;
      if (this.appointmentdata.org_address_id) this.getLocationData();
    });
  }

  onCheckingMeetingLinkStatus() {
    const todayDate = this.formatDate(new Date(),'format');
    const currentTime = this.convertToMinutes(this.formatTime(new Date()));
    const appointmentDate = this.formatDate(new Date(this.appointmentdata.staff_timezone_date), 'format');
    const appointmentTime = this.appointmentdata.staff_timezone_time;
    const originalDate = new Date(`${appointmentDate}T${appointmentTime}`);
    const minutesBeforeTime = this.convertToMinutes(this.formatTime(new Date(originalDate.getTime() - (10 * 60000))));
    const meetingDurations = this.appointmentdata.consultation_id.duration;
    const minutesAfterTime = this.convertToMinutes(this.formatTime(new Date(originalDate.getTime() + (meetingDurations * 60000))));
    const minutesBeforeDate = this.formatDate(new Date(originalDate.getTime() - (10 * 60000))).split('/').reverse().join('-');
    const minutesAfterDate = this.formatDate(new Date(originalDate.getTime() + (meetingDurations * 60000))).split('/').reverse().join('-');
    if ((new Date(todayDate).getTime() > new Date(minutesAfterDate).getTime())) {
      this.disabledMetting = true;
    }
    if ((todayDate === minutesBeforeDate || todayDate === minutesAfterDate) && (currentTime >= minutesBeforeTime && currentTime <= minutesAfterTime)) {
      this.isMeetingEnabled = true;
    } else {
      this.isMeetingEnabled = false;
    }
  }

  convertToMinutes(time: any) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  }

  formatDate(date: any,type?:string) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if(type){
      return `${year}-${month}-${day}`;
    }
    return `${day}/${month}/${year}`;
  }

  formatTime(date: any) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }
  getContactById(contact_id: string) {
    this.crmService.getContactById(contact_id).subscribe((resp: any) => {
      this.appointmentdata.contact_details = resp.data[0];
      this.contactActive = resp.data[0].is_active;
      this.navigationAccess();
      console.log(this.appointmentdata, "aptData");

    });
  }

  getLocationData() {
    this.settingService.getLocationSettingsById(this.appointmentdata.org_address_id).subscribe((resp: any) => {
      this.locationsDetails = resp.data;
    });
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
  onMouseEnter() {
    this._matTooltip.message = 'Copy to clipboard';
  }
  onCustomerClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltipCustomer.show();
      this._matTooltipCustomer.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
  onCustomerMouseEnter() {
    this._matTooltipCustomer.message = 'Copy to clipboard';
  }

  reschedule() {
    const modalRef = this.modalService.open(AppointmentModalComponent, { size: 'lg' });
    modalRef.componentInstance.appointmentId = this.appointmentId;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === 'success') this.getAppointmentbyId();
    });
  }
  onSelect(event: any) {
    this.files = [...event.addedFiles];
    const media_dropzone = document.getElementById('media-dropzone');
    media_dropzone && (media_dropzone.style['height'] = 'fit-content');
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onStatusChange() {
    this.homeService.putAppointmentStatus(this.appointmentId, { appointment_status: this.appointmentdata.appointment_status }).subscribe((resp: any) => {
      this.getAppointmentbyId();
    }, (err: any) => {
      if (err.status === 400) {
        this.getAppointmentbyId();
      }
    });
  }

  getFiles(page: number) {
    this.homeService.getFiles(this.appointmentId, page).subscribe((response: any) => {
      this.filesTotal = response.total;
      this.filesListArray = response.data;
    });
  }

  deleteFile(id: any) {
    this.homeService.deleteFiles(id).subscribe((response: any) => {
      this.getFiles(1);
    });
  }

  confirmDeleteFile(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getFiles(this.currentPage);
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 3) {
      this.getFiles(1);
    }
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }

  toggleVisibility(e: any, data: any) {
    this.visibility = e.target.checked;
    this.updateFile(data, this.visibility);
  }

  onUpload() {
    if (!this.files[0]) return;
    if (this.files[0].size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }

    this.show_loader = true;
    this.awsUtilService.getUploadFileLink(this.files[0].type, this.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.fileName = this.presignedurl + resp.data.file_name;
        this.awsUtilService.uploadFile(this.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
            this.fileSubmit();
          }, error: () => {
            this.files = [];
            this.show_loader = false;
          }
        });
      }, error: () => {
        this.files = [];
        this.show_loader = false;
      }
    });
  }

  updateFile(data: any, visibility: boolean) {
    const updatePayload = {
      id: data.id,
      source_id: data.source_id,
      name: data.name,
      source_type: data.source_type,
      is_visible: visibility,
      media_url: data.media_url
    };
    this.homeService.Updatefile(updatePayload).subscribe(() => { //@typescript-eslint/no-empty-function
    });
  }

  onNaviagtetoContactInnerScreen(userId: string) {
    const queryParams: any = {
      id: userId,
      showtype: 'contact'
    };
    this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
  }

  navigationAccess() {
    if (this.appointmentdata.contact_details.assignee.includes(this.loggedInUser.data.id) || this.appointmentdata.contact_details?.owner_info?.id === this.loggedInUser.data.id || this.loggedInUser.data.role === 'ADMIN') {
      this.validateUserAccess = true;
    } else {
      this.validateUserAccess = false;
    }

    //For manager control
    //   // const departmentsList = [...this.taskDetails.assignee_department, this.taskDetails.owner_department];
    //   // this.settingsService.getStaff_infoById(this.loggedInUser.data.id).subscribe({
    //   //   next: (resp: any) => {
    //   //     this.validateUserAccess = (resp.data?.department && departmentsList.includes(resp.data?.department) && resp.data?.role_info?.name === "MANAGER") ? true : false;
    //   //   }
    // }
  }

  fileSubmit() {
    const payload = {
      source_name: "this.project.name",
      source_id: this.appointmentId,
      source_type: 'consultation',
      name: this.files[0].name,
      is_visible: this.visibility,
      media_url: this.fileName,
      related_to: [this.appointmentdata?.contact_id]
    };

    this.homeService.fileUpload(payload).subscribe({
      next: () => {
        this.show_loader = false;
        this.getFiles(this.currentPage);
        this.files = [];
      }, error: () => {
        this.show_loader = false;
        this.files = [];
      }
    });
  }
}
