export const CONTACTSJSON = [
  {
    heading_label: 'Contacts',
    Filter_label: 'Filter',
    search_placeholder: 'Search column',
    // back_label: 'Back',
    add_to_list_label: 'Add to list',
    search_contact_placeholder: 'Search',
    label_add_contact: 'Add contact',
    col_two_label: 'Company Name',
    col_three_label: 'Contact Name',
    col_four_label: 'Email',
    col_five_label: 'Phone',
    col_six_label: 'Contact Owner',
    col_seven_label: 'Last Updated',
    col_eight_label: 'Actions',
    lists_label: 'Lists',
    lists_placeholder: 'Create list',
    label_workathon: 'Workathon',
    label_syoft: 'Syoft',
    label_zepul: 'Zepul',
    label_create_new: 'Create new lists',
    label_import: 'Import Contacts',
    label_export: 'Export Contacts',
    import_btn: 'Import',
    export_btn: 'Export',
    heading_update: 'Update List',
    list_name: 'List Name',
    lable_dsp: 'Description'
  }
];

export const ADDCONTACTJSON = [
  {
    heading: 'Add Contact',
    label_first_name: 'First Name',
    label_last_name: 'Last Name',
    label_company: 'Company Name',
    label_phone: 'Phone Number',
    btn_add_phone: 'Add Phone',
    label_email: 'Email',
    btn_add_email: 'Add Email',
    label_tag: 'Tag',
    btn_add_tag: 'Add Tag',
    owner: 'Owner',
    contact_type: 'Contact Type',
    Source: 'Channel',
    customize_text: 'Customize your contact form',
    save_btn: 'Save',
    cancel_btn: 'Close',
    btn_cancel: 'Cancel'
  }
];
