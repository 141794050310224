<div class="container-fluid d-flex no-gutters details-modal px-0" [ngStyle]="{'padding-top':headerHeight+'px'}">
    <!-- <div class="row h-100 no-gutters"> -->
    <div class="col-md-3 col-lg-3 col-xl-3 pe-0 h-100">
        <app-client-info></app-client-info>
    </div>
    <div class="col-md-9 col-lg-9 col-xl-9 h-100 ps-0 pb-3 pt-3 bg-white" style="padding-right: 54px;">
        <div class="crm-client-content">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom px-3 mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1" class="me-2" *ngIf="featuresData?.project_timeline">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Timeline</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-0">
                            <app-time-line></app-time-line>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2" *ngIf="featuresData?.project_task">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Task Board</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="pt-2 pb-0">
                        <app-task-board ></app-task-board>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2" *ngIf="featuresData?.project_notes">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Notes</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="">
                        <app-notes ></app-notes>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2" *ngIf="featuresData?.project_activities">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Activity</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-2">
                        <app-activity ></app-activity>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" class="me-2" *ngIf="featuresData?.project_files">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Files</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="py-3">
                        <app-files ></app-files>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="6" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Conversations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-35rem p-3">
                            <app-client-conversations></app-client-conversations>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="7" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-16">Emails</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-35rem p-3">
                            <div class="conversations h-100 container">
                                <div class="middle-chat-section h-100">
                                    <section class="middle h-100">
                                        <div class="chat h-100 d-flex flex-column justify-content-between">
                                            <div class="messages-chat" style="height: calc(100vh - 270px);" #mailchat>
                                                <div class="">
                                                    <ng-container *ngFor="let item of email_converstion;let i = index">
                                                        <div class="message d-flex align-items-start justify-content-between cursor col-12"
                                                            (click)="onToogle(i)">
                                                            <div class="d-flex align-items-start col-11">
                                                                <div class="avatar"
                                                                    [style.background-color]="item?.colour">
                                                                    {{item?.message?.from?.charAt(0)?.toUpperCase()}}
                                                                </div>
                                                                <div class="ms-2" style="width: 94%;">
                                                                    <p class="fw-600 m-0">{{item.message.from}}</p>
                                                                    <p class="message-to to-content" [id]="'toId'+i">To:
                                                                        <span class="fw-600">{{item.message.to}}</span>
                                                                    </p>
                                                                    <p class="message-body limit-line-length-1 m-0 content-height"
                                                                        [id]="'item'+i" style="color: #706d6d;"
                                                                        [innerHTML]="item?.message?.body"></p>
                                                                </div>
                                                            </div>
                                                            <mat-icon class="col-1"
                                                                [id]="'arrow'+i">keyboard_arrow_down</mat-icon>
                                                        </div>
                                                        <hr *ngIf="i !== email_converstion.length - 1">
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end mt-2">
                                                <button class="btn ms-2 btn-primary" type="button"
                                                    (click)="openSendMailTemplate('sent')">Send a
                                                    Mail</button>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="7">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Products</span>
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li> -->
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <!-- </div> -->
</div>