import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/service/auth.service';
import { environment } from 'src/environments/environment';
import { SetUpService } from './core/service/set-up.service';
import { InternetService } from './core/service/internet.service';
import { PushNotificationsService } from './core/service/push-notifications.service';
import { SubscriptionPlansComponent } from './pages/settings/subscription-plans/subscription-plans.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'worke_dashboard';
  currentUser: any;
  authUser: any;
  isOnline$: any;
  private debounceTimeout: any;
  private readonly DEBOUNCE_DELAY = 10000;
  isMouseMoving = true;
  plan_error = true;
  @HostListener('document:mousemove', ['$event'])

  onMouseMove(event: MouseEvent): void {
    clearTimeout(this.debounceTimeout);
    if (!sessionStorage.getItem('currentUser')) return;
    if (!this.isMouseMoving) {
      this.isMouseMoving = true;
      console.log('Mouse movement started');
      this.authService.putActiveStatus(this.authUser.data.id, { active_status: true }).subscribe();
    }
    this.debounceTimeout = setTimeout(() => {
      if (this.authUser) {
        console.log('Mouse movement stopped');
        this.isMouseMoving = false;
        this.authService.putActiveStatus(this.authUser.data.id, { active_status: false }).subscribe();
      }
    }, this.DEBOUNCE_DELAY);
  }

  constructor(private router: Router, private authService: AuthService, private setupService: SetUpService,
    private internetService: InternetService, private pushService: PushNotificationsService, public modelService: NgbModal) { }

  ngOnInit() {
    this.isOnline$ = this.internetService.isOnline$;
    console.log(this.isOnline$);

    if (localStorage.getItem('currentUser')) {
      sessionStorage.setItem('currentUser', localStorage.getItem('currentUser')!);
    }
    if (sessionStorage.getItem('currentUser')) {
      this.authUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      if (this.authUser.data?.subscription_plan_type === 'free') {
        this.plan_error = false;
        this.authService.getCurrentPlanDays().subscribe((resp: any) => {
          if (resp.data.remaining_days === 0 || !resp.data.subscription_plan) {
            this.modelService.open(SubscriptionPlansComponent, { size: 'xl', backdrop: 'static', keyboard: false, modalDialogClass: 'billing_modal', scrollable: true });
          } else {
            this.authService.getData();
            this.setupService.getSetupInfo();
            this.authService.authUserSubscription$.subscribe((userInfo: any) => {
              this.authUser = userInfo;
            });
          }
        }, () => {
          // this.modelService.open(SubscriptionPlansComponent, { size: 'xl', backdrop: 'static', modalDialogClass: 'billing_modal', scrollable: true });
          this.plan_error = true;
        });
      } else {
        this.authService.getData();
        this.setupService.getSetupInfo();
      }
      // if (Notification.permission === "default") {
      //   this.pushService.modal();
      // }
    }
    // const data = {};
    // parent.postMessage(data, environment.baseUrl);
  }
}
