import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { countryData } from 'src/app/core/data/countryData';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ClientModalComponent } from 'src/app/pages/shared/client-modal/client-modal.component';
import { ClientProjectModalComponent } from 'src/app/pages/shared/client-project-modal/client-project-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {
  panelOpenState = false;
  selected_id!: string;
  project: any;
  client: any;
  staffMembers: any = [];
  productsArray: any = [];
  client_seemore = true;
  clientproject_seemore = true;
  client_owner: any;
  project_owner: any;
  statusList: any = [
    {
      id: 1,
      name: "PENDING"
    },
    {
      id: 2,
      name: "COMPLETED"
    }
  ];
  allStaffMembers: any = [];
  selected_type: any = "";
  countryjson = countryData;
  tagsListArray: any;
  custom_field: any[] = [];
  slice_additional_Item = 5;
  slice_additional_contact_Item = 5;
  addmore = false;
  seemoreContacts = false;
  client_custom_field: any[] = [];
  presignedurl = environment.preSendUrl;
  appointment_category: any;
  appointment: any;
  product: any;
  services_list: any;
  product_category: any;
  service_category: any;
  isVisibleDetailsBox = true;
  detailsBox: {
    detailsBoxTitle: string,
    detailBoxBody: string,
    isTitleCase: boolean
  } = {
      detailsBoxTitle: '',
      detailBoxBody: '',
      isTitleCase: false
    };
  project_data: any;

  constructor(private route: ActivatedRoute, private service: ClientsService, private product_service: SetUpService, private settingsService: SettingsService,
    private modalService: NgbModal, private customfieldService: CustomFieldsService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getRelatedIdInfo(this.selected_id);
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    console.log(event.target?.offsetParent?.classList.contains('details-box'), this.isVisibleDetailsBox);
    if (!event.target?.offsetParent?.classList.contains('details-box') && !this.isVisibleDetailsBox) {
      this.onCloseDetailsBox();
    }
  }

  seeMore(type: string) {
    if (type === 'client') {
      this.client_seemore = !this.client_seemore;
    } else if (type === 'project') {
      this.clientproject_seemore = !this.clientproject_seemore;
    }
  }

  onOpenDetailBox(event: any, data: any) {
    setTimeout(() => {
      this.isVisibleDetailsBox = false;
      console.log(event);
      this.detailsBox.detailsBoxTitle = data?.title ? data.title : '';
      this.detailsBox.detailBoxBody = data?.value ? data.value : '';
      this.detailsBox.isTitleCase = data?.isTitleCase ? data.isTitleCase : false;
      const left = event.srcElement?.offsetLeft + event.srcElement?.clientWidth + 40;
      const top = event.clientY - 60;
      const detailsBox = document.querySelector('.details-box');
      this.renderer.setStyle(detailsBox, 'display', 'block');

      setTimeout(() => {
        if (window.innerHeight < (event.clientY + detailsBox?.clientHeight)) {
          detailsBox && this.renderer.setStyle(detailsBox, 'top', top - detailsBox?.clientHeight + 30 + 'px');
        } else {
          detailsBox && this.renderer.setStyle(detailsBox, 'top', top + 'px');
        }
        detailsBox && this.renderer.setStyle(detailsBox, 'left', left + 'px');
      }, 10);

      setTimeout(() => {
        this.renderer.setStyle(detailsBox, 'opacity', '1');
      }, 300);
    }, 0);
  }

  onScroll() {
    this.isVisibleDetailsBox = false;
    this.onCloseDetailsBox();
  }

  onCloseDetailsBox() {
    if (!this.isVisibleDetailsBox) {
      const detailsBox = document.querySelector('.details-box');
      this.renderer.setStyle(detailsBox, 'opacity', '0');
      setTimeout(() => {
        this.renderer.setStyle(detailsBox, 'display', 'none');
        this.isVisibleDetailsBox = true;
      }, 100);
    }
  }

  getRelatedIdInfo(id: string) {
    this.organisationMembersList();
    this.getTags();
    this.staffMembers = [];

    this.service.getProjectDetailsById(id).subscribe({
      next: async (proj_resp: any) => {
        console.log("project - ", proj_resp);
        this.customfieldService.getClientProjectModelFields().subscribe(async (projectfields: any) => {
          const modelfields = projectfields.data;
          this.project_data = proj_resp.data;
          const systemFields = Object.entries(proj_resp.data);
          const fields_element: any = {};
          fields_element['custom_project_fields'] = proj_resp.data.custom_project_fields;
          Object.entries(projectfields.data).map((field: any) => {
            if (field[0] !== "project_fields_order" && field[0] !== 'custom_project_fields' && field[0] !== 'custom_project_templates' && field[0] !== "id" && field[0] !== "organisation"){
              if (field[1].on_form || field[1].is_inner_screen) fields_element[field[0]] = proj_resp.data[field[0]];
            } else if (field[0] === 'custom_project_templates') {
              field[1].forEach((element: any) => {
                if (element.on_form || element.is_inner_screen) {
                  const customFieldIndex = proj_resp.data.custom_project_fields.findIndex((item: any) => item.custom_project_template === element.id);
                  const custom_field_includes = customFieldIndex !== -1;
                  if (!custom_field_includes) { //new field is creating
                    const body = {
                      "id": "",
                      "label": element.label,
                      "data_type": element.data_type,
                      "options": element.options,
                      "on_form": element.on_form,
                      "is_inner_screen": element.is_inner_screen,
                      "data": "",
                      "is_mandatory": element.is_mandatory,
                      "is_active": element.is_active,
                      "custom_project_template": element.id,
                      "project": id,
                      "start": "",
                      "end": ""
                    };
                    fields_element['custom_project_fields'].push(body);
                    field[1].push(body);
                  } else { // exist project fields adding options to the element
                    field[1][customFieldIndex].options = element.options;
                    fields_element['custom_project_fields'][customFieldIndex].options = element.options;
                  }
                }
              });
            }
          });
          const promises = systemFields.map(async (field: any) => {
            // if (field[1] !== null) {
            if (field[0] === 'appointment_category' && (modelfields[field[0]].on_form || modelfields[field[0]].is_inner_screen)) {
              const results: any = await this.product_service.getAllCategories().toPromise();
              const selectedItem: any[] = [], dropdownItems: any[] = [];
              results.data.filter((item: any) => {
                field[1].forEach((element: any) => {
                  if (item.id === element) {
                    selectedItem.push({ id: item.id, name: item.name });
                  }
                });
                dropdownItems.push({ id: item.id, name: item.name });
              });
              this.appointment_category = dropdownItems;
              if (selectedItem.length !== 0) field[1] = selectedItem; fields_element[field[0]] = selectedItem;

            } else if (field[0] === "appointment" && (modelfields[field[0]].on_form || modelfields[field[0]].is_inner_screen)) {
              const results: any = await this.product_service.getAllConsulations().toPromise();
              // field[1] = results.data.name;
              const selectedItem: any[] = [], dropdownItems: any[] = [];
              results.data.filter((item: any) => {
                field[1].forEach((element: any) => {
                  if (item.id === element) {
                    selectedItem.push({ id: item.id, name: item.name });
                  }
                });
                dropdownItems.push({ id: item.id, name: item.name });
              });
              this.appointment = dropdownItems;
              if (selectedItem.length !== 0) field[1] = selectedItem; fields_element[field[0]] = selectedItem;
            } else if ((field[0] === "product" || field[0] === "product_category" || field[0] === "service" || field[0] === "service_category") && (modelfields[field[0]].on_form || modelfields[field[0]].is_inner_screen)) {
              const store_resp: any = await this.product_service.getStore().toPromise();
              if (field[0] === "product" || field[0] === "service") {
                const results: any = await this.product_service.getDropDownProducts(store_resp.data.id, field[0]).toPromise();
                // field[1] = results.data.name;
                const selectedItem: any[] = [], dropdownItems: any[] = [];
                results.data.filter((item: any) => {
                  field[1].forEach((element: any) => {
                    if (item.id === element) {
                      selectedItem.push({ id: item.id, name: item.name });
                    }
                  });
                  dropdownItems.push({ id: item.id, name: item.name });
                });
                if (field[0] === "product") this.product = dropdownItems;
                else this.services_list = dropdownItems;
                if (selectedItem.length !== 0) field[1] = selectedItem; fields_element[field[0]] = selectedItem;
              } else {
                const results: any = await this.product_service.getProductCategories(store_resp.data.id, field[0]).toPromise();
                // const selectedItem = results.data.filter((item: any) => item.id === field[1]);
                // if (selectedItem.length !== 0) field[1] = selectedItem[0].name;
                const selectedItem: any[] = [], dropdownItems: any[] = [];
                results.data.filter((item: any) => {
                  field[1].forEach((element: any) => {
                    if (item.id === element) {
                      selectedItem.push({ id: item.id, name: item.name });
                    }
                  });
                  dropdownItems.push({ id: item.id, name: item.name });
                });
                if (field[0] === "product_category") this.product_category = dropdownItems;
                else this.service_category = dropdownItems;
                if (selectedItem.length !== 0) field[1] = selectedItem; fields_element[field[0]] = selectedItem;
              }
            }
            // }
          });
          await Promise.all(promises);
          fields_element.project_id = proj_resp.data.project_id;
          fields_element.id = proj_resp.data.id;
          fields_element.contact_id = proj_resp.data.contact_id;
          fields_element.client_id = proj_resp.data.client_id;
          fields_element.created_at = proj_resp.data.created_at;
          fields_element.updated_at = proj_resp.data.updated_at;
          fields_element.updated_by_name = proj_resp.data.updated_by_name;
          fields_element.created_by_name = proj_resp.data.created_by_name;
          this.project = fields_element;

          this.custom_field = proj_resp.data.custom_project_fields;
          this.getStaffDataById(this.project.owner, 'project');
          if (this.project.tags) this.getTagData(this.project.tags, 'project');
          this.service.getClientById(this.project.client_id).subscribe({
            next: (client_resp: any) => {
              // console.log("client - ", client_resp);
              this.customfieldService.getClientModelFields().subscribe(async (clientfields: any) => {
                this.client = client_resp.data;
                this.client_custom_field = client_resp.data.custom_client_fields;
                this.getStaffDataById(this.client.owner, 'client');
                if (this.client.tags) this.getTagData(this.client.tags, 'client');
                if (clientfields.data.custom_client_templates.length > 0) {
                  clientfields.data.custom_client_templates.forEach((element: any) => {
                    if (element.on_form || element.is_inner_screen) {
                      const customFieldIndex = client_resp.data.custom_client_fields.findIndex((item: any) => item.custom_client_template === element.id);
                      const custom_field_includes = customFieldIndex !== -1;
                      if (!custom_field_includes) { //new field is creating
                        const body = {
                          "id": "",
                          "label": element.label,
                          "data_type": element.data_type,
                          "options": element.options,
                          "on_form": element.on_form,
                          "is_inner_screen": element.is_inner_screen,
                          "data": "",
                          "is_mandatory": element.is_mandatory,
                          "is_active": element.is_active,
                          "custom_client_template": element.id,
                          "client": this.project.client_id,
                          "start": "",
                          "end": ""
                        };
                        this.client_custom_field.push(body);
                      } else { // exist project fields adding options to the element
                        this.client_custom_field[customFieldIndex].options = element.options;
                      }
                    }
                  });
                }
              });
            }
          });

          if (this.project.assignee.length !== 0) {
            this.project.assignee.forEach((staff: any) => {
              this.settingsService.getStaff_infoById(staff).subscribe({
                next: (resp: any) => {
                  this.staffMembers.push(resp.data);
                }
              });
            });
          }

          // if (this.project?.service_ids.length !== 0) { //Not using any more
          //   this.productsArray = [];
          //   this.project.service_ids.forEach((product: any) => {
          //     this.getProductById(product);
          //   });
          // }
        });
      }
    });
  }

  getTagData(id: string, type: string) {
    this.settingsService.getClientTagById(id).subscribe({
      next: (resp: any) => {
        if (type === 'client') {
          this.client.tag_name = resp.data[0].name;
          this.client.colour = resp.data[0].colour;
        } else if (type === 'project') {
          this.project.tag_name = resp.data[0].name;
          this.project.colour = resp.data[0].colour;
        }
      }
    });
  }

  getProductById(product_id: string) {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getProductById(resp.data.id, product_id).subscribe({
          next: (products_resp: any) => {
            // console.log(products_resp);
            this.productsArray.push(products_resp.data);
            this.project.product_details = this.productsArray;
            // this.products_list = products_resp.data;
            console.log(this.project);

          }
        });
      }
    });
  }

  getStaffDataById(id: string, type: string) {
    this.settingsService.getStaff_infoById(id).subscribe({
      next: (resp: any) => {
        if (type === 'client') {
          this.client_owner = resp.data;
        } else if (type === 'project') {
          this.project_owner = resp.data;
        }
      }
    });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.drag_elements, event.previousIndex, event.currentIndex);
  // }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.allStaffMembers = resp?.data;
      }
    });
  }

  getTags() {
    this.settingsService.getClientTags('projects').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  onUpdatePopOver(event: any, data: any, type?: string, fieldType?: string, customData?: any) {
    const input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      }
      if (event?.ccode) {
        input['ccode'] = event.cCode;
      }

      if (event.labelName === 'Tag') {
        input[event.formName] = event.value.id;
      } else if (event.labelName === "Status") {
        input.project_status = event.value.name;
      } else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      } else if (event.labelName === "appointment" || event.labelName === 'appointment_category' || event.labelName === 'product_category' || event.labelName === 'product' || event.labelName === "service" || event.labelName === "service_category") {
        input[event.formName] = [event.value.id];
      }

    } else if (event.type === 'multiselect') {
      input[event.formName] = event.value;
      input.assignee_departments = [];
      this.allStaffMembers.forEach((element: any) => {
        if (event.value.includes(element.id)) {
          input.assignee_departments.push(element.department_info?.id);
        }
      });
    } else
      input[event.formName] = event.value;
    let custom_fields = {};
    if (fieldType === 'custom_field') {
      if (!customData.id) {
        customData.data = event.value;
        custom_fields = {
          id: input.id,
          [`custom_${type}_fields`]: [customData]
        };
      } else {
        custom_fields = {
          id: input.id,
          [`custom_${type}_fields`]: [{
            id: event.formName,
            data: event.value,
            data_type: event.type
          }]
        };
      }
    }
    if (type === 'client')
      this.service.updateClient(input.id, fieldType === 'custom_field' ? custom_fields : input).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    else
      this.service.updateClientProject(input.id, fieldType === 'custom_field' ? custom_fields : input).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });

  }

  seeMoreAdditionalFields(type: string) {
    if (type === 'project') {
      this.addmore = true;
      this.slice_additional_Item = this.custom_field.length;
    } else {
      this.seemoreContacts = true;
      this.slice_additional_contact_Item = this.client_custom_field.length;
    }
  }

  openProjectModalDialog(data: any) {
    const modalRef = this.modalService.open(ClientProjectModalComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.clientProjectData = this.project_data;
  }

  openClientModalDialog(data: any) {
    const modalRef = this.modalService.open(ClientModalComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.clientData = data;
  }

  isObject(value: any): boolean {
    return (typeof value === 'object' && value.length !== 0);
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }
}
