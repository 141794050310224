import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/core/service/crm.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-imported-files',
  templateUrl: './imported-files.component.html',
  styleUrls: ['./imported-files.component.scss']
})
export class ImportedFilesComponent {
  field_data: any[] = [];
  params:any;

  constructor(private service: CrmService,private activatedRoute:ActivatedRoute,private _location: Location) {
    this.activatedRoute.queryParams.subscribe((params:any) => {
      this.params = params?.type;
    });
    this.getFiles();
  }

  getFiles() {
    this.service.getCSVFiles(this.params).subscribe((resp: any) => {
      this.field_data = resp.data;
    });
  }

  onBack(){
    this._location.back();
  }
}
