import { Component } from '@angular/core';
import { APPOINTMENTJSON } from 'src/app/core/data/crm_modal_json';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  appointmentJson = APPOINTMENTJSON[0];
  AptListArray = [{
    name: 'Appointment name',
    client_name: 'Dileep',
    email: 'dileep@syoft.com',
    number: '989898989',
    amount: '10000',
    due_date: new Date()
  }];
}
