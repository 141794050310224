import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ACTIVITYJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivityMarkedModelComponent } from 'src/app/pages/shared/activity-marked-model/activity-marked-model.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { customDateTimeConversion, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { debounceTime } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/service/auth.service';
@Component({
  selector: 'app-activity-crm',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  @Input() isModal!: boolean;
  @Output() closeModalEvent = new EventEmitter<string>();
  activityJSON = ACTIVITYJSON[0];
  errorjson = ERRORJSON[0];
  selected_id!: string;
  selected_type!: string;
  activity_show = false;
  currentActivityIndex = -1;
  activity_type: any;
  associated_dropdown = 'All';
  submitedActivityForm = false;
  activity_form!: FormGroup;
  activity_filter = 'all';
  activityList_filter = 'all';
  activitiesArray: any = [];
  staffMembers: any = [];
  info: any = {};
  loggedInUser: any;
  guestsMeetingList: any = [];
  related_to: any = [];
  minDate = new Date();
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  activities_total_count!: number;
  pageEvent!: PageEvent;
  currentPage = 0;
  allActivityLists: Array<any> = [];
  currentActiveActivity: any;
  modalRef: any;
  selectedContactDetails: any;
  activityId = '';
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  featuresData: any;
  activity_related_types: any[] = [];
  activityTypes: any[] = [{
    label: 'Contact',
    value: 'contact',
    feature_key: ['contact', 'contact_activities']
  },
  {
    label: 'Lead',
    value: 'lead',
    feature_key: ['enquiry', 'enquiry_activities']
  },
  {
    label: 'Company',
    value: 'company',
    feature_key: ['company', 'company_activities']
  },
  {
    label: 'Client Project',
    value: 'project',
    feature_key: ['projects', 'project_activities']
  }];
  readOnly = false;

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private location: Location,
    private service: CommonService, private crm_service: CrmService, private client_service: ClientsService,
    private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService,
    private renderer: Renderer2, private toastAlert: ToastrService, private authService: AuthService) { }

  ngOnInit() {
    this.getFeatues();
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.readOnly = params.contact_active === 'false' ? true : false;
      this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      this.initlizeActivityForm();
      this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
      this.organisationMembersList();
      if (this.selected_id && !this.isModal) {
        // console.log("type - ", this.selected_type);
        const activityId = new URLSearchParams(window.location.search).get('activityId');
        if (params?.activityId && activityId) {
          this.activityId = params.activityId;
          this.getSelectedActivitesById(this.activityId);
        } else {
          this.getAllActivites(this.selected_id, 1);
        }
        this.activity_form.patchValue({ source_id: this.selected_id });
        if (this.selected_type === 'lead') {
          this.crm_service.getLeadById(this.selected_id).subscribe({
            next: (resp: any) => {
              if (resp.data[0].type === 'PIPELINE') this.readOnly = true;
            }
          });
        }
      }
    });
    this.getAllActivity();
    if (this.isModal) {
      this.organisationMembersList();
      this.activity_show = true;
      this.activity_form.patchValue({ type: this.activity_type });
      this.activity_form.controls['related_to_type'].setValidators(Validators.required);
      this.searchTrigger();
    }
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };

    // console.log(this.activity_form.get('search'), this.activity_form.get('search')?.valueChanges);
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  getContactsList() {
    this.crm_service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }
  getCompaniesList() {
    this.crm_service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }
  getEnquiryList() {
    this.crm_service.getAllLeads(1, 'ACTIVE').subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }
  getProjectList() {
    this.crm_service.getProjectDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  clearFilter() {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace(/[?&]activityId=[^&]+/, '').replace(/[?&]activeId=[^&]+/, '');
    this.location.replaceState(updatedUrl);
    this.activityId = '';
    this.getAllActivites(this.selected_id, 1);
  }

  getAllActivites(id: string, page: number) {
    this.service.getActivitysById(id, page).subscribe({
      next: (resp: any) => {
        // console.log("get activities - ", resp);
        this.activities_total_count = resp.count;
        this.activitiesArray = resp.data;
        this.activitiesArray.forEach((element: any) => {
          const timeString = element?.created_at;
          element['customTime'] = customDateTimeConversion(new Date(timeString).getTime());
        });
      }
    });
  }
  getSelectedActivitesById(id: string) {
    this.service.getSelectedActivitesById(id).subscribe({
      next: (res: any) => {
        this.activitiesArray = [res?.data];
        this.activitiesArray.forEach((element: any) => {
          const timeString = element?.created_at;
          element['customTime'] = customDateTimeConversion(new Date(timeString).getTime());
        });
        this.activities_total_count = res.count;
      },
      error: (err) => {
        if (err && err?.data.length === 0) {
          console.log(err);
          this.clearFilter();
        }
      }
    });
  }
  getAllActivity() {
    this.settingsService.getAllActivities().subscribe((response: any) => {
      this.allActivityLists = response.data;
      if (this.allActivityLists && this.allActivityLists.length > 0) {
        const index = this.allActivityLists.findIndex((elem: any) => elem.status);
        if (index > -1) {
          this.activity_type = this.allActivityLists[index].name.split(" ").join("").toLowerCase();
          this.currentActiveActivity = this.allActivityLists[index];
        } else {
          this.currentActiveActivity = 'no-res';
        }
      }
    });
  }

  createActivity(body: any, source_type: string, formDirective: any) {
    // console.log(this.activity_form);
    let department: any;
    for (const item of this.staffMembers) {
      if (item.id === body.owner) {
        this.info['owner_info'] = {
          fname: item.fname,
          lname: item.lname
        };
        department = item.department_info.id;
      }
    }
    this.service.createActivity({ ...body, source_type: source_type, info: this.info, department: department }).subscribe({
      next: () => {
        this.activity_show = false;
        this.closeModalEvent.emit('close');
        this.guestsMeetingList = [];
        this.activityId ? this.clearFilter() : this.getAllActivites(this.selected_id, 1);
        formDirective.resetForm();
        this.activity_form.reset();
        this.submitedActivityForm = false;
        this.initlizeActivityForm();
        this.patchDefaultValues();
      }
    });
  }

  currentActivity(content: any, formDirective: FormGroupDirective, index: number) {
    this.submitedActivityForm = false;
    formDirective.resetForm();
    this.activity_form.reset();
    if (this.currentActiveActivity?.id === content?.id) {
      this.activity_show = !this.activity_show;
      this.activity_show ? (this.currentActivityIndex = index) : (this.currentActivityIndex = -1);
    } else {
      this.activity_show = true;
      this.currentActivityIndex = index;
    }
    this.currentActiveActivity = content;
    this.selectedContactDetails = null;
    // this.initlizeActivityForm();
    this.activity_form.patchValue({ type: this.currentActiveActivity.id });
    this.activity_type = content.name.split(" ").join("").toLowerCase();
    this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
    this.activity_form.patchValue({ source_type: this.selected_type });
    if (content.name === 'Meeting') {
      this.activity_form.get('location')?.addValidators([Validators.required]);
      this.activity_form.get('location')?.updateValueAndValidity();
      this.activity_form.get('meeting_link')?.addValidators([Validators.required]);
      this.activity_form.get('meeting_link')?.updateValueAndValidity();

    } else {
      this.activity_form.get('location')?.clearValidators();
      this.activity_form.get('location')?.markAsUntouched();
      this.activity_form.get('location')?.updateValueAndValidity();
      this.activity_form.get('meeting_link')?.clearValidators();
      this.activity_form.get('meeting_link')?.markAsUntouched();
      this.activity_form.get('meeting_link')?.updateValueAndValidity();
    }

    if (this.isModal) {
      this.searchTrigger();
    }
    if (!this.isModal) {
      this.patchDefaultValues();
    }
  }

  searchTrigger() {
    this.activity_form.get('search')!.valueChanges.pipe(debounceTime(300)).subscribe((change: any) => {
      if (this.selected_type === 'lead' && this.isModal === true) {
        this.searchLeads(change);
      } else if (this.selected_type === 'contact' && this.isModal === true) {
        this.searchContact(change);
      } else if (this.selected_type === 'company' && this.isModal === true) {
        this.searchCompanies(change);
      } else if (this.selected_type === 'project' && this.isModal === true) {
        this.searchProjects(change);
      }
      // }
    });
  }

  initlizeActivityForm() {
    this.activity_form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      due_date: new FormControl(''),
      source_id: new FormControl('', Validators.required),
      owner: new FormControl(this.loggedInUser.data.id, Validators.required),
      source_type: new FormControl({value:this.selected_type , disabled:!this.isModal ? true : false}, Validators.required),
      type: new FormControl('', Validators.required),
      location: new FormControl(''),
      meeting_link: new FormControl(''),
      guest: new FormArray([]),
      description: new FormControl(''),
      search: new FormControl(''),
      related_to_type: new FormControl('')
    });
  }

  get guestList() {
    return this.activity_form.get('guest') as FormArray;
  }

  addMailsToTheList(mail: any) {
    this.guestList.push(new FormControl(mail));
  }

  addnew = (option: any) => ({ mail: option });

  onSelectEmail(e: any) {
    // console.log("event", e);
    this.guestsMeetingList = e;
  }

  get f() {
    return this.activity_form.controls;
  }

  submitActivityForm(formDirective: FormGroupDirective) {
    const newdata = this.activity_form;
    console.log(newdata);
    this.submitedActivityForm = true;
    if (this.activity_form.valid) {
      if (this.guestsMeetingList) {
        this.guestsMeetingList.map((data: any) => {
          this.addMailsToTheList(data.mail);
        });
      }
      this.createActivity(this.activity_form.value, this.activity_form.value.source_type ? this.activity_form.value.source_type : this.activity_form.controls["source_type"].value, formDirective);
    }
  }

  updateActivity(id: any, status: boolean) {
    const checkbox: any = document.getElementById('mark_as_read');
    checkbox.checked = true;
    this.modalRef = this.modalService.open(ActivityMarkedModelComponent, { size: 'md', centered: true });
    this.modalRef.componentInstance.data = { id, status };
    if (this.modalRef) {
      this.modalRef.result.then((data: any) => {
        data && this.activityId ? this.getSelectedActivitesById(this.activityId) : this.getAllActivites(this.selected_id, 1);
      }).catch(() => {
        checkbox.checked = false;
      });
    }
    // this.modalRef.closed.subscribe((data: any) => {
    //   console.log('vnbdshvdsghv dghvfdv', data);
    //   data && this.activityId ? this.getSelectedActivitesById(this.activityId) : this.getAllActivites(this.selected_id, 1);
    //   if (!data) {
    //     checkbox.checked = false;
    //   }
    // });
  }

  deleteActivity(id: string) {
    this.service.deleteActivty(id).subscribe(() => {
      this.activityId ? this.clearFilter() : this.getAllActivites(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteActivity(fileId);
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  searchLeads(name: string) {
    if (name?.length >= 3) {
      this.crm_service.getLeadsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
        }
      });
    } else if (name?.length === 1) {
      this.getEnquiryList();
    }
  }

  searchContact(name: string) {
    if (name?.length >= 3) {
      this.crm_service.getContactsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
        }
      });
    } else if (name?.length === 1) {
      this.getContactsList();
    }

  }

  searchCompanies(name: string) {
    if (name?.length >= 3) {
      this.crm_service.getCompaniesBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
        }
      });
    } else if (name?.length === 1) {
      this.getCompaniesList();
    }
  }

  searchProjects(name: string) {
    if (name?.length >= 3) {
      this.client_service.getAllProjectsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
        }
      });
    } else if (name?.length === 1) {
      this.getProjectList();
    }
  }

  onRelatedTochange(ev: any) {
    this.selectedContactDetails = null;
    switch (ev.value) {
      case 'lead':
        this.getEnquiryList();
        break;
      case 'contact':
        this.getContactsList();
        break;
      case "company":
        this.getCompaniesList();
        break;
      case "project":
        this.getProjectList();
        break;
      default:
        break;
    }
    this.selected_type = ev.value;
    this.activity_form.patchValue({ source_type: this.selected_type });
    this.related_to = [];
    // this.activity_form.controls['search'].setValue('');

  }

  patchDefaultValues() {
    // console.log(this.loggedInUser, "LOG user");

    if (this.selected_type === 'contact') {
      this.activity_form.patchValue({ source_id: this.selected_id });

    } else if (this.selected_type === 'company') {
      this.activity_form.patchValue({ source_id: this.selected_id });

    } else if (this.selected_type === 'lead') {
      this.activity_form.patchValue({ source_id: this.selected_id });
    }
  }

  selectedSource(contact?: any) {
    if (contact) {
      this.selectedContactDetails = contact;
    }
    this.activity_form.patchValue({ type: this.currentActiveActivity.id });
  }

  onSelectMode(event: any) {
    const meetingLink = document.querySelector('.meeting-link');
    if (event.value !== 'custom') {
      if (event.value === "google") {
        this.getGoogle();
      } else {
        this.activity_form.get('meeting_link')?.clearValidators();
        this.activity_form.get('meeting_link')?.markAsUntouched();
        this.activity_form.get('meeting_link')?.updateValueAndValidity();
        if (meetingLink) {
          this.renderer.setStyle(meetingLink, 'opacity', '0');
          setTimeout(() => {
            this.renderer.setStyle(meetingLink, 'display', 'none');
          }, 400);
        }
      }
    } else {
      this.renderer.setStyle(meetingLink, 'display', 'block');
      this.renderer.setStyle(meetingLink, 'opacity', '1');
      this.activity_form.get('meeting_link')?.addValidators([Validators.required]);
      this.activity_form.get('meeting_link')?.updateValueAndValidity();
    }
  }
  onOwnerchange(){
    if (this.activity_form.controls['location'].value === 'google'){
      this.getGoogle();
    }
    // if(this.activity_form.value.owner === this.loggedInUser.data.id) this.getGoogle();
    // else {
    //   this.activity_form.patchValue({ location: 'custom' });
    //   this.onSelectMode({ value: 'custom' });
    // }
  }
  getGoogle() {
    this.settingsService.staffConnected(this.activity_form.value.owner).subscribe((resp: any)=>{
      if (resp.data && resp.data?.isConnected) {
        const meetingLink = document.querySelector('.meeting-link');
        this.activity_form.get('meeting_link')?.clearValidators();
        this.activity_form.get('meeting_link')?.markAsUntouched();
        this.activity_form.get('meeting_link')?.updateValueAndValidity();
        if (meetingLink) {
          this.renderer.setStyle(meetingLink, 'opacity', '0');
          setTimeout(() => {
            this.renderer.setStyle(meetingLink, 'display', 'none');
          }, 400);
        }
      } else {
        this.toastAlert.error("Staff not connected to Gmail");
        this.activity_form.patchValue({ location: 'custom' });
        this.onSelectMode({ value: 'custom' });
      }
    });

    // this.settingsService.getGoogle('calendar').subscribe({
    //   next: (resp: any) => {
    //     if (resp.data && resp.data[0]?.is_connected) {
    //       const meetingLink = document.querySelector('.meeting-link');
    //       this.activity_form.get('meeting_link')?.clearValidators();
    //       this.activity_form.get('meeting_link')?.markAsUntouched();
    //       this.activity_form.get('meeting_link')?.updateValueAndValidity();
    //       if (meetingLink) {
    //         this.renderer.setStyle(meetingLink, 'opacity', '0');
    //         setTimeout(() => {
    //           this.renderer.setStyle(meetingLink, 'display', 'none');
    //         }, 400);
    //       }
    //     } else {
    //       this.toastAlert.error("Staff not connected to Gmail");
    //       this.activity_form.patchValue({ location: 'custom' });
    //       this.onSelectMode({ value: 'custom' });
    //     }
    //   },
    //   error: (err: any) => {
    //     console.log(err);
    //     this.activity_form.patchValue({ location: 'custom' });
    //     this.onSelectMode({ value: 'custom' });
    //   }
    // });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllActivites(this.selected_id, this.currentPage);
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.activityTypes.forEach((item: any) => {
        const features = item.feature_key.filter((elem: any) => !this.featuresData[elem]);
        console.log(features);
        if (features && features.length === 0) {
          this.activity_related_types.push(item);
        }
      });
    });
  }
}
