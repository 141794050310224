import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
  loggedInUser: any;
  currentPlan = '';
  manage_plans: any[] = [];

  constructor(private settingService: SettingsService,private modalService: NgbModal) { }
  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getCurrentPlan();
    this.getListOfPlans();
  }

  getListOfPlans() {
    this.settingService.getListOfPlans().subscribe((resp: any) => this.manage_plans = resp.data);
  }

  getCurrentPlan() {
    this.settingService.getCurrentPlanOfOrg().subscribe((resp: any) => {
      this.currentPlan = resp.data.subscription_plan?.id;
    });
  }

  subscriptionSubmit(selectedPlanId: string, type: string) {
    this.settingService.checkOutPayment(selectedPlanId, type).subscribe((resp: any) => {
      if (type === 'free') {
        this.getListOfPlans();
        this.getCurrentPlan();
        this.modalService.dismissAll();
      } else {
        window.open(resp.data.url, "_self");
      }
    });
  }

}
