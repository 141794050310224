<div class="container-fluid">
    <div class="h-100 overflow-auto p-2">
        <div class="d-flex align-items-center">
            <mat-icon class="text-light d-inline-flex align-items-center font-size-16 cursor"
                (click)="onBack()" matTooltip="Back">arrow_back_ios
            </mat-icon>
            <h5 class="fw-500 font-size-22 text-primary my-2">Imported Files </h5>
        </div>
        <div class="table-responsive table-height fixed-table">
            <table class="table align-middle table-nowrap table-hover mb-0 w-100 mt-2">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            S.NO
                        </th>
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            Uploaded File
                        </th>
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            Status
                        </th>
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            Uploaded at
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor" *ngFor="let item of field_data; let i = index">
                        <td>{{i + 1}}</td>
                        <td>
                            <a href="{{item?.file}}" target="_blank">{{item?.file_name}}</a>
                        </td>
                        <td>
                            <div *ngIf="item?.file_status === 'PROCESSING'">
                                <p style="background-color: #FCEED8;color: #73321B;"
                                    class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                                        [style]="{'background-color': '#73321B'}"></span>
                                    <span class="limit-line-length-1 word-break"> Processing...</span>
                                </p>
                            </div>
                            <div *ngIf="item?.file_status === 'FAIL'">
                                <p class="text-name m-0 bg-high d-inline-flex align-items-center px-2 border-radius-50">
                                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                                        [style]="{'background-color': '#752522'}"></span>
                                    <span class="limit-line-length-1 word-break"> Failed</span>
                                </p>
                                <a class="ms-1" matTooltip="Download File" href="{{item?.faild_csv_file}}" target="_blank" *ngIf="item?.faild_csv_file">
                                <img style="filter: invert(27%) sepia(47%) saturate(788%) hue-rotate(316deg) brightness(98%) contrast(98%);" src="assets/images/newUI/CRM/notes_download_icon.svg" alt="">
                                </a>
                            </div>
                            <div *ngIf="item?.file_status === 'SUCCESS'">
                                <p style="background-color: rgba(39, 174, 96, 0.06);color:rgba(39, 174, 96, 1)"
                                    class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                                        [style]="{'background-color': 'rgba(39, 174, 96, 1)'}"></span>
                                    <span class="limit-line-length-1 word-break"> Success</span>
                                </p>
                            </div>
                        </td>
                        <td>{{item?.updated_at | dateConversion}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>