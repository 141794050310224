
export class constant {
  //REGISTER & LOGIN
  static readonly register = '/users/authorisation/register/';
  static readonly login = '/users/authorisation/login/';
  static readonly forgot_psw = '/users/authorisation/forgot-password/';
  static readonly reset_psw = '/users/authorisation/reset-password/';
  static readonly verification_code = '/users/authorisation/activate/';
  static readonly resend_verification_code = '/users/authorisation/resend-activation/';
  static readonly verify_psw_link = '/users/authorisation/verify-link/';

  //SETTINGS
  static readonly general_settings = '/users/organisation/general_settings/';
  static readonly company_settings = '/users/organisation/information/';
  static readonly locations = '/users/organisation/locations/';
  static readonly staff_locations = '/users/staff/locations/';
  static readonly price_settings = '/users/organisation/price_settings/';
  static readonly working_hours = '/users/organisation/working_hours/';
  static readonly staff_info = '/users/staff/information/';
  static readonly developer_info = '/users/developer/info/';
  static readonly developer_password_reset = '/users/developer/password-reset/';
  static readonly roles_permissions = '/users/organisation/roles_permissions/';
  static readonly teams = '/users/organisation/departments/';
  static readonly zoom = '/consultations/zoom/';
  static readonly outlook = '/outlook/';
  static readonly chatbot_outlook = '/emails/personalInbox/isConnected';
  static readonly get_outlook = '/isConnected/';
  static readonly connect_outlook = '/authorize/';
  static readonly disconnect_outlook = '/disconnect/';
  static readonly google = '/googler';
  static readonly google_locations = '/users/organisation/fetch-google-locations/';
  /******************************No more using START**************************/
  static readonly google_register = '/register/';
  static readonly google_authorize = '/authorize/';
  static readonly get_google_calendar = '/calendarevent/';
  /******************************No more using END**************************/

  static readonly get_google_connect = '/getgoogleconnect/';
  static readonly google_disconnect = '/disconnect/';
  static readonly google_connect = '/gmail_authorize/';
  static readonly google_calendar_connect = '/calendar_authorize/';

  /**************Messanger Google API's Start*********/
  static readonly calendar_connect = '/calendar/oauth2/url';
  static readonly google__connect = '/google';
  static readonly outlook_connect = '/outlook';
  static readonly calender_connect_check = '/calendar/isconnected';
  static readonly calender_disconnect = '/calendar/'; 
  static readonly calender_isconnect = '/isconnected/';

  /**************Messanger Google API's END*********/


  static readonly custom_tags = '/crm_service/tags';//'/users/organisation/tag/';
  static readonly custom_client_tags = '/client/tags';
  static readonly dayoff = '/calendars/dayoff/';
  static readonly active_status = '/users/staff/active-status/';
  static readonly branch_staff = '/users/organisation/location/staff-info/';
  static readonly domain_gun = '/communication/domains/';
  static readonly mail_gun = '/communication/domainmails/';
  static readonly mail_gun_staff_emails = '/communication/staff_email_list/';
  static readonly staff_email_signature = '/communication/staff_email_signature/';

  //S3
  static readonly S3presignedUrl = '/users/aws_utils/pre_signed_url/';

  // Leads
  static readonly leads_crm = '/crm_service/Leads';
  static readonly custom_lead_fields_crm = '/crm_service/CustomLeadTemplate';
  static readonly exportLeads = '/crm_service/LeadsExport';
  static readonly custom_filters_leads = '/crm_service/CustomLeadFilter';

  static readonly customEnquiryModel = '/crm_service/CustomLeadsModel';
  static readonly CustomEnquiryTemplate = '/crm_service/CustomLeadTemplate';

  //contacts
  static readonly contacts_crm = '/crm_service/Contacts';
  static readonly custom_contact_fields_crm = '/crm_service/CustomContactTemplate';
  static readonly custom_contact_models_crm = '/crm_service/CustomContactModel';
  static readonly custom_contact_template_crm = '/crm_service/CustomContactTemplate';
  static readonly custom_filters_contacts = '/crm_service/CustomContactFilter';
  static readonly contact_group_list = '/crm_service/ContactGroupsListAPI/';
  static readonly contact_list_from_group = '/crm_service/CreateGroupContactsAPI/';
  static readonly contact_list_from_group_name = '/crm_service/CreateGroupNameContactsAPI/';
  static readonly contact_list_from_group_details = '/crm_service/ContactGroupsDetailAPI/';
  static readonly get_contacts_list_under_groupId = '/crm_service/GetGroupByContactsPaginationAPI/';
  static readonly delete_contacts_from_group = '/crm_service/RemoveGroupContactsAPI/';

  static readonly exportContacts = '/crm_service/ContactsExport';
  static readonly contact_fileds = '/crm_service/CustomContactModel';
  static readonly contact_bulk_inactive = '/crm_service/contact-bulk-inactive';

  static readonly csv_upload_file = '/crm_service/csvUpload';
  static readonly csv_file_mapping = '/crm_service/csvUploadMapping';

  static readonly table_coloums = '/crm_service/field-customization';

  //company
  static readonly companies_crm = '/crm_service/Companys';
  static readonly importCompanies = '/crm_service/CompanyImport';
  static readonly exportCompanies = '/crm_service/CompanyExport';
  static readonly customCompanyModel = '/crm_service/CustomCompanyModel';
  static readonly CustomCompanyTemplate = '/crm_service/CustomCompanyTemplate';
  static readonly custom_filters_company = '/crm_service/CustomCompanyFilter';


  //Reports
  static readonly reports_crm_contact = '/crm_service/reports/contact';
  static readonly reports_crm_company = '/crm_service/reports/company';
  static readonly reports_crm_enquiry = '/crm_service/reports/lead';
  static readonly reports_crm_pipeline = '/crm_service/reports/pipeline';
  static readonly reports_client = '/client/report/client/';
  static readonly reports_project = '/client/report/projects/';
  static readonly reports_task = '/client/report/task/';
  static readonly reports_activities = '/activities/reports/activities/';
  static readonly reports_orders = '/product/orders/report/';
  static readonly reports_appointments = '/consultations/reports/appointments/';

  //timeline
  static readonly auditlog = '/audits/auditlogs/';

  //Activity Logs
  static readonly audit_activitylog = '/audits/audit_activity_text/';

  //notes
  static readonly notes = '/common/notes/';

  //files
  static readonly files = '/common/files/';

  //activity
  static readonly activity = '/activities/activities/';
  static readonly activityType = '/activities/activitytypes/';
  static readonly custom_activity_filter = '/activities/activities_filters';

  //Lost Reasons 
  static readonly crmEnquiryLostReason = '/crm_service/enquiry-lost-reason';
  static readonly crmPipelineLostReason = '/crm_service/pipeline-lost-reason';

  //Activity Outcome
  static readonly activityOutcome = '/activities/activityoutcomes/';

  //taskboard
  static readonly stage = '/client/stages/';
  static readonly task = '/client/tasks/';
  static readonly staff_in_task = '/users/users-info';
  static readonly board = '/client/board';
  static readonly custom_task_filter = '/client/tasks_filters/';

  //clients
  static readonly clients = '/client/clients/';
  static readonly client_projects = '/client/projects/';
  static readonly tags_with_name = '/client/tags_with_names/';
  static readonly delete_client_projects = '/client/';
  static readonly custom_clients_filter = '/client/clientCustomFilter';
  static readonly custom_client_model = '/client/customClientModel';
  static readonly custom_project_model = '/client/customProjectModel';
  static readonly custom_projects_filter = '/client/projectCustomFilter';
  static readonly customClientModel = '/client/customClientModel';
  static readonly CustomClientTemplate = '/client/customClientTemplate';
  static readonly customClientProjectModel = '/client/customProjectModel';
  static readonly CustomClientProjectTemplate = '/client/customProjectTemplate';

  static readonly clients_table_coloums = '/client/clientFieldsCustomization';

  //appointments
  static readonly categories = '/consultations/category/';
  static readonly appointments = '/consultations/appointments/';
  static readonly appointment_count = '/consultations/appointments_count/';
  static readonly appointmentReschedule = '/consultations/appointments/reschedule/';
  static readonly appointmentQuestionnaire = '/consultations/questionnaire/';
  static readonly appointmentStaffService = '/consultations/staff/';
  static readonly custom_appointment_filter = '/consultations/appointments_filters/';
  static readonly calendar_filter = '/consultations/calendar-filter/';

  //Meetings
  static readonly profileMeetings = '/calendars/profiles/';
  static readonly profileCalMeetings = '/calendars/meetings/';
  static readonly profileWorkingHours = '/calendars/workinghours/';
  static readonly profilemeetingsorder = '/calendars/meetingorder/';
  static readonly bookedorgMeetings = '/calendars/bookings/';
  static readonly bookedorgMeeting = '/calendars/booking/';
  static readonly custom_calender_filter = '/calendars/bookings/filters/';

  //product
  static readonly store = '/product/stores/';
  static readonly product_categories = '/categories/';
  static readonly products = '/products/';
  static readonly variants = '/variant/';
  static readonly variants_combinations = '/product/variantscombo/';

  // form-builder
  static readonly form = '/form_builder/form/';
  static readonly form_submissions = '/form_builder/form_submissions/';
  static readonly form_fields = '/form_builder/form_fields/';
  static readonly form_notifications_me = '/form_builder/notification_setting/';
  static readonly form_integration_webhooks = '/form_builder/web_hook_setting/';
  static readonly form_thank_you = '/form_builder/thank_you_page_setting/';
  static readonly form_crm = '/form_builder/crm_setting/';

  //consultations
  static readonly consultations = '/consultations/consultations/';
  static readonly consultations_settings = '/consultations/appointment_settings/';

  //pipeline
  static readonly pipelines = '/crm_service/Pipelines';
  static readonly duplicate_pipelines = '/crm_service/DuplicatePipeline';
  static readonly custom_filters_pipeline = '/crm_service/pipeline/filters';
  static readonly stages = '/crm_service/Stages';
  static readonly bulkstages = '/crm_service/BulkStage';
  static readonly cards = '/crm_service/Cards';
  static readonly bulkCards = '/crm_service/BulkCards';
  static readonly templates = '/crm_service/GlobalPipeline';
  static readonly templatesCard = '/crm_service/TemplateCard';//For getting a system and custom fields
  static readonly templatesFields = '/crm_service/TemplateFields';//For creating a new custom field
  static readonly template = '/crm_service/CreatePipelineWithTemplate';
  static readonly card_fields = '/crm_service/CardField';

  static readonly contact_custom_fields = '';
  static readonly contact_modal_fields = 'crm_service/CustomContactModel';

  //Notifications
  static readonly notification = '/notifications/notifications/';
  static readonly notificationsCount = '/notifications/notifications_unread_count/';

  //Knowledge Base
  static readonly article = '/articles/articles/';
  static readonly group = '/articles/topics/';

  //Announcements
  static readonly announcements = '/articles/annoucements/';

  //conversations
  static readonly conversation = '/communication/communications/';
  static readonly worke_ai = '/botstudio/generatecontent';
  //Slots
  static readonly slots = '/calendars/slots/';

  //invoice
  static readonly invoice = '/documents/invoices/';
  static readonly estimate_proforma_invoice = '/documents/estimate/invoices/';
  static readonly invoice_retry = '/retry';
  static readonly manual_invoice = '/product/manual_order/';
  static readonly proforma_to_invoice = '/documents/proforma_to_invoice/';
  static readonly partial_invoice = '/documents/offline_partial_payment/';
  static readonly invoice_count = '/documents/invoice_count/';

  //terms and conditions 
  static readonly terms_settings = '/documents/invoice_settings/';

  //payments
  static readonly payment_transactions = '/payment/payments/transactions/';

  //payments
  static readonly payments = '/payment/payments/gateway/';
  static readonly transaction = '/payment/payments/transactions/';

  //Set-up
  static readonly setup = '/users/organisation/set_up/';

  //header
  static readonly header_calendar = '/calendars/bookings/';
  static readonly header_calendar_sync = '/calendar/events';

  //badges
  static readonly badges = '/users/organisation/badge/';

  //Taxes
  static readonly tax = '/payment/taxes/';

  //Email Template
  static readonly email_template = '/consultations/template/';

  // Test Email
  static readonly test_email = '/consultations/sample-email/';

  //Conversation from Chatbot
  static readonly chatbot_conversation = '/chats';
  static readonly chatbot_fileupload = '/files';
  static readonly chat_count = '/chats/unread';
  static readonly chat_bot_variables = '/chats/variables/';

  //Reject call 
  static readonly call_reject = '/api/v1/chatService/rejectCall/';

  //orders
  static readonly orders = '/product/orders/';
  static readonly custom_order_filter = '/product/orders_filters/';

  //Googgler mails
  static readonly googler_mails = '/googler/getgooglemails/';
  static readonly send_googler_mails = '/googler/sendmail/';

  //Communications Creations
  static readonly communication_creations = '/communication/communications/';

  //File Management
  static readonly file_management = '/common/folders/';

  //AI

  //Geenrate Email AI
  static readonly ai_email_generator = '/generate-email/';

  //feature management
  static readonly feature_management = '/users/organisation/feature_management/';

  //Dynamic Applications
  static readonly dynamic_app_category = '/dynamic_forms/category/';
  static readonly dynamic_app_form = '/dynamic_forms/form/';
  static readonly dynamic_app_form_duplication = '/dynamic_forms/form-duplication/';
  static readonly dynamic_app_badge = '/dynamic_forms/badge/';
  static readonly dynamic_app_show_user = '/dynamic_forms/form-description-fields/';
  static readonly dynamic_app_user_form_submission = '/dynamic_forms/user-submission-fields/';
  static readonly dynamic_app_form_submission = '/dynamic_forms/submissions/';
  static readonly dynamic_app_notification_settings = '/dynamic_forms/notification-settings/';
  static readonly dynamic_app_thankyou_page = '/dynamic_forms/thank-you-page-settings/';
  static readonly dynamic_app_filters = '/dynamic_forms/category-filter/';

  //sending message to team members
  static readonly share_to_teamMembers = '/chats/sendFiles';

  //subscription Plans
  static readonly billing = '/articles/billing';
  static readonly subscription = '/subscription';
  static readonly plans = '/plans/';
  static readonly plan_transaction = '/transaction/';
  static readonly plan_payment = '/create_payment/';
  static readonly current_plan = '/org_current_plan/';
  static readonly current_plan_usage = '/org_current_plan_usage/';
  static readonly current_plan_days = '/org_current_plan_days/';
}