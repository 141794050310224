import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CONVERSATIONSJSON } from '../../../../core/data/crm_modal_json';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/core/service/crm.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailModalComponent } from 'src/app/pages/shared/email-modal/email-modal.component';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  @ViewChild('mailchat', { read: ElementRef })
  private mailchat!: ElementRef<any>;
  convJSON = CONVERSATIONSJSON[0];
  date!: Date;
  medium_type = 'Mail';
  send_text: any;
  email_converstion: any[] = [];
  mail_template!: FormGroup;
  source_type: any;
  source_id: any;
  source_email = '';
  display_mail_popup = false;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  contact_id: any;
  minDate = new Date();
  showSchedule = false;
  submited = false;
  modalRef: any;
  readOnly = false;
  constructor(private route: ActivatedRoute, private crmService: CrmService,private renderer:Renderer2, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.date = new Date();
    this.route.queryParams.subscribe((params: any) => {
      this.source_id = params.id;
      this.source_type = params.showtype;
      this.contact_id = params.contact_id;
      this.readOnly = params.contact_active === 'false' ? true : false;
    });
    this.getMailTemplate();
    this.crmService.registeredEmailId.subscribe((data: any) => {
      this.source_email = data?.email;
    });
  }

  initilizeMailTemplateForm() {
    this.mail_template = new FormGroup({
      to: new FormControl('', Validators.required),
      subject: new FormControl(''),
      content: new FormControl('', Validators.required),
      source_type: new FormControl(this.source_type, Validators.required),
      source_id: new FormControl(this.source_id, Validators.required),
      schedule_mail: new FormControl()
    });
  }

  getMailTemplate() {
    const body = {
      source_id: this.source_id,
      source_type: this.source_type
    };
    this.crmService.getCommunicationsMails(body).subscribe((resp: any) => {
      this.email_converstion = resp.data;
      const emailsLists: any[] = [];

      this.email_converstion.forEach((elem: any) => {
        const date = new Date(elem.created_at);
        const options: any = { hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = new Date(elem.created_at).toLocaleDateString() +  ', ' +  date.toLocaleString('en-US', options);
        elem['formattedTime'] = formattedTime;

        // const parser = new DOMParser();

        // // Parse the HTML string into a DOM document
        // const doc = parser.parseFromString(elem.message.body, 'text/html');
        // elem.message['parsedContent'] = doc.documentElement.innerText.replace(/\\n/g, '\n');
        const duplicateSender = emailsLists.find((ele: any) => ele.from === elem.message.from);
        if (!duplicateSender) {
          const element = {
            from: elem.message.from,
            colour: this.generateRandomColor()
          };
          emailsLists.push(element);
          elem['colour'] = element.colour;
        } else {
          elem['colour'] = duplicateSender.colour;
        }
      });
    });
    //remove this in future;
    // let counter = 0;
    // this.email_converstion.forEach((element: any) => {
    //   if (element.type === 'sent') {
    //     counter += 1;
    //     if (counter % 2 === 0) {
    //       element['scheduleIcon'] = true;
    //     }
    //   }
    //   return element;
    // });
  }
  generateRandomColor() {
    return "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
  }

  onToogle(id:number){
    const messageBody=document.getElementById('item'+id);
    const fromMailBody = document.getElementById('fromId'+id);
    const cc = document.getElementById('ccId'+id);
    const bcc = document.getElementById('bccId' + id);
    const arrow = document.getElementById('arrow'+id);
    const subject = document.getElementById('subject' + id);
    messageBody?.classList.toggle('limit-line-length-1');
    messageBody?.classList.toggle('content-height');
    fromMailBody?.classList.toggle('message-from');
    cc?.classList.toggle('messageCC');
    bcc?.classList.toggle('messageBCC');
    subject?.classList.toggle('message-from');
    arrow?.classList.toggle('upArrow');
    
  }

  onMailSubmit() {
    this.submited = true;
    if (this.mail_template.valid) {
      this.crmService.sendMail(this.mail_template.value).subscribe((resp: any) => {
        this.getMailTemplate();
        this.modalRef.close();
      });
    }
  }
  get f() {
    return this.mail_template.controls;
  }

  onSaveToDraft() {
    this.modalRef.close();
  }

  openSendMailTemplate(mailType: string) {
    let subject = '';
    if (this.email_converstion.length > 0){
      subject = this.email_converstion[this.email_converstion.length - 1]?.payload?.subject;
    }
    const emailData = {
      source_id: this.source_id,
      source_type: this.source_type,
      contact_id: this.contact_id,
      mailType: false,
      subject: subject,
      source_email:this.source_email,
      type:'sent'
    };
    this.modalRef = this.modalService.open(EmailModalComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.emailData = emailData;
    if(this.modalRef){
      console.log(this.modalRef);
      this.modalRef?.result?.then((receivedEntry: any) => {
        if (receivedEntry === 'success') this.getMailTemplate();
      }).catch(() => {console.log('');});
    }
    // console.log(receivedEntry);
    // if (type) {
    //   this.modalRef = this.modalService.open(content, { size: 'lg', centered: true });
    // }

    // this.initilizeMailTemplateForm();
    // this.getContactById();
    // if (mailType) {
    //   this.showSchedule = true;
    //   this.mail_template.get('schedule_mail')?.addValidators([Validators.required]);
    //   this.mail_template.get('schedule_mail')?.updateValueAndValidity();
    // } else {
    //   this.showSchedule = false;
    //   this.mail_template.get('schedule_mail')?.clearValidators();
    //   this.mail_template.get('schedule_mail')?.updateValueAndValidity();

    // }
    // if (this.email_converstion.length > 0) this.mail_template.controls['subject'].setValue(this.email_converstion[0].payload.subject);
    // this.display_mail_popup = type;
  }

  // getContactById() {
  //   this.crmService.getContactById(this.contact_id).subscribe((resp: any) => {
  //     this.source_email = resp.data[0].email;
  //     this.mail_template.controls["to"].setValue(this.source_email);
  //   });
  // }
}
