import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CONVERSATIONSJSON } from 'src/app/core/data/crm_modal_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-client-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, OnDestroy{
  @ViewChild('messageschat') messagesChat!: ElementRef;
  @ViewChild('iframeRef', { static: true }) iframeRef!: ElementRef;
  convJSON = CONVERSATIONSJSON[0];
  date!: Date;
  medium_type = 'Mail';
  send_text: any;
  mail_template!: FormGroup;
  // modalref!: NgbModalRef;
  display_mail_popup = false;
  selected_id!: string;
  selected_type!: string;
  source_email!: string;
  loggedInUser: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  container: any;
  conversations_array: any = [];
  fileChoosen: any;
  attachedFile: any;
  presignedurl = environment.preSendUrl;
  converstion: any = [];
  apiKey = "AIzaSyBhK5ojDqLIKUonL7rX54ZkvRZVN3ZvVaM"; // Replace with your Google Maps API key
  message: any;
  chatId = '';
  contact_id: any;
  currentPage = 1;
  iframe: any;
  private receiveMessage: ((event: any) => void);
  iframeLoaded = false;
  src: any;

  constructor(private route: ActivatedRoute, private crm_service: CrmService, private client_service: ClientsService, private awsUtilService: AwsUtilsService,
    private spinner: NgxSpinnerService, private sanitizer: DomSanitizer,private chatService:ChatService) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://messengers.brexa.ai/single-conversation' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demomessenger.brexa.ai/single-conversation' : 'https://stagemessenger.brexa.ai/single-conversation');
    this.spinner.show();
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        try {
          this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
        } catch (e) {
          console.log(e);
        }
      }
    };
  }

  ngOnInit(): void {
    this.date = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    // console.log(this.loggedInUser);
    // this.chatToTheBottom();

    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.contact_id = params?.contact_id;
      this.chatService.clearAllMessagesSub$.next({ type: 'clear', id: this.selected_id,showtype:this.selected_type });
      if (params?.activeId && window.location.pathname === '/crm/crm-details'){
        this.getChatconverstion();
      }
      // if (this.selected_id) {
      // this.getPreviousConversation(this.selected_id);// Not using anymore
      // if (this.selected_type === 'lead') {
      //   this.crm_service.getLeadById(this.selected_id).subscribe({
      //     next: (resp: any) => {
      //       // console.log("lead - ", resp.data[0]);
      //       this.contact_id = resp.data[0].contact;
      //       this.source_email = resp.data[0].email;
      //       this.mail_template.patchValue({ mail_to: this.source_email });
      //     }
      //   });
      // }
      //   if (this.selected_type === 'project') {
      //     this.client_service.getProjectDetailsById(this.selected_id).subscribe({
      //       next: (resp: any) => {
      //         // console.log("project - ", resp);
      //         this.source_email = resp.data.email;
      //         this.mail_template.patchValue({ mail_to: this.source_email });
      //       }
      //     });
      //   }
      // }
    });
    // this.ckconfigQue = {
    //   height: 200,
    //   toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
    //   removeButtons: 'Subscript,Superscript'
    // };
    // this.initilizeMailTemplateForm();

    setTimeout(() => {
      this.getChatconverstion();
      this.spinner.hide();
      // this.scrollToBottom();
    }, 5000);
    this.iframe = document.getElementById('chat-widget-value');

    window.addEventListener('message', this.receiveMessage, false);
    setTimeout(async () => {
      const iframeReload = this.iframeRef.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  initilizeMailTemplateForm() {
    this.mail_template = new FormGroup({
      mail_to: new FormControl('', Validators.required),
      mail_from: new FormControl(this.loggedInUser.data.email, Validators.required),
      subject: new FormControl(''),
      msg_body: new FormControl('', Validators.required),
      msg_type: new FormControl('EMAIL', Validators.required)
    });
  }

  workeAI(draft: any) {
    this.client_service.worke_ai({ prompt: "Draft an email body " + draft + " in html tags" }).subscribe({
      next: (resp: any) => {
        this.mail_template.patchValue({ msg_body: resp.data.content.choices[0].message.content });
      }
    });
  }

  onMailSubmit() {
    // console.log(this.mail_template.value);
    if (this.mail_template.valid) {
      const body: any = {
        'type': this.mail_template.value.msg_type,
        'message': {
          'to': this.mail_template.value.mail_to,
          'from': 'support@brexa.ai',
          'subject': this.mail_template.value.subject,
          'body': this.mail_template.value.msg_body
        },
        'media_name': this.fileChoosen ? this.fileChoosen : "",
        'media_url': this.attachedFile ? this.attachedFile : "",
        'from_user_id': '',
        'to_user_id': '',
        'org_id': '',
        'from_Staff': true,
        'source_type': this.selected_type,
        'source_id': this.selected_id,
        'start_time': '',
        'end_time': '',
        'time_zone': '',
        'info': ''
      };
      // console.log(body, "conv msg");

      this.client_service.postConversation(body).subscribe({
        next: (resp: any) => {
          this.converstion.push(body);
          this.fileChoosen = '';
          this.attachedFile = '';
          this.mail_template.reset();
          this.initilizeMailTemplateForm();
          this.mail_template.patchValue({ mail_to: this.source_email });
          // this.converstion = resp.data;
          // this.getPreviousConversation(this.selected_id);
          this.chatToTheBottom();

        }
      });
      this.display_mail_popup = false;
    }
  }

  sendMsg() {
    // console.log(this.send_text, "msg entered");

    // if (this.send_text) {
    //   let body: any = {
    //     'type': "text",
    //     'message': {
    //       'to': "",
    //       'from': "",
    //       'subject': "",
    //       'body': this.send_text
    //     },
    //     'media_name': "",
    //     'media_url': "",
    //     'from_user_id': '',
    //     'to_user_id': '',
    //     'org_id': '',
    //     'from_staff': true,
    //     'source_type': this.selected_type,
    //     'source_id': this.selected_id,
    //     'start_time': '',
    //     'end_time': '',
    //     'time_zone': '',
    //     'info': ''
    //   }
    //   this.sampleMsgData.push(body);
    //   this.send_text = '';
    //   this.chatToTheBottom();
    // }
  }

  openMailTemplate(template_status: boolean) {
    this.display_mail_popup = template_status;
  }

  fileUpload(e: any, type?: string) {
    // console.log(e.target.files[0]);
    this.fileChoosen = e.target.files[0];
    if (type === "chatbot") {
      const formData = new FormData();
      formData.append('files', this.fileChoosen);
      formData.append('fileType', 'any');
      formData.append('associatedWith', 'Chat');
      formData.append('associatedId', this.chatId);
      formData.append('org', this.loggedInUser.data.organisation);

      // this.awsUtilService.chatbotFile(formData).subscribe((file) => {
      //   const body = {
      //     chatId: this.chatId,
      //     type: file.data[0].mimetype.split('/')[0],
      //     payload: {
      //       files: [file.data[0]._id]
      //     }
      //   };
      //   this.client_service.chatbotSendMessage(body).subscribe((messageresp: any) => {
      //     messageresp.data.payload.files = file.data;
      //     this.converstion.push(messageresp.data);
      //   });
      // });
    } else {
      this.awsUtilService.getUploadFileLink(e.target.files[0].type,e.target.files[0].name.split('.')[1]).subscribe((resp) => {
        // console.log(resp);
        this.attachedFile = this.presignedurl + resp.data.file_name;
        this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        });
      });
    }
  }

  removeFile() {
    this.fileChoosen = '';
  }

  getPreviousConversation(id: string) {
    this.client_service.getConversation(id).subscribe({
      next: (resp: any) => {
        this.converstion = resp.data;
        this.chatToTheBottom();

      }
    });
  }

  chatToTheBottom() {
    setTimeout(() => {
      this.container = document.getElementById("syoft-msgContainer");
      this.container.scrollTop = this.container.scrollHeight;
    }, 0);
  }

  /*********************************ChatBot Conversation Start************************/
  getChatconverstion() {
    this.client_service.getChatbotConversation(this.selected_id, this.selected_type).subscribe((resp: any) => {
      this.chatId = resp.data?._id;
      this.src = this.src + '/' + this.chatId;
      console.log("entry");
      try {
        this.iframe?.contentWindow.postMessage({ type: "entityData", data: { id: this.selected_id, type: this.selected_type }}, '*');
      } catch (e) {
        console.log(e);
      }
      try {
        this.iframe?.contentWindow.postMessage({ type: "updateChatId", data: this.chatId }, '*');
      } catch (e) {
        console.log(e);
      }
      if (this.chatId) this.client_service.getChatbotConversationMessages(this.chatId, this.currentPage).subscribe((message: any) => {
        this.converstion.unshift(...message.data.messages);
      });
    });
  }

  onMessageSend() { // no more using
    let body;
    if (!this.chatId) {
      if (this.selected_type === 'lead') {
        body = {
          type: 'text',
          payload: {
            text: this.message
            // files:[]
          },
          user: this.contact_id,
          enquiryId: this.selected_id
        };
      } else {
        body = {
          type: 'text',
          payload: {
            text: this.message
          },
          user: this.contact_id,
          pipelineId: this.selected_id
        };
      }
    } else {
      body = {
        chatId: this.chatId,
        type: 'text',
        payload: {
          text: this.message
          // files:[]
        }
        // replyTo : ""
      };
    }
    this.client_service.chatbotSendMessage(body).subscribe((messageresp: any) => {
      this.message = '';
      this.chatId = messageresp.data.chatId;
      this.converstion = [];
      // this.converstion.push(messageresp.data);
      this.getChatconverstion();
    });
  }
  scrollToBottom() {
    if (this.messagesChat && this.messagesChat.nativeElement) {
      const chatContainer = this.messagesChat.nativeElement;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

  onScroll(): void {
    ++this.currentPage;
    this.getChatconverstion();
  }

  ngOnDestroy(): void {
    this.iframeRef.nativeElement.remove();
  }
  /*********************************ChatBot Conversation End************************/
}
