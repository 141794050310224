<div class="container-fluid pt-1">
    <div class="row">
        <div class="col-md-12 h-35rem">
            <ul ngbNav #customNav="ngbNav" class="nav-tabs nav-tabs-custom mt-0 me-3" [(activeId)]="activeId"
                (navChange)="tabChange($event)">
                <!-- <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Estimation/Quotations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-estimates-and-quotation></app-estimates-and-quotation>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Proforma Invoice</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-proforma-invoices></app-proforma-invoices>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_one}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-invoices></app-invoices>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Payments</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-payments></app-payments>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" class="me-2" *ngIf="featuresData?.tax">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Tax</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-tax></app-tax>
                    </ng-template>
                </li>
                <li [ngbNavItem]="6" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Terms & Conditions</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-terms-and-conditions></app-terms-and-conditions>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="3" class="me-2 d-none">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_two}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2 d-none">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_three}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li> -->
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>