import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FORMTABS, SHAREFORM } from 'src/app/core/data/form_builder_json';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent {
  activeId = 1;
  formtabsJson = FORMTABS[0];
  shareJson = SHAREFORM[0];
  organisation: string = JSON.parse(sessionStorage.getItem("currentUser") ?? "").data.organisation;

  formId = '';
  form_name = '';


  tabChange(ev: any) {
    console.log("ev");
  }

  constructor(
    private route: ActivatedRoute, private setupService: SetUpService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.formId = params.id;
      this.form_name = params.form_name;
    });
  }

  featureShow() {
    return this.setupService.checkFeature('Web Forms');
  }
}
