<div class="container-fluid">
    <div class="row px-2" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="i+1" class="me-2" *ngFor="let heading of lostResHeading;let i = index">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{heading}}</span>
                    </a>
                </li>
            </ul>
            <div class="my-3">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-9 d-flex justify-content-end align-items-center">
                        <button class="btn btn-primary d-flex align-items-center me-3"
                            (click)="onAdd(add_reason_modal)">
                            <mat-icon>add</mat-icon>
                            {{(activeId !== 2)? 'Outcome':'Lost Reason'}}
                        </button>
                    </div>
                </div>
            </div>
            <ng-container>
                <div
                class="mt-3 d-flex flex-column justify-content-between user-settings-div-height">
                    <div class="table-responsive table-height">
                        <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="tableColumns.length"
                            [fromLocation]="'ACTIVITY'"></app-table-skeleton>
                        <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                            <thead>
                                <tr class="position-sticky">
                                    <ng-container *ngFor="let col of lostRes_table_cols;let i = index">
                                        <th scope="col" *ngIf="col.is_visible" class="text-primary pb-3"> <span
                                                *ngIf="i === 1">{{activeId !== 2 ? lostResHeading[activeId-1] + '
                                                Outcome':
                                                lostResHeading[activeId-1] + ' Lost Reason'}} </span> {{ col.name}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody *ngIf="allOutcomeLists && allOutcomeLists.length > 0">
                                <tr class="" *ngFor="let outcome of allOutcomeLists;let i = index">
                                    <td class="text-uppercase">{{lostResHeading[activeId-1]}}</td>
                                    <td>
                                        {{outcome.outcome_text || outcome.reason_text}}
                                    </td>
                                    <td *ngIf="lostRes_table_cols[2].is_visible">
                                        {{outcome.pipeline_name}}
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <mat-icon class="me-2 font-size-18 cursor"
                                                (click)="openEditModal(add_reason_modal,outcome)">edit</mat-icon>
                                            <mat-icon class="font-size-18 cursor"
                                                (click)="onDelete(outcome)">delete</mat-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <mat-paginator [length]="totalPages" [pageSize]="20" [pageIndex]="pageIndex"
                            (page)="page($event)" aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #add_reason_modal let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title text-primary fw-600" id="label">{{allOutcomeForm.value?.id ? 'Update' : 'Add' }}
            {{lostResHeading[activeId-1]}}
        </h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="allOutcomeForm" (ngSubmit)="onSubmit()">
            <label class="mb-2 fw-500 text-primary">{{lostResHeading[activeId-1]}} <span
                    [innerHTML]="(activeId !== 2)? 'Outcome':'Lost Reason'"></span> <small
                    class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput
                    placeholder="{{activeId !== 2 ? 'Enter outcome':'Enter lost reason'}}"
                    formControlName="outcome_text" />
                <mat-error class="error d-flex align-item-center" *ngIf="isSubmitted && f['outcome_text'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                    <span *ngIf="f['outcome_text'].errors['required']"
                        class="small-margin">{{lostResHeading[activeId-1]}} {{(activeId !== 2)? 'outcome':'lost
                        reason'}} is
                        required</span>
                    <span *ngIf="f['outcome_text'].errors['pattern']">Invalid {{(activeId !== 2)? 'outcome':'lost
                        reason'}} name</span>
                </mat-error>
            </mat-form-field>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3" *ngIf="lostResHeading[activeId-1] ==='Flow'">
                <label class="mb-2 fw-600 text-primary font-size-14">Flow <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [(ngModel)]="selectedPipeline" [ngModelOptions]="{standalone:true}"
                    [items]="allPipelineLists" bindLabel="name" bindValue="id" (change)="onSelectPipeline($event)"
                    placeholder="Flow name">
                </ng-select>
                <mat-error class="error d-flex align-items-center" *ngIf="isSubmitted && f['pipeline'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2" style="width: 1em;">info</mat-icon>
                    <span *ngIf="f['pipeline'].errors['required']" class="small-margin">{{lostResHeading[activeId-1]}}
                        name is required</span>
                </mat-error>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</ng-template>