<div class="modal-header">
    <h5 class="modal-title fw-600" *ngIf="!clientProjectData?.id">{{addclient.heading}}</h5>
    <h5 class="modal-title fw-600" *ngIf="clientProjectData?.id">Update Project</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()"></button>
</div>

<div *ngIf="showErrorMessage" class="px-3 pb-0 pt-1" style="box-shadow: 0 4px 14px #e1e1e1;">
    <div>
        <p class="m-0 font-size-13"> <span class="text-danger font-size-13 fw-500">NOTE : </span> You are not
            Authorised to view or use
            <ng-container *ngFor="let fields of revokedSysFieldsList | keyvalue; let i = index">
                <span class="text-danger  fw-600" *ngIf="fields.value.is_visible">{{ fields.key.replace("_", " ") |
                    titlecase }}{{ i < (revokedSysFieldsList | keyvalue).length - 1 ? ', ' : '' }}</span>
            </ng-container>
            feature. Please remove them as required field from <span class="font-size-13 fw-600">Settings >
                Custom Fields > Client Project > System field table</span>.
        </p>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="clientForm">
        <div class="row">

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_project}} <small
                    class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{addclient.label_project}}"
                        formControlName="name" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> Project
                            {{errorJson.required}}</span>
                        <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{addclient.label_name}}</label>
                <ng-select class="form-control" [items]="clients" bindLabel="name" bindValue="id" [multiple]="false"
                    (search)="searchClient($event)"(change)="onSelectClient($event)" (clear)="clearClientFields($event)"  placeholder="Client name" formControlName="client_id">
                </ng-select>
                <div *ngIf="submitted && f['client_id'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center w-3 me-1">info</mat-icon>
                    <span *ngIf="f['client_id'].errors['required']">Client
                        {{errorJson.required}}</span>
                </div>
            </div>

            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <label class="fw-600 mb-2 ">{{addclient.label_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="client_id" placeholder="Client" class="pt-1">
                        <mat-option (click)="onSelectClient(item)" *ngFor="let item of clients" [value]="item.id"
                            class="filteropt">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['client_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['client_id'].errors['required']"> Client
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <label class="fw-600 mb-2 ">{{addclient.label_phone}}</label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input readonly style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                            placeholder="{{addclient.label_phone}}" formControlName="phone" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone'].errors['required']"> {{addclient.label_phone}}
                                {{errorJson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- <label class="fw-600 mb-2 ">{{addclient.label_phone}} <small
                        class="text-danger">*</small></label>
                <div formArrayName="phone">
                    <div *ngFor="let skill of phone.controls; let i = index">
                        <div class="d-flex" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{addclient.label_phone}}"
                                    formControlName="phone" />
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone'].errors">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['phone'].errors['required']">
                                        {{addclient.label_phone}} {{errorJson.required}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <button *ngIf="i > 0" type="button" class="btn btn-danger ms-2" style="height: 45px"
                                (click)="removePhoneNumField(i)">
                                <mat-icon>{{addclient.btn_cancel}}</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <span (click)="addPhoneNumFields()"
                    class="text-success font-size-12 cursor">+{{addclient.btn_add_phone}}</span> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <!-- <form > -->
                <label class="fw-600 mb-2 ">{{addclient.label_email}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input readonly style="outline: none" matInput placeholder="{{addclient.label_email}}"
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']"> {{addclient.label_email}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
                <!-- <label class="fw-600 mb-2 ">{{addclient.label_email}} <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="clientsArray" bindLabel="email_id" [addTag]="addOptionToList"
                    [multiple]="false" placeholder="{{addclient.label_email}}" formControlName="email">
                </ng-select> -->
            </div>

            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{addclient.label_product}}</label>
                <ng-select class="form-control" [items]="products" bindValue="id" bindLabel="name" [addTag]="false"
                    [multiple]="true" placeholder="{{addclient.label_product}}" formControlName="service_ids">
                </ng-select>
            </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{addclient.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select Tag">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 ">Status</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="project_status" placeholder="Status">
                        <mat-option value="PENDING" class="filteropt">Pending</mat-option>
                        <mat-option value="COMPLETED" class="filteropt">Completed</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">Channel</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="channel" placeholder="Select channel" >
                        <mat-option *ngFor="let item of channels"
                            [value]="item.value">{{item.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_price}}</label>
                <div class="d-flex">
                    <!-- <mat-form-field appearance="outline" class="w-50rem me-3">
                        <mat-select style="outline: none" placeholder="{{addclient.label_currency}}"
                            formControlName="currency">
                            <mat-option *ngFor="let item of currency" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{addclient.label_price}}"
                            formControlName="cost" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['cost'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['cost'].errors['pattern']"> {{addclient.label_price}}
                                {{errorJson.invalid}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_date}}</label>
                <mat-form-field appearance="outline" class="w-100 d-none">
                    <input style="outline: none" matInput [matDatepicker]="picker" [min]="minDate"
                        placeholder="{{addclient.label_date}}" formControlName="due_date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['due_date'].errors['required']">Due date
                            {{errorJson.required}}</span>
                    </mat-error> -->
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput [ngxMatDatetimePicker]="dueDatepicker" [min]="minDate" placeholder="Choose a date & time"
                    formControlName="due_date">
                    <mat-datepicker-toggle matSuffix [for]="$any(dueDatepicker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #dueDatepicker></ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_staff}}
                    <!-- <small class="text-danger">*</small> -->
                </label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                    [multiple]="true"
                    [ngClass]="{'disabled-field': clientProjectData?.owner && (clientProjectData.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')}"
                    placeholder="{{addclient.label_staff}}" formControlName="assignee">

                </ng-select>
                <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['assignee'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div> -->
                <!-- <label class="fw-600 mb-2 ">{{addclient.owner}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="staff_ids" placeholder={{addclient.owner}} class="pt-1">
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']">
                            {{addclient.owner}} {{errorJson.required}}
                        </span>
                    </mat-error>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.owner}} <small class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" (change)="selectOwner($event)"
                    bindLabel="fname"
                    [ngClass]="{'disabled-field': clientProjectData?.owner && (clientProjectData.owner !== loggedInUser.data.id && loggedInUser.data.role !== 'ADMIN')}"
                    [addTag]="false" placeholder="{{addclient.owner}}" formControlName="owner">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['owner'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['owner'].errors['required']"> Owner
                        {{errorJson.required}}</span>
                </div>
            </div>

            <div class="col-sm-12 mb-3 col-md-12 col-lg-12 col-xl-12 mt-3">
                <label class="fw-600 mb-2 ">{{addclient.label_dsp}}</label>
                <ckeditor [editor]="Editor" placeholder="Description" #editorComponent formControlName="description"
                    [config]="ckconfigQue" class="ckeditor-height"
                    [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                    (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)">
                </ckeditor>
                <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                    class="font-size-10">Maximum
                    1000 characters are accepted.</small><small
                    [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                    class="font-size-10 ps-1">(Character limit:
                    {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                <!-- <mat-form-field appearance="outline" class="w-100 h-10rem">
                    <textarea style="outline: none;" matInput class="h-10rem" maxlength="1000" formControlName="description"></textarea>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" formArrayName="system_project_fields"
                *ngFor="let system_field of system_project_fields.controls;let i=index;">
                <div class="row" [formGroupName]="i" *ngIf="system_field.value.on_form">
                    <div>
                        <label class="fw-600 mb-2 ">{{system_field.value.label.replace("_", " ") |
                            titlecase}}</label>&nbsp;
                        <small class="text-danger" *ngIf="system_field.value.is_mandatory">*</small>
                        <ng-container *ngIf="system_field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
                            <ng-select class="form-control" [items]="products" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Products" formControlName="data"
                                *ngIf="system_field.value.label === 'product'">
                            </ng-select>
                            <ng-select class="form-control" [items]="productCategory" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Product Categories" formControlName="data"
                                *ngIf="system_field.value.label === 'product_category'">
                            </ng-select>
                            <ng-select class="form-control" [items]="services" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Services" formControlName="data"
                                *ngIf="system_field.value.label === 'service'">
                            </ng-select>
                            <ng-select class="form-control" [items]="serviceCategory" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Service Categories" formControlName="data"
                                *ngIf="system_field.value.label === 'service_category'">
                            </ng-select>
                            <ng-select class="form-control" [items]="aptCategoriesArray" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Appointment Categories" formControlName="data"
                                *ngIf="system_field.value.label === 'appointment_category'">
                            </ng-select>
                            <ng-select class="form-control" [items]="appointmentsArray" bindLabel="name" bindValue="id"
                                [multiple]="true" placeholder="Appointments" formControlName="data"
                                *ngIf="system_field.value.label === 'appointment'">
                            </ng-select>
                            <div class="error d-flex align-items-center" *ngIf="submitted && getSystemFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{system_field.value.label.replace("_", "
                                    ")}}</span>&nbsp; {{errorJson.required}}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <hr class="mt-2">
        <h5>
            Additional Fields
        </h5>
        <div formArrayName="custom_project_fields" class="row">
            <div *ngFor="let field of custom_project_fields.controls; let i=index"
                class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <div class="row" [formGroupName]="i" *ngIf="field.value.on_form">
                    <div>
                        <label class="fw-600 mb-2 ">{{field.value.label}}</label>&nbsp;
                        <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

                        <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height-2" #customEditorComponents
                                (ready)="onCustReady($event,'cust'+i)"
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="custEditorkeyEvent($event,customEditorComponents,'cust'+i)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists['cust'+i]?.actualLimit}}/{{ckeditorLimitLists['cust'+i]?.maxLimit}})</small>
                            <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                                placeholder="write a message"></textarea> -->
                            <div class="error d-flex align-items-center" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
                            <div *ngFor="let options of field.value.options">
                                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                                    {{options}}
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'SELECT_DROPDOWN' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select formControlName="data">
                                    <!-- <mat-option [value]="'N/A'">
                                        None
                                    </mat-option> -->
                                    <mat-option *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
                            <div>
                                <mat-radio-group formControlName="data">
                                    <mat-radio-button *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div class="error d-flex align-items-center"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                    <span class="text-capitalize">{{field.value.label.replace("_", "
                                        ")}}</span>&nbsp; {{errorJson.required}}
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
                            <label class="switch d-none">
                                <input type="checkbox" formControlName="data">
                                <span class="slider round"></span>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" type="number"
                                    (keydown)="keyDownCustomField($event,0,'custom_field')"
                                    (wheel)="$event.preventDefault()" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker>
                                </ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-date-range-input [rangePicker]="dateRangepicker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getCustomFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'FILE'">
                            <div class="" *ngIf="clientProjectData?.id">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3"
                                download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                                (change)="onFileUpload($event,i)" />
                            <div class="error d-flex align-items-center" 
                            *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.label}}" formControlName="end" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getCustomFieldValidity(i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", " ") }}
                                            {{errorJson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    
                        <ng-container *ngIf="field.value.data_type === 'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                              <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
                              <mat-error class="error text-danger d-flex" *ngIf="submitted && getCustomFieldValidity(i)">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                  class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                {{errorJson.required}}
                              </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="mt-3 d-flex justify-content-between align-items-center modal-footer">
    <div class="d-flex cursor" routerLink="settings/custom-fields" [queryParams]="{ activeLink: 'Client Projects'}"
        (click)="ngbActiveModal.dismiss()">
        <span class="material-icons text-primary">settings_applications</span>
        <span class=""></span> {{addclient.customize_text}}
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="button" (click)="cancelModal()">
            {{addclient.cancel_btn}}
        </button>
        <button class="btn btn-primary" [disabled]="loading || (ckeditorLimit.actualLimit > ckeditorLimit.maxLimit) || showErrorMessage" type="submit"
            (click)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && !showErrorMessage && onSubmit()">{{addclient.save_btn}}</button>
    </div>
</div>