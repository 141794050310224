<div class="mb-0 task-details position-relative ">
  <div class="header d-flex justify-content-between">
    <div>
      <h6 class="fw-600 font-size-20 m-0 me-3 limit-line-length-1" style="color:#232323">{{ taskDetails?.name }}</h6>
      <h6 class="fw-600 font-size-20 m-0 me-3 limit-line-length-1" *ngIf="taskDetails?.info?.fname"
        style="color:#232323">{{
        taskDetails?.info?.fname }}
      </h6>
      <div class="d-flex align-items-center priority mt-1"
        [ngClass]="taskDetails?.priority === 'High' ? 'bg-high': taskDetails?.priority === 'Low' ? 'bg-low': 'bg-medium'"
        *ngIf="taskDetails?.priority">
        <div class="dot"
          [ngClass]="taskDetails?.priority === 'High' ? 'bg-dot-high': taskDetails?.priority === 'bg-dot-low' ? 'bg-low': 'bg-dot-medium'">
        </div>
        <p class="m-0 font-size-12 fw-500">{{taskDetails?.priority}} Priority</p>
      </div>
    </div>
    <div>
      <mat-select class="status_dropdown bg-primary border-radius-8 px-3" [disabled]="taskDetails?.mark_as_done"
        [ngStyle]="{'opacity':taskDetails?.mark_as_done?'0.7':'1'}" (selectionChange)="onSelectStatus($event)"
        [(ngModel)]="status_dropdown">
        <div *ngFor="let data of stageList">
          <mat-option [value]="data.name">{{ data?.name | titlecase }}</mat-option>
        </div>
      </mat-select>
    </div>
  </div>
  <div class="">
    <div class="d-flex align-items-center task-border p-3">
      <img src="assets/images/newUI/HOME/task_details_icons.svg" alt="">
      <h6 class="fw-700 m-0 ms-2" style="color:#232323;">{{ showType | titlecase }} Details</h6>
      <img *ngIf="showType === 'task'" (click)="onOpenTaskModal()" src="assets/images/edit_pen.svg" style="width: 20px !important; height: 20px !important;top: 2px;"
        class="position-relative ms-2 cursor" alt="" >
    </div>
    <div class="no-gutters">
      <div *ngIf="showType === 'task' && taskDetails?.display_id">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Task Id',titleValue:taskDetails?.display_id ? taskDetails?.display_id : 'NA'}"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="list_view;context:{title:'Name',titleValue:taskDetails?.name}"></ng-container>
      <ng-container *ngTemplateOutlet="list_view;context:{title:'Due Date',titleValue:taskDetails?.due_date ?
          (taskDetails?.due_date | dateConversion) : 'NA' + ' ' + taskDetails?.due_date ? (taskDetails?.due_date | date:
          'h:mm a') : '' }"></ng-container>
      <div *ngIf="showType !== 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Due Date & Time',titleValue:taskDetails?.name}"></ng-container>
      </div>

      <div *ngIf="showType === 'task' && taskDetails?.start_date">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Start Date',titleValue:taskDetails?.start_date ? (taskDetails?.start_date | dateConversion) : 'NA'}"></ng-container>
      </div>
      <div *ngIf="showType === 'task' && taskDetails?.working_hours">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Time estimate in hours',titleValue: taskDetails?.working_hours}"></ng-container>
      </div>
      <div *ngIf="showType === 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Stage',titleValue:taskDetails?.stage_name | titlecase,color:'#302E7C',bgColor:'#E1E7FD'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Priority',titleValue:taskDetails?.priority}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Tag',titleValue:taskDetails?.tag_name,color:taskDetails?.colour}"></ng-container>
      </div>
      <div *ngIf="showType !== 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Outcome',titleValue: taskDetails?.outcome_text ? (taskDetails?.outcome_text | titlecase) : ''}"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="list_view;context:{title:'Related To',titleValue:taskDetails?.source_type === 'deal' ? 'Flow' : taskDetails?.source_type}"></ng-container>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Created At',titleValue:taskDetails?.created_at ? (taskDetails?.created_at | dateConversion) : ''}"></ng-container>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Updated At',titleValue:taskDetails?.updated_at ? (taskDetails?.updated_at | dateConversion) : 'NA'}"></ng-container>
      <div *ngIf="showType === 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:taskDetails?.owner_info?.fname ? taskDetails?.owner_info?.fname : 'NA'}"></ng-container>
      </div>
      <div *ngIf="showType !== 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Owner',titleValue:taskDetails?.info?.owner_info?.fname ? taskDetails?.info?.owner_info?.fname : 'NA'}"></ng-container>
      </div>

      <div *ngIf="showType === 'task'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Assignee',titleValue:taskDetails?.info?.staff_info_array}"></ng-container>
      </div>
      <ng-container
        *ngTemplateOutlet="list_view;context:{title:'Description',titleValue:taskDetails?.description}"></ng-container>
      <div *ngIf="showType === 'Meeting'">
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Meeting Url',titleValue:taskDetails?.meeting_link}"></ng-container>
      </div>
    </div>
  </div>
  <div class="checklist px-3 border-bottom" *ngIf="showType === 'task'">
    <div class="d-flex align-items-center py-3">
      <img src="assets/images/newUI/HOME/checkbox_icon.svg" alt="">
      <h6 class="font-size-16 fw-600 ps-2 m-0">Checklist</h6>
    </div>
    <div class="card mt-2">
      <div class="card-body rounded d-flex flex-column justify-content-center">
        <ul class="p-0" *ngIf="checklist_array.length > 0">
          <li *ngFor="let item of checklist_array;let i=index">
            <mat-checkbox [checked]="item.value" (change)="onCheckBoxChange($event,i)">
              {{item.name}}
            </mat-checkbox>
          </li>
        </ul>
        <div class="example-box mb-3 row d-flex align-items-center" *ngIf="newItemAdded">
          <div class="col-md-10">
            <mat-form-field appearance="outline" class="w-100 mat-hint-none">
              <input style="outline: none" matInput placeholder='Item' [(ngModel)]="newItemText" />
            </mat-form-field>
          </div>
          <div class="col-md-2 px-0" style="overflow: visible;">
            <div class="d-flex align-items-center">
              <mat-icon class="d-flex align-items-center font-size-16 cursor position-relative top-8"
                style="color: rgb(0, 128, 55);width: 16px;" (click)="newCheckListAdded()">done</mat-icon>
            </div>
          </div>
        </div>
        <button class="btn custom-checkbox-btn font-size-14  d-flex align-items-center width-fit-content"
          (click)="addItemCheckBox()">Add an Item</button>
      </div>
    </div>
  </div>
  <div class="pb-4 border-bottom" *ngIf="showType === 'task'">
    <div class="notes-sec d-flex flex-column justify-content-between">
      <div>
        <div class="top-add-notes-sec p-3" *ngIf="!showEdit">
          <div class="d-flex align-items-center mb-4">
            <img src="assets/images/newUI/CRM/add_notes_icons.svg" alt="">
            <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Add Notes</p>
          </div>
          <div class="pt-1">
            <ng-container *ngTemplateOutlet="edit_notes;context:{index:'NA'}"></ng-container>
          </div>
        </div>
        <ul class="list-group mt-1 border-radius-0 new_design_2024">
          <div class="d-flex align-items-center mt-3 mb-3 px-3">
            <img src="assets/images/newUI/CRM/saved_notes_icons.svg" alt="">
            <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Saved Notes</p>
          </div>
          <div style="max-height:40vh;overflow: auto;" class=" auto-hide-scroll-bar">
            <li class="group-item py-2" *ngFor="let note of currentPageNotesList;let i = index">
              <div class="d-flex align-items-center w-100 px-3">
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between mb-2">
                    <div class="d-flex align-items-center">
                      <div class="name-circle text-capitalize">
                        <span>{{note.info?.fname[0]}}</span>
                      </div>
                      <p class="m-0 ps-2 font-size-14 fw-500 user-name">
                        {{note.info?.fname}}
                        {{note.info?.lname}}
                      </p>
                    </div>
                    <p class="m-0 font-size-12 fw-500" style="color: #8B8B8B;">{{note.customTime}}</p>
                  </div>
                  <div class="p-3 notes-body border-radius-8">
                    <p class="m-0 font-size-14 fw-400  text-break" style="color: #404040;" [id]="i+'pTag'"
                      [innerHTML]="note.notes | safeHtml">
                    </p>
                    <div *ngIf="showEdit && selectedIndex === i">
                      <ng-container *ngTemplateOutlet="edit_notes;context:{index:i}"></ng-container>
                    </div>
                    <div class="notes-control-sec d-flex align-items-center  pt-1" *ngIf="selectedIndex !== i">
                      <div class="d-flex align-items-center">
                        <img src="assets/images/edit_pen.svg" class="p-1 cursor" alt="" (click)="editNotes(note,i)">
                        <img src="assets/images/newUI/CRM/delete_icons.svg" class="cursor p-1"
                          (click)="confirmDelete(note.id)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </div>
          <div class="mt-2">
            <mat-paginator [length]="notes_total_count" [pageSize]="20" (page)="page($event)" aria-label="Select page"
              showFirstLastButtons>
            </mat-paginator>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div class="files mt-3 mb-3 px-3" *ngIf="showType === 'task'">
    <div>
      <div class="d-flex align-items-center mb-4">
        <img src="assets/images/newUI/CRM/file_folder_icons.svg" alt="">
        <p class="m-0 ps-2 title-text-clr font-size-16 fw-600">Add Files</p>
      </div>
      <div style="border-bottom: 1px dashed #E1E0E0;">
        <div class="col-12">
          <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [draggable]="false" id="media-dropzone"
            class="file-upload-sec border-radius-8" style="height: 80px; min-height: 80px;"
            accept="image/jpeg,image/jpg,image/png,image/gif,video/mp4,video/mp3,video/mov,text/plain,.doc,.docx,.txt,.pdf">
            <ngx-dropzone-label class="m-0 mx-3 font-size-14"> {{filesJSON.dropfile_placeholder_text1}} <span
                class="text-primary fw-500">{{filesJSON.dropfile_placeholder_text2}}</span>
              {{filesJSON.dropfile_placeholder_text3}}
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
              *ngFor="let f of files" [file]="f">
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div>
        <p class="fw-400 mb-0 font-size-12 mt-2 pb-2" style="color: #717179;">Files of JPG, JPEG, PNG,
          GIF,
          PDF, DOC, DOCX, TXT, MP3 MP4, or
          MOV
          formats with max of
          15MB
          are allowed.</p>
        <div class="d-flex my-3">
          <div class="" style="width:40px;height:40px" *ngIf="show_loader">
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
              <path fill="black"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                  to="360 50 50" repeatCount="indefinite" />
              </path>
            </svg>
          </div>
          <button *ngIf="!show_loader" class="btn border-radius-8 bg-primary fw-500 font-size-14 text-white action-btn"
            type="button" [disabled]="!this.files[0]" (click)="onUpload()">Upload File</button>
        </div>
      </div>
      <div class="files-div-height d-flex flex-column justify-content-between new_design_2024 pt-3">
        <div class="table-responsive table-height h-100">
          <table class="table align-middle table-nowrap tßable-hover mb-0 file-table ">
            <thead>
              <tr class="table-header-color">
                <th scope="col">{{filesJSON.col_one_label}}</th>
                <th scope="col">{{filesJSON.col_seven_label}}</th>
                <th scope="col">{{filesJSON.col_two_label}}</th>
                <th scope="col">{{'Delete'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filesListArray; let i= index;">
                <td>
                  <div class="d-flex align-items-baseline list-name-sec">
                    <img src="{{item.media_url}}" (error)="onErrorLoadImage($event,presignedurl,item.name)"
                      height="20px" width="20px">
                    <a href="{{item.media_url}}" target="_blank"
                      class="position-relative ms-2 text-dark fw-medium limit-line-length-1" download>
                      {{item.name}}
                    </a>
                  </div>

                  <span class="file-badges font-size-10 ms-2 px-2 py-1 border-radius-6"
                    *ngIf="item?.source_type === 'consultation'">From Appointment</span>
                </td>
                <td class="limit-line-length-1">{{item?.created_by_name || "NA" }}</td>
                <td>{{item.updated_at | dateConversion }}</td>
                <td>
                  <mat-icon class="cursor d-flex align-items-center"
                    (click)="confirmDeleteFile(item.id)">delete</mat-icon>
                </td>
              </tr>
              <tr *ngIf="filesListArray && filesListArray.length === 0">
                <div class="d-flex align-items-center justify-content-center" style="transform: translateX(70%);">
                  <div class="text-center">
                    <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px"
                      width="160px">
                    <h4 class="mt-4">
                      No Data Available
                    </h4>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <mat-paginator [length]="filesTotal" [pageSize]="20" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #list_view let-title="title" let-value="titleValue" let-color="color" , let-bgColor="bgColor">
  <div class="no-gutters task-border list-view-sec p-3 d-flex align-items-start">
    <div class="info-accord col-2">
      <p class="text-inner-info m-0 limit-line-length-1">{{title}} </p>
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="info-accord d-flex col-10">
      <div class="text-name d-flex text-wrap w-100 align-items-start">
        <span class="pe-1 fw-600">:</span>
        <div class="max-width"
          *ngIf="title !== 'Tag' && title !== 'Assignee' && title !=='Description' && title !== 'Meeting Url' && title !== 'Priority'">
          <p class="text-name cursor limit-line-length-1 m-0">
            {{value || "NA"}}
          </p>
        </div>
        <div *ngIf="title === 'Tag'" class="max-width">
          <p *ngIf="value && title !== 'Tag'" [style]="{'background-color': bgColor, 'color':color}"
            class="text-name m-0 bg-tag limit-line-length-1 d-inline-flex align-items-center px-1 border-radius-50">
            {{value}}
          </p>
          <p *ngIf="value && title === 'Tag'" style="display: flex !important;"
            class="text-name m-0 bg-tag limit-line-length-1 align-items-center px-2 border-radius-50">
            <span *ngIf="value && title === 'Tag'" class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
              [style]="{'background-color': color}"></span>
            <span>{{value}}</span>
          </p>
          <p class="text-name m-0" *ngIf="!value">NA</p>
        </div>
        <div *ngIf="title === 'Priority'" class="max-width">
          <p *ngIf="value" [ngClass]="value === 'High' ? 'bg-high': value === 'Low' ? 'bg-low':
        'bg-medium'" class="text-name limit-line-length-1 m-0 d-inline-flex align-items-center px-2 border-radius-50">
            {{value}}
          </p>
          <p class="text-name m-0" *ngIf="!value">NA</p>
        </div>
        <div *ngIf="title === 'Assignee'" class="max-width">
          <p class="text-name m-0 " *ngIf="value && value.length > 0">
            <span *ngFor="let assignee of value">{{assignee.fname | titlecase}} {{assignee.lname | titlecase}},
            </span>
          </p>
          <p class="text-name m-0" *ngIf="!value || value.length === 0">NA</p>
        </div>
        <div *ngIf="title === 'Description'" class="max-width" style="word-wrap: break-word">
          <p class="text-name limit-line-length-2" [innerHTML]="value ? value : 'NA'"></p>
        </div>
        <div *ngIf="showType === 'Meeting' && title === 'Meeting Url'" class="max-width">
          <p class="text-name d-flex align-items-center">
            <a [href]="value" target="_blank" class="limit-line-length-1 text-decoration-none text-black cursor"
              *ngIf="value">{{companyUrl+value}}</a>
            <span *ngIf="!value">NA</span>
            <span class="calender-icons" *ngIf="value">
              <mat-icon class="cursor" (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                matTooltip="Copy to clipboard" matTooltipPosition="above" [cdkCopyToClipboard]="companyUrl+value"
                #tp="matTooltip">content_copy</mat-icon>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #edit_notes let-index="index">
  <div class="notes bg-white border-radius-8">
    <div data-text="What's this note about?"
      class="form-control contenteditable-div content-input border-radius-0 m-0 overflow-y font-size-15"
      aria-label="With textarea" [mention]="userLists" [mentionConfig]="mentionConfig"
      (itemSelected)="itemSelected($event)" (click)="expandContent($event)"
      [ngClass]="{'height-class' : contentExpand === true}" contenteditable="true" [id]="index+'myContent'"
      (keyup)="handleInput()"></div>
  </div>
  <div class="mt-3 pt-1 notes-input-control" *ngIf="contentExpand">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center cursor border-radius-8 bg-white fw-500 font-size-14 me-2 btn-light"
          (click)="clearNotes(index)">
          <mat-icon>clear</mat-icon>
          <span class="ms-2">{{'Cancel'}}</span>
        </div>
        <button class="btn border-radius-8 bg-primary fw-500 font-size-14 text-white btn-primary ms-2"
          [disabled]="!notesContent" (click)="saveNotes(index)">{{'Save Notes'}}</button>
      </div>
    </div>
  </div>
</ng-template>