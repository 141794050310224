import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { MENU } from '../data/menu_list';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent {

  menuList: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches)
    );
  selectedMenu: any;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private cdr: ChangeDetectorRef) {
    this.menuList = MENU;
  }

  changeOfRoutes() {
    this.menuList.forEach((ele) => {
      if (ele.label.toLowerCase() === this.router.url.replace('_', " ").split('/')[1] || (ele.label.toLowerCase()  === 'flows' && this.router.url.replace('_', " ").split('/')[1] === 'pipelines')) {
        this.selectedMenu = ele;
        this.cdr.detectChanges();
      } else if ('user-profile' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 21,
          "label": "Profile",
          "icon": "",
          "link": "/user-profile",
          "slug": "",
          "image": "",
          "color_img": ""
        };
      } else if ('settings' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 21,
          "label": "Settings",
          "icon": "settings",
          "link": "/settings",
          "slug": "settings",
          "image": "./assets/images/settings.png",
          "color_img": "./assets/images/color_settings_Icon.svg"
        };
      } else if ('header-calendar' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 22,
          "label": "Calendar",
          "icon": "calendar",
          "link": "/header-calendar",
          "slug": "calendar",
          "image": "./assets/images/calendar.png",
          "color_img": "./assets/images/color_personal_calendar_Icon.svg"
        };
      } else if ('taskboard' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 23,
          "label": "Boards",
          "icon": "dashboard",
          "link": "/taskboard",
          "slug": "taskboard",
          "image": "./assets/images/notes-edit-add.svg",
          "color_img": "./assets/images/color_board_Icon.svg"
        };
      } else if ('filemanager' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 29,
          "label": 'File Manager',
          "icon": 'dashboard',
          "link": '/filemanager',
          "slug": 'filemanager',
          "image": './assets/images/task-board.svg',
          "color_img": './assets/images/newUI/MENU/file_manager_icon_color.svg'
        };
      } else if ('finance' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 17,
          "label": 'Finance',
          "link": '/finance',
          "slug": 'finance',
          "icon": 'monetization_on',
          "image": './assets/images/Finance_Icon.svg',
          "color_img": './assets/images/newUI/MENU/finance_icon_color.svg'
        };
      } else if ('reports' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 16,
          "label": 'Reports',
          "link": '/reports',
          "slug": 'reports',
          "icon": 'pie_chart',
          "image": './assets/images/Report_Icon.svg',
          "color_img": './assets/images/newUI/MENU/reports_icon_color.svg'
        };
      } else if ('conversation' === this.router.url.split('/')[1]) {//Route Link and label name are different so we are using this to match the heding 
        this.selectedMenu = {
          id: 25,
          label: 'Messages',
          icon: 'chat',
          link: '/conversation',
          slug: 'conversation',
          image: './assets/images/conversations (1).svg',
          color_img: './assets/images/conversations-color.svg'
        };
      } else if ('folder=inbox' === this.router.url.split('?')[1]) {
        this.selectedMenu = {
          id: 30,
          label: 'Inbox',
          icon: 'chat',
          link: '/bot_chat?folder=inbox',
          slug: 'inbox',
          image: './assets/images/conversations (1).svg',
          color_img: './assets/images/conversations-color.svg'
        };
      }
    });
  }
}
