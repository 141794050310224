import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bulk-import',
  templateUrl: './bulk-import.component.html',
  styleUrls: ['./bulk-import.component.scss']
})
export class BulkImportComponent implements OnInit {
  field_data: any = [];
  hoveredIndex: any;
  fieldsList: any[] = [];
  date_formats = ['yyyy-MM-dd', 'yyyy-dd-MM', 'yyyy/MM/dd', 'yyyy.MM.dd', 'yyyy.dd.MM', 'yyyy/dd/MM', 'dd/MM/yyyy', 'dd-MM-yyyy',
    'MM/dd/yyyy', 'dd.MM.yyyy', 'MM.dd.yyyy', 'MM-dd-yyyy', 'yy-MM-dd', 'yy-dd-MM', 'MM-dd-yy', 'dd/MM/yy', 'MM/dd/yy', 'yy/dd/MM',
    'yy/MM/dd', 'dd.MM.yy', 'MM.dd.yy', 'yy.MM.dd', 'yy.dd.MM'];
  mappingForm!: FormGroup;
  imported_id = '';
  file_name: any;
  showType: any;
  selectedPipeline: any;
  selectedStage: any;
  featuresData: any;
  field_index = 0;
  public bankFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public filteredBanks: Array<ReplaySubject<any[]>> = new Array<ReplaySubject<any[]>>(0);
  group_id: any;

  constructor(private _customFields: CustomFieldsService, private activatedRoute: ActivatedRoute,
    private service: CrmService, private route: Router, private toastr: ToastrService, private authservice: AuthService) { }

  ngOnInit(): void {
    this.getFeatues();
    this.activatedRoute.queryParams.subscribe((data: any) => {
      this.imported_id = data['id'];
      this.group_id = data?.group_id;
      if (data.module) {
        this.showType = data.module;
        this.getUploadFileData(data);
        this.intilizeForm();
      }
    });
    this.bankFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  intilizeForm() {
    this.mappingForm = new FormGroup({
      csv_id: new FormControl(this.imported_id, Validators.required),
      fields_mapping: new FormArray([]),
      group_id: new FormControl(this.group_id)
    });
  }

  get FormItems() {
    return this.mappingForm.get('fields_mapping') as FormArray;
  }

  FormItemsArray(data?: any) {
    return new FormGroup({
      table_header: new FormControl(data ? data.label : ''),
      mapped_field: new FormControl(''),
      replace_text: new FormControl(''),
      date_type: new FormControl(data ? data.data_type : '')
    });
  }

  addFormItem(data?: any) {
    this.FormItems.push(this.FormItemsArray(data));
  }

  getContactModelFields() {
    this._customFields.getContactModelFields().subscribe((resp: any) => {
      this.fieldsList = resp.data?.custom_contact_templates.filter((item: any) => { return item.is_active && item.data_type !== 'MULTI_SELECT_DROPDOWN' && item.data_type !== 'SELECT_DROPDOWN' && item.data_type !== 'RADIO_BUTTON' && item.data_type !== 'FILE'; });
      Object.entries(resp.data).forEach((field: any) => {
        if (field[0] !== 'id' && field[0] !== 'custom_contact_templates' && field[0] !== 'contact_fields_order' && field[0] !== 'organisation'
          && field[0] !== 'owner' && field[0] !== 'channel' && field[0] !== 'tags' && field[0] !== 'assignee') {
          if (field[1].on_form || field[1].is_inner_screen) {
            if (field[0] === 'company' && this.featuresData['company']) this.fieldsList.push({ label: field[0], data_type: field[1].data_type });
            else if (field[0] !== 'company' && field[1].data_type !== 'MULTI_SELECT_DROPDOWN' && field[1].data_type !== 'SELECT_DROPDOWN' && field[1].data_type !== 'RADIO_BUTTON' && field[1].data_type !== 'FILE') this.fieldsList.push({ label: field[0], data_type: field[1].data_type });
          }
        }
      });
      this.field_data.forEach((data: any, index: number) => {
        this.filteredBanks[index].next(this.fieldsList.slice());
      });
    });
  }

  getCompanyModelFields() {
    this._customFields.getCompanyModelFields().subscribe((resp: any) => {
      this.fieldsList = resp.data?.custom_company_templates.filter((item: any) => { return item.is_active && item.data_type !== 'MULTI_SELECT_DROPDOWN' && item.data_type !== 'SELECT_DROPDOWN' && item.data_type !== 'RADIO_BUTTON' && item.data_type !== 'FILE'; });
      Object.entries(resp.data).forEach((field: any) => {
        if (field[0] !== 'id' && field[0] !== 'custom_company_templates' && field[0] !== 'company_fields_order' && field[0] !== 'organisation'
          && field[0] !== 'owner' && field[0] !== 'channel' && field[0] !== 'tags' && field[0] !== 'assignee') {
          if ((field[1].on_form || field[1].is_inner_screen) && field[1].data_type !== 'MULTI_SELECT_DROPDOWN' && field[1].data_type !== 'SELECT_DROPDOWN' && field[1].data_type !== 'RADIO_BUTTON' && field[1].data_type !== 'FILE') {
            this.fieldsList.push({ label: field[0], data_type: field[1].data_type });
          }
        }
      });
      this.field_data.forEach((data: any, index: number) => {
        this.filteredBanks[index].next(this.fieldsList.slice());
      });
    });
  }

  getEnquiryModelFields() {
    this._customFields.getEnquiryModelFields().subscribe((resp: any) => {
      this.fieldsList = resp.data?.custom_lead_templates.filter((item: any) => { return item.is_active && item.data_type !== 'MULTI_SELECT_DROPDOWN' && item.data_type !== 'SELECT_DROPDOWN' && item.data_type !== 'RADIO_BUTTON' && item.data_type !== 'FILE'; });
      const static_fields = [
        { label: 'first_name', data_type: 'Text' },
        { label: 'email', data_type: 'Text' },
        { label: 'ccode', data_type: 'Text' },
        { label: 'phone_number', data_type: 'Text' }
      ];
      this.fieldsList.push(...static_fields);
      Object.entries(resp.data).forEach((field: any) => {
        if (field[0] !== 'id' && field[0] !== 'custom_lead_templates' && field[0] !== 'lead_fields_order' && field[0] !== 'organisation'
          && field[0] !== 'owner' && field[0] !== 'channel' && field[0] !== 'tags' && field[0] !== 'assignee' && field[0] !== 'company'
          && field[0] !== 'contact') {
          if ((field[1].on_form || field[1].is_inner_screen) && field[1].data_type !== 'MULTI_SELECT_DROPDOWN' && field[1].data_type !== 'SELECT_DROPDOWN' && field[1].data_type !== 'RADIO_BUTTON' && field[1].data_type !== 'FILE') this.fieldsList.push({ label: field[0], data_type: field[1].data_type });
        }
      });
      this.field_data.forEach((data: any, index: number) => {
        this.filteredBanks[index].next(this.fieldsList.slice());
      });
    });
  }

  getPielineModelFields() {
    this.service.getTemplateCard(this.selectedPipeline).subscribe((resp: any) => {
      this.fieldsList = resp.data[0]?.pipeline_template_field.filter((item: any) => { return item.is_active && item.data_type !== 'MULTI_SELECT_DROPDOWN' && item.data_type !== 'SELECT_DROPDOWN' && item.data_type !== 'RADIO_BUTTON' && item.data_type !== 'FILE'; });
      const static_fields = [
        { label: 'first_name', data_type: 'Text' },
        { label: 'email', data_type: 'Text' },
        { label: 'ccode', data_type: 'Text' },
        { label: 'phone_number', data_type: 'Text' }
      ];
      this.fieldsList.push(...static_fields);
      Object.entries(resp.data[0]).forEach((field: any) => {
        if (field[0] !== 'id' && field[0] !== 'pipeline_template_field' && field[0] !== 'card_fields_order' && field[0] !== 'organisation'
          && field[0] !== 'owner' && field[0] !== 'channel' && field[0] !== 'tags' && field[0] !== 'assignee' && field[0] !== 'company'
          && field[0] !== 'contact' && field[0] !== 'pipeline' && field[0] !== 'stage') {
          if ((field[1].on_form || field[1].is_inner_screen) && field[1].data_type !== 'MULTI_SELECT_DROPDOWN' && field[1].data_type !== 'SELECT_DROPDOWN' && field[1].data_type !== 'RADIO_BUTTON' && field[1].data_type !== 'FILE') this.fieldsList.push({ label: field[0], data_type: field[1].data_type });
        }
      });
      this.field_data.forEach((data: any, index: number) => {
        this.filteredBanks[index].next(this.fieldsList.slice());
      });
    });
  }

  getUploadFileData(data: any) {
    this.service.getCSVFile(this.imported_id).subscribe((resp: any) => {
      this.field_data = resp.data.sample_data;
      this.file_name = resp.data.file_name;
      resp.data.sample_data.forEach((element: any, index: number) => {
        this.addFormItem(element);
        this.filteredBanks.push(new ReplaySubject<any>(index));
      });
      if (data.module === 'contact') {
        this.getContactModelFields();
      } else if (data.module === 'company') {
        this.getCompanyModelFields();
      } else if (data.module === 'enquiry') {
        this.getEnquiryModelFields();
      } else if (data.module === 'pipeline') {
        this.selectedPipeline = data['pipeline_id'];
        this.selectedStage = data['stage'];
        this.getPielineModelFields();
      }
    });
  }

  onChange(ev: any, index: number, field: any) {
    console.log(ev.value, "ev", this.FormItems.at(index).get('mapped_field'));
    this.FormItems.at(index).get('mapped_field')?.setValue(ev.value);
    const alreadyMappedFiled: any = [];
    this.FormItems.value.filter((e: any) => {
      if (ev.value && e.mapped_field.includes(ev.value)) {
        alreadyMappedFiled.push(e);
      }
    });

    if (alreadyMappedFiled.length > 1) {
      this.confirmationMappedFields(alreadyMappedFiled, ev.value, field).then((data) => {
        if (data.value) {
          alreadyMappedFiled.forEach((element: any) => {
            if (element.table_header !== field.label) {
              this.FormItems.value.filter((e: any, i: number) => {
                if (e.table_header === element.table_header) {
                  // this.FormItems.at(i).get('mapped_field')?.setValue('');
                  this.FormItems.at(i).patchValue({ mapped_field: '', replace_text: '' });
                }
              });
            }
          });
          this.FormItems.at(index).patchValue({ mapped_field: ev.value });
        } else {
          this.FormItems.at(index).get('mapped_field')?.setValue('');
        }
      });
    } else {
      this.FormItems.at(index).patchValue({ mapped_field: ev.value });
    }
  }

  onSubmit() {
    if (this.mappingForm.invalid) return;
    const hasEmailAndFirstname: any = {};
    if (this.showType === 'company') {
      for (const field of this.FormItems.value) {
        if (field.mapped_field === "name") {
          hasEmailAndFirstname[field.mapped_field] = true;
        }
      }
      if (!(hasEmailAndFirstname.name)) {
        this.toastr.error("Mandatory fields are not mapped");
        return;
      }
    }
    else {
      for (const field of this.FormItems.value) {
        if (field.mapped_field === "email") {
          hasEmailAndFirstname[field.mapped_field] = true;
        }
        if (field.mapped_field === "first_name") {
          hasEmailAndFirstname[field.mapped_field] = true;
        }
      }
      if (!(hasEmailAndFirstname.email && hasEmailAndFirstname.first_name)) {
        this.toastr.error("Mandatory fields are not mapped");
        return;
      }
    }
    // this.FormItems.value.forEach((element: any) => {
    //   if(element.date_type === "RADIO_BUTTON" || element.date_type === "NUMBER"){
    //     const filerValue = this.fieldsList.find((ele: any)=> ele.label.includes(element.mapped_field));
    //     if(filerValue.data_type !== element.date_type) {
    //       console.log(filerValue,"value");
    //       console.log("selected th different date type");
    //     }
    //   }
    // });
    let body;
    if (this.showType === 'pipeline') {
      body = {
        pipeline: this.selectedPipeline,
        stage: this.selectedStage,
        ...this.mappingForm.value
      };
    }
    this.service.mappingCSVFile(this.showType === 'pipeline' ? body : this.mappingForm.value).subscribe(() => {
      if (this.showType === 'contact') {
        this.route.navigate(['crm/contacts']);
      } else if (this.showType === 'enquiry') {
        this.route.navigate(['crm/leads']);
      } else if (this.showType === 'pipeline') {
        this.route.navigate(['pipelines/deals']);
      } else if (this.showType === 'company') {
        this.route.navigate(['crm/companies']);
      }
    });
  }
  onIndexChange(index: any) {
    this.field_index = index;
  }

  protected filterBanks() {
    if (!this.fieldsList) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks[this.field_index].next(this.fieldsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks[this.field_index].next(
      this.fieldsList.filter(bank => {
        return (bank.label.toLowerCase().indexOf(search) > -1) ||
          bank.label.toLowerCase().indexOf(search) > -1;
      }
      )
    );
  }

  confirmationMappedFields(field: any, newfieldValue: any, newfield: any) {
    console.log(field, newfield, "text");

    return Swal.fire({
      title: "Map to Another Field",
      html: `<b>${newfieldValue}</b> has already been mapped with <b>${field.table_header}</b> from file. By mapping it with <b>${newfield.label}</b> this will be overridden.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: "Map"
    });
  }

  getFeatues() {
    this.authservice.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }
  //Some of the fields you have selected do not have correctly chosen data formats. Please review and correct the data formats, and try again.
}
