<div *ngIf="loggedInUser.data.role === 'ADMIN'" class="main-container">
    <div class="card pt-2 px-3">
        <div class="d-flex justify-content-between">
            <div class="d-inline-flex text-wrap">
                <nav mat-tab-nav-bar [backgroundColor]="background" [tabPanel]="tabPanel" mat-stretch-tabs="false"
                    mat-align-tabs="start" animationDuration="0ms">
                    <a mat-tab-link *ngFor="let link of links" (click)="onTabChange(link.label)"
                        [active]="activeLink === link.label" class="text-primary">
                        <span *ngIf="link.label !== 'Pipeline'">{{link.label}}</span>
                        <span *ngIf="link.label === 'Pipeline'">Flow</span>
                    </a>
                </nav>
            </div>
            <div class="mt-1">
                <button class="btn btn-primary btn-sm px-4" (click)="openModel(customFieldModal)">
                    + Add
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column" *ngIf="activeLink === 'Pipeline'">
        <label class="fw-600 mb-2 text-primary">Flow</label>
        <mat-form-field appearance="outline" class="w-40">
            <mat-select [(ngModel)]="selected_pipeline" (selectionChange)="onPipelineChange($event)">
                <mat-option *ngFor="let item of pipeline_list" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-tab-nav-panel #tabPanel>
        <div class="card p-2">
            <div class="mt-3">
                <h5 class="fw-600 px-2 mb-3">
                    Custom Fields
                </h5>
            </div>
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="5"
                [fromLocation]="'CUSTOM'"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader" class="table align-middle mb-0 file-table w-100">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            S.No
                        </th>
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Field Name
                        </th>
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Variable Name
                        </th>
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Field Input Type
                        </th>
                        <!-- <th scope="col" class="table-sticky-header bg-white z-index-max">
                            Validation
                        </th> -->
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    <tr class="cursor-move" *ngFor="let item of customFieldsList; let i = index" cdkDrag>
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            {{item.label}}
                        </td>

                        <td>
                            {{item.original_label}}
                        </td>
                        <td>
                            {{item.data_type.replace("_", "")}}
                        </td>
                        <!-- <td>
                            {{item.validation}}
                        </td> -->
                        <td>
                            <div class="mt-2">
                                <mat-icon class="font-size-18 text-primary" matTooltip="Form"
                                    *ngIf="item.on_form">description</mat-icon>
                                <mat-icon class="font-size-18 text-primary" matTooltip="Inner Screen"
                                    *ngIf="item.is_inner_screen">format_textdirection_l_to_r</mat-icon>
                                <mat-icon class="font-size-18 text-primary" matTooltip="Required"
                                    *ngIf="item.is_mandatory">star</mat-icon>
                                <mat-icon class="font-size-18 text-primary" matTooltip="Client Visibility"
                                    *ngIf="item.is_client_visible">visibility</mat-icon>
                                <mat-icon class="font-size-18 text-primary cursor"
                                    (click)="openModel(customFieldModal, item,'customfield')">edit</mat-icon>
                                <mat-icon class="font-size-18 text-danger cursor"
                                    (click)="deleteCustomField(item.id)">delete</mat-icon>
                            </div>

                        </td>
                        <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                            {{item.label}}
                        </div>
                    </tr>
                </tbody>
            </table>
            <mat-paginator [length]="1" [pageSize]="1" aria-label="Select page" hidePageSize="true"
                showFirstLastButtons>
            </mat-paginator>
        </div>
        <div *ngIf="showErrorMessage" style="width: 700px;">
            <div>
                <p class="pb-2 font-size-13 "> <span class="text-danger font-size-14 fw-500">NOTE : </span> You are not Authorised to view or use
                    <ng-container *ngFor="let fields of revokedSysFieldsList | keyvalue; let i = index">
                        <span class="text-danger  fw-600" *ngIf="fields.value.is_visible">{{ fields.key.replace("_", " ") | titlecase }}{{ i < (revokedSysFieldsList | keyvalue).length - 1 ? ', ' : '' }}</span>
                    </ng-container>
                    feature. Please remove them as required field from System field table.
                </p>
            </div>
        </div>
        <div class="card p-2">
            <div class="mt-3">
                <h5 class="fw-600 px-2 mb-3">
                    System Fields
                </h5>
            </div>
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="5"
                [fromLocation]="'CUSTOM'"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader" class="table align-middle mb-0 file-table w-100">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            S.No
                        </th>
                        <th scope="col" class="table-sticky-header bg-white font-size-16 z-index-max text-primary">
                            Field Name
                        </th>
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Variable Name
                        </th>
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Field Input Type
                        </th>
                        <!-- <th scope="col" class="table-sticky-header bg-white z-index-max">
                            Validation
                        </th> -->
                        <th scope="col" class="table-sticky-header bg-white z-index-max text-primary">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let field of systemFields; let i = index">
                        <tr *ngIf="field[0] !== 'id' 
                            && field[0] !== 'custom_contact_templates' 
                            && field[0] !== 'custom_lead_templates'
                            && field[0] !== 'custom_company_templates'
                            && field[0] !== 'custom_project_templates'
                            && field[0] !== 'custom_client_templates'
                            && field[0] !== 'client_fields_order'
                            && field[0] !== 'project_fields_order'
                            && field[0] !== 'pipeline_template_field'
                            && field[0] !== 'contact_fields_order'
                            && field[0] !== 'company_fields_order'
                            && field[0] !== 'lead_fields_order'
                            && field[0] !== 'card_fields_order'
                            && field[0] !== 'organisation' 
                            ">
                            <ng-container *ngIf="(field[0] === 'company' && featuresData['create_company'] && featuresData['company']) || field[0] !== 'company'">
                                <ng-container
                                    *ngIf="featuresData  && ((field[1]?.is_mandatory && revokedSysFieldsList[field[0]] && revokedSysFieldsList[field[0]].is_visible) || !(revokedSysFieldsList && revokedSysFieldsList[field[0]]))">
                                    <td [ngStyle]="{'color':revokedSysFieldsList[field[0]] && revokedSysFieldsList[field[0]].is_visible ? 'red' : ''}">
                                        {{i-1}}
                                    </td>
                                    <td [ngStyle]="{'color':revokedSysFieldsList[field[0]] && revokedSysFieldsList[field[0]].is_visible ? 'red' : ''}">
                                        {{field[0].replace("_", " ") | titlecase}}
                                    </td>
                                    <td [ngStyle]="{'color':revokedSysFieldsList[field[0]] && revokedSysFieldsList[field[0]].is_visible ? 'red' : ''}">
                                        {{field[0]}}
                                    </td>
                                    <td [ngStyle]="{'color':revokedSysFieldsList[field[0]] && revokedSysFieldsList[field[0]].is_visible ? 'red' : ''}">
                                        {{field[1]?.data_type}}
                                    </td>
                                    <!-- <td>
                                    DEFAULT
                                </td> -->
                                    <td>
                                        <div class="mt-2 ms-3 font-size-18" *ngIf="!field[1]?.is_default">
                                            &nbsp;
                                            <mat-icon class="font-size-18 text-primary" matTooltip="Form"
                                                *ngIf="field[1].on_form">description</mat-icon>
                                            <mat-icon class="font-size-18 text-primary" matTooltip="Inner Screen"
                                                *ngIf="field[1].is_inner_screen">format_textdirection_l_to_r</mat-icon>
                                            <mat-icon class="font-size-18 text-primary" matTooltip="Required"
                                                *ngIf="field[1].is_mandatory">star</mat-icon>
                                            <mat-icon class="font-size-18 text-primary" matTooltip="Client Visibility"
                                                *ngIf="field[1].is_client_visible">visibility</mat-icon>
                                            <mat-icon class="font-size-18 text-primary cursor"
                                                (click)="openModel(systemFieldModal,field,'systemfield')">edit</mat-icon>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <mat-paginator [length]="1" [pageSize]="1" aria-label="Select page" hidePageSize="true"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-tab-nav-panel>

</div>
<ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
    <div class="d-flex align-items-center justify-content-center">
        <h6 class="text-secondary">You are not authorized to view this.</h6>
    </div>
</ng-container>

<ng-template #customFieldModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-dark fw-500" id="customFieldModal">
            Custom field value for {{activeLink}}
        </h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="custom_form">
            <div class="form-group">
                <label for="" class="form-label">Field Name <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input type="text" style="outline: none" matInput class="form-control"
                        placeholder="Enter Field Name" formControlName="label" (input)="onLabelChange($event)">
                    <mat-error class="error text-danger d-flex" *ngIf="submited && f['label'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto info">info</mat-icon>
                        <span *ngIf="f['label'].errors['required']"> Field Name
                            {{errorjson.required}}</span>
                        <span *ngIf="f['label'].errors['pattern']">Invalid Field name</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group mt-3">
                <label for="" class="form-label">Variable Name <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input type="text" style="outline: none" matInput class="form-control"
                        placeholder="Enter Variable Name" formControlName="original_label">
                    <mat-error class="error text-danger d-flex" *ngIf="submited && f['original_label'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto info">info</mat-icon>
                        <span *ngIf="f['original_label'].errors['required']"> Variable Name
                            {{errorjson.required}}</span>
                        <span *ngIf="f['original_label'].errors['pattern']">Invalid Variable name</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group mt-3">
                <label for="" class="form-label">Field Type <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="me-2 w-100">
                    <mat-select name="" id="" formControlName="data_type"
                        [class.disabled]="custom_form.value?.id !== ''" placeholder="Select Field Type">
                        <mat-option value="TEXT">TEXT</mat-option>
                        <mat-option value="TEXT_AREA">TEXTAREA</mat-option>
                        <!-- <option value="CHECK_BOX">CHECKBOX</mat-option> -->
                        <mat-option value="SELECT_DROPDOWN">SELECT DROPDOWN</mat-option>
                        <mat-option value="RADIO_BUTTON">RADIO BUTTON</mat-option>
                        <mat-option value="FILE">FILE UPLOAD</mat-option>
                        <!-- <option value="TOGGLE_BUTTON">TOGGLE BUTTON</option> -->
                        <mat-option value="NUMBER">NUMBER</mat-option>
                        <mat-option value="DATE">DATE</mat-option>
                        <mat-option value="DATE_TIME">DATE TIME</mat-option>
                        <mat-option value="DATE_RANGE">DATE RANGE</mat-option>
                        <!-- <option value="DATE_TIME_RANGE">DATE TIME RANGE</option> -->
                        <mat-option value="EMAIL">EMAIL</mat-option>
                        <mat-option value="PHONE_NUMBER">PHONE NUMBER</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submited && f['data_type'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['data_type'].errors['required']">Field Type {{errorjson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group mt-3" *ngIf="custom_form.value.data_type?.includes('CHECK_BOX') 
                    || custom_form.value.data_type?.includes('SELECT') 
                    || custom_form.value.data_type?.includes('RADIO_BUTTON')">
                <label for="" class="form-label">Options <span class="text-danger">*</span></label>
                <ng-select class="form-control" [addTag]="true" formControlName="options" [multiple]="true"
                    placeholder="Options"></ng-select>
                <div *ngIf="submited && f['options'].errors"
                    class="invalid-feedback text-danger d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center me-0 info">info</mat-icon>
                    <div class="font-size-12" *ngIf="f['options'].errors['required']">
                        Options {{errorjson.required}}</div>
                </div>
            </div>
            <!-- <div class="form-group mt-3" *ngIf="!(custom_form.value.data_type.includes('CHECK_BOX') 
                || custom_form.value.data_type.includes('SELECT') 
                || custom_form.value.data_type.includes('RADIO_BUTTON')
                || custom_form.value.data_type.includes('TOGGLE_BUTTON')
                || custom_form.value.data_type.includes('DATE')
                || custom_form.value.data_type.includes('DATE_TIME')
                || custom_form.value.data_type.includes('DATE_RANGE')
                || custom_form.value.data_type.includes('DATE_TIME_RANGE'))">
                <label for="" class="form-label">Validations</label>
                <select name="" id="" class="form-select" formControlName="validation">
                    <option value="NONE">NONE</option>
                    <option value="URL_VALIDATION">URL VALIDATION</option>
                    <option value="PHONE_VALIDATION">PHONE VALIDATION</option>
                    <option value="NUMBER_VALIDATION">NUMBER VALIDATION</option>
                    <option value="EMAIL_VALIDATION">EMAIL VALIDATION</option>
                    <option value="PIN_CODE_VALIDATION">PIN CODE VALIDATION</option>
                </select>
            </div> -->
            <div class="mt-3 ms-2 justify-content-around">
                <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        (change)="onCheckBoxChange($event)" formControlName="is_mandatory" />
                    <span class="ms-1 mb-2">
                        Required
                    </span>
                </div>
                <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="on_form" (change)="onCheckBoxFormChange($event)" />
                    <span class="ms-1 mb-2">
                        Add field in {{activeLink}} form
                    </span>
                </div>
                <div class="d-flex align-items-center" *ngIf="activeLink !== 'Pipeline'">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_inner_screen" />
                    <span class="ms-1 mb-2">
                        Add field in inner screen {{activeLink}} form
                    </span>
                </div>
                <!-- <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="on_dashboard" />
                    <span class="ms-1 mb-2">
                        Show on dashboard <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div> -->
            </div>
            <div class="mt-3 ms-2 justify-content-around" *ngIf="activeLink === 'Contact'">
                <div class="d-flex align-items-center">
                    <label class="fw-600 font-size-14 me-2">Client Visibility</label>
                    <label class="switch">
                        <input type="checkbox" formControlName="is_client_visible">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div>
            <button class="btn btn-secondry" (click)="modalService.dismissAll()">Cancel</button>
            <button class="btn btn-primary ms-2" (click)="onCustomFieldSubmit()">Save</button>
        </div>
    </div>

</ng-template>

<ng-template #systemFieldModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-dark fw-500" id="systemFieldModal">
            System field value for {{activeLink}}
        </h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="system_form">
            <div class="mt-3 ms-2 justify-content-around">
                <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_mandatory" (change)="onCheckBoxChange($event)" />
                    <span class="ms-1 mb-2">
                        Required <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div>
                <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="on_form" (change)="onCheckBoxFormChange($event,'systemfield')" />
                    <span class="ms-1 mb-2">
                        Add field in {{activeLink}} form <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div>
                <div class="d-flex align-items-center" *ngIf="activeLink !== 'Pipeline'">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_inner_screen" (change)="onCheckBoxInnerFormChange($event,'systemfield')" />
                    <span class="ms-1 mb-2">
                        Add field in inner screen {{activeLink}} form
                    </span>
                </div>
                <div class="d-flex align-items-center" *ngIf="activeLink === 'Contact'">
                    <label class="fw-600 font-size-14 me-2">Client Visibility</label>
                    <label class="switch">
                        <input type="checkbox" formControlName="is_client_visible">
                        <span class="slider round"></span>
                    </label>
                </div>
                <!-- <div class="d-flex align-items-center">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="on_dashboard" />
                    <span class="ms-1 mb-2">
                        Show on dashboard <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div> -->
            </div>
        </form>
    </div>
    <div class="modal-footer">

        <div>
            <button type="button" class="btn btn-secondry" (click)="modalService.dismissAll()">Cancel</button>
            <button class="btn btn-primary ms-2" (click)="onSystemFieldSubmit()">Save</button>
        </div>
    </div>

</ng-template>