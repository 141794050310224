<div class="container-fluid overflow-auto">
    <div class="row px-2 profile-div-height pb-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <h6 class="fw-600">Select subscription billing cycle</h6>
            <h6 class="font-size-14">Save 60% with an annual billing cycle</h6>
            <div class="row mt-4">
                <div class="col-md-4" *ngFor="let item of manage_plans">
                    <div class="card h-100vh h-100">
                        <div class="p-3">
                            <h5 class="fw-600">{{item.name}}</h5>
                            <p class="text-secondary">{{item.short_description}}</p>
                            <div class="d-flex mb-4">
                                <label class="font-size-24">{{item.currency_symbol}} {{item.price}} <span
                                        class="font-size-14 text-secondary">/ Lifetime fee</span></label>
                            </div>
                            <button class="btn w-100 dotted-border-1" type="button" *ngIf="item.id === currentPlan">
                                Current Plan
                            </button>
                            <button class="btn w-100 btn-primary" type="button" *ngIf="item.id !== currentPlan"
                                (click)="subscriptionSubmit(item.id,item.plan_type)">
                                Subscribe
                            </button>
                        </div>
                        <hr>
                        <div class="p-3 pt-0">
                            <label class="fw-600 mb-2">Features</label>
                            <div class="d-flex align-items-center mt-2" *ngFor="let feature of item.plan_features">
                                <mat-icon
                                    class="me-2 font-size-16 d-flex align-items-center justify-content-center">check</mat-icon>
                                <label>{{feature.feature_text}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>