import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  activeId = 1;
  tabs_menu = [
    {
      title: "Profile Settings",
      slug: "design",
      route: "design",
      icon: "mib",
      id: 1
    },
    // {
    //   title: "Settings",
    //   slug: "calendar_settings",
    //   route: "settings",
    //   icon: "mib",
    //   id: 2
    // },
    {
      title: "Schedule",
      slug: "schedule",
      route: "schedule",
      icon: "mib",
      id: 3
    },
    {
      title: "Meeting types",
      slug: "meeting_types",
      route: "meeting-type",
      icon: "mib",
      id: 4
    },
    {
      title: "Share",//and embed
      slug: "share",
      route: "share",
      icon: "mib",
      id: 5
    }
  ];

  constructor(private router: Router,private setupService: SetUpService) {
    // this.tabs_menu.forEach((item: any) => {
    //   if (this.router.url.includes(item.route)) this.activeId = item.id;
    // });

    this.router.navigate(['/calendar/design']);
  }

  // featureShow() {
  //   return this.setupService.checkFeature('meetings');
  // }
}
