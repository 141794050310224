import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  modalRef!: NgbModalRef;
  zoom_form!: FormGroup;
  submitted = false;
  zoomKeys: any = {};
  outlookData: any;
  zoomSecretKey = '';
  notRegistered = false;
  googleConnected = false;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  activeId = 1;
  filtersLables = ["Name", "Verified", "Action"];
  domainsArray: any[] = [];
  mailsArray: any[] = [];
  domain: any;
  selectedDomain: any;
  domainTemplate = true;
  email_form!: FormGroup;
  isSubmitted = false;
  errorjson = ERRORJSON[0];
  search: any;
  unVerifiedMails = false;
  noDomainFound = false;
  domainForm!: FormGroup;
  domainSubmitted = false;
  googleCalendarConnected = false;
  staff_list: any;
  connectedGmail: any;
  connectedCalendarmail: any;

  constructor(private modelservice: NgbModal, private service: SettingsService, private confirmationMsz: ConfirmationMszService) { }

  ngOnInit(): void {
    // this.getKeys();
    // this.initilize_zoom_form();
    this.getOutlook();
    this.getGoogleGmail();
    this.getGoogleCalendar();
  }

  initilize_zoom_form() {
    this.zoom_form = new FormGroup({
      id: new FormControl(''),
      api_key: new FormControl('', Validators.required),
      secret_key: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.zoom_form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.zoom_form.valid) {
      this.modalRef.close();
      if (this.zoom_form.value.id) {
        this.service.updateZoomKeys(this.zoom_form.value).subscribe(() => {
          this.getKeys();
          this.zoom_form.reset();
        });
      } else {
        this.service.postZoomKeys(this.zoom_form.value).subscribe(() => {
          this.getKeys();
          this.zoom_form.reset();
        });
      }
    }
  }

  getKeys() {
    this.service.getZoomKeys().subscribe({
      next: (resp: any) => {
        this.zoomKeys = resp.data;
        if (Object.keys(this.zoomKeys).length !== 0) {
          this.zoomSecretKey = this.zoomKeys.secret_key;
          this.zoom_form.patchValue({ id: this.zoomKeys.id, api_key: this.zoomKeys.api_key });
        }
      }
    });
  }

  openZoomModal(modal: any) {
    console.log(this.zoom_form.value);
    this.modalRef = this.modelservice.open(modal, { size: 'md' });
  }

  zoomDelete() {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to disconnect!', button: 'Yes Disconnect!' }).then((result) => {
      if (result.value) {
        this.service.deleteZoomKeys(this.zoom_form.value.id).subscribe(() => {
          this.getKeys();
          this.initilize_zoom_form();
        });
      }
    });
  }

  onDeleteDomain(event: any, domainId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteDomain(domainId);
      }
    });
    event.stopPropagation();
  }

  deleteDomain(domainId: string) {
    this.service.deleteDomain(domainId).subscribe(() => {
      this.getDomains();
    });
  }

  getGoogleGmail() {
    this.service.getGoogle('gmail').subscribe((resp: any) => {
      if (resp.message === 'Staff Connected to Google Gmail') {
        this.connectedGmail = resp.data[0].email;
        this.googleConnected = true;
      } else if (resp.message === 'Staff Not Registered') {
        this.googleConnected = false;
        // this.notRegistered = true;
      } else {
        this.googleConnected = false;
      }
    });
  }
  getGoogleCalendar() {
    this.service.getGoogle('calendar').subscribe((resp: any) => {
      if (resp.message === 'Staff Connected to Google Calendar') {
        this.connectedCalendarmail = resp.data[0].email;
        this.googleCalendarConnected = true;
      } else {
        this.googleCalendarConnected = false;
      }
    });
  }

  // googleRegister() {
  //   this.service.googleRegister().subscribe(() => {
  //     this.getGoogle();
  //     this.authorizeTogmail();
  //   });
  // }

  connectTogmail() {
    // if (this.notRegistered) {
    //   this.googleRegister();
    // } else {
    //   this.authorizeTogmail();
    // }
    this.service.get_gmail_connect().subscribe((resp: any) => {
      window.open(resp.data, "_self");
    });
  }

  connectToCalender() {
    this.service.get_calendar_connect().subscribe((resp: any) => {
      window.open(resp.data, "_self");
    });
  }

  authorizeTogmail() {
    this.service.connectTogmail().subscribe((resp: any) => {
      window.open(resp.data, "_self");
    });
  }

  disconnectgoogler(type: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.service.disconnectToGoogler(type).subscribe(() => {
          if (type === 'gmail') this.getGoogleGmail(); else this.getGoogleCalendar();
        });
      }
    });
  }

  getOutlook() {
    this.service.getOutlook().subscribe((resp: any) => {
      this.outlookData = resp.data;
    });
  }

  connecttoOutlook(type: string) {
    this.service.connectToOutlook(type).subscribe((resp: any) => {
      if (type === 'connect') {
        window.open(resp.data.url, "_self");
      } else {
        this.getOutlook();
      }
    });
  }

  disconnectfromOutlook() {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to disconnect!', button: 'Yes Disconnect!' }).then((result) => {
      if (result.value) {
        this.connecttoOutlook('disconnect');
        this.getGoogleGmail();
      }
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 2) {
      this.getDomains();
    }
  }

  addDomain(modal: any) {
    this.domainSubmitted = false;
    this.initializeDomainForms();
    this.modelservice.open(modal);
  }

  submitDomain() {
    this.domainSubmitted = true;
    if (this.domainForm.valid) {
      this.service.postDomains(this.domainForm.value).subscribe(() => {
        this.modelservice.dismissAll();
        this.getDomains();
      });
    }
  }

  getDomains() {
    this.service.getDomains().subscribe((domain: any) => {
      this.domainsArray = domain.data;
      if (this.domainsArray.length > 0) {
        this.noDomainFound = false;
      } else {
        this.noDomainFound = true;
      }
      const unverifiedDomain = this.domainsArray.find((elem: any) => elem.status === "unverified");
      if (unverifiedDomain) {
        this.unVerifiedMails = true;
      } else {
        this.unVerifiedMails = false;
      }
    });
  }

  verifyDomains(domainId: string) {
    this.service.verifyDomains(domainId).subscribe(() => {
      this.getDomains();
    });
  }

  openInstructions(modal: any, selectedItem: any) {
    this.selectedDomain = selectedItem;
    this.modelservice.open(modal, { size: 'lg', scrollable: true });
  }

  openDomainEmails(domain: any) {
    if (domain.status !== 'unverified') {
      this.selectedDomain = domain;
      this.domainTemplate = false;
      this.getMailsBasedOnDomain();
    }
  }

  domainTemplateChange(type: boolean) {
    this.domainTemplate = type;
  }

  addEmailsToDomain(modal: any) {
    this.organisationMembersList();
    this.initializeEmailForms();
    this.modelservice.open(modal);
  }

  initializeEmailForms() {
    this.email_form = new FormGroup({
      domain: new FormControl(this.selectedDomain?.id, Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9.+-\s]{1,}$/)]),
      name: new FormControl('', Validators.required),
      map_staff_email: new FormControl('', Validators.required),
      email_extension: new FormControl('@' + this.selectedDomain?.domain, Validators.required)
    });
    this.email_form.controls['email_extension'].disable();
  }


  initializeDomainForms() {
    this.domainForm = new FormGroup({
      domain: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9]+(\.[A-Za-z0-9]+)+$/)])
    });
  }

  get dForm() {
    return this.domainForm.controls;
  }
  get emailForm() {
    return this.email_form.controls;
  }

  addEmails() {
    this.isSubmitted = true;
    this.email_form.markAllAsTouched();
    if (this.email_form.valid) {
      this.service.postmails({ ...this.email_form.value, email: this.email_form.value.email + '@' + this.selectedDomain?.domain }).subscribe(() => {
        this.modelservice.dismissAll();
        this.getMailsBasedOnDomain();
      });
    }
  }

  getMailsBasedOnDomain() {
    this.service.getmails(this.selectedDomain?.id).subscribe((resp: any) => {
      this.mailsArray = resp.data;
    });
  }

  searchName(e: any) {
    if (e.length >= 3) {
      this.mailsArray = [];
      this.service.getSearchedMails(this.selectedDomain?.id, this.search).subscribe((resp: any) => {
        this.mailsArray = resp.data;
      });
    }
    else if (e.length === 0) {
      this.getMailsBasedOnDomain();
    }
  }

  organisationMembersList() {
    this.service.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staff_list = resp?.data;
      }
    });
  }
}
