import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnDestroy {
  @ViewChild('iframeRefconv', { static: true }) iframeRefconv!: ElementRef;
  iframeLoaded = false;
  iframe: any;
  private subscriptions: Subscription;
  chatNavObj: any;
  private receiveMessage: ((event: any) => void);
  src: any;

  constructor(private spinner: NgxSpinnerService, private router: Router, private chatServices: ChatService, private sanitizer: DomSanitizer) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://messengers.brexa.ai/conversations' :  window.location.hostname.includes('demo.brexa.ai') ? 'https://demomessenger.brexa.ai/conversations' : 'https://stagemessenger.brexa.ai/conversations');
    console.log(window.location.hostname);
    this.chatServices.clearAllMessagesSub$.next({ type: 'clear', id: '' });
    this.spinner.show();
    this.subscriptions = this.chatServices.chatRedirectUrlSubs$.subscribe((data) => {
      this.chatNavObj = data;
      if (this.router.routerState.snapshot.url === '/conversation') {
        this.postMessageIFrame('updateParams');
      }
    });
    if (this.router.getCurrentNavigation()?.extras?.state?.['routerState']) {
      this.chatNavObj = this.router.getCurrentNavigation()?.extras?.state?.['routerState'];
    }
    this.receiveMessage = (event: any) => {
      //   console.log(event,"event: ");
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl" && event.data.url !== "/") {
        sessionStorage.setItem('redirectConUrl', event.data.url);
        this.chatServices.updateCountsSub$.next('');
      }
    };
  }

  ngOnInit(): void {
    // Parent page
    this.iframe = document.getElementById('chatconv-widget-value');

    // function receiveMessage(event: any) {
    //   console.log(event,"event: ");

    //   const sessionValues = {
    //     ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
    //   };
    //   const iframe: any = document.getElementById('chatconv-widget-value');
    //   if (event.data.message === "auth") {
    //     iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
    //   } else if (event.data.message === "updateUrl" && event.data.url !== "/") {
    //     sessionStorage.setItem('redirectConUrl', event.data.url);
    //   }
    // }
    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRefconv.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterViewInit() {
    let url = sessionStorage.getItem('redirectConUrl');
    this.iframe.onload = () => {
      if (this.chatNavObj?.redirectUrl && this.chatNavObj?.redirectUrl !== "") {
        url = this.chatNavObj?.redirectUrl;
      }
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };

  }

  async postMessageIFrame(type: string) {
    if (type !== 'initilized') {
      this.iframe && this.iframe?.contentWindow.postMessage({ data: this.chatNavObj, type: "redirect" }, '*');
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    sessionStorage.removeItem('redirectConUrl');
    this.subscriptions.unsubscribe();
    this.chatServices.updateCountsSub$.next('');

  }
}
