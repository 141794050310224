<div class="container-fluid  p-0 bg-white" *ngIf="featuresData?.company">
  <div class="d-flex align-items-center new-design-padding justify-content-end bg-white mb-2"
    style="box-shadow: 0px 0px 24px -8px rgba(0, 0, 0, 0.13);border-bottom: 1px solid #E1E1E1;">
    <div class="right-controls-sec d-flex p-1 pb-2 align-items-center auto-hide-scroll-bar bg-white customised-sec">
      <div class="d-flex p-0 align-items-center height-max-content">
        <mat-form-field appearance="outline"
          class="margin-right-14 main-filter position-relative mat-hint-none main-filter"
          style="max-width: 150px;min-width: auto;">
          <mat-select placeholder="{{ companiesJson.heading_label }}" (ngModelChange)="onFilterChange($event)"
            [(ngModel)]="filter">
            <mat-option class="font-size-12" [value]="list.value" *ngFor="let list of companiesdropDown">{{
              list.label
              }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
          style="width: 212px;">
          <input style="outline: none" class="font-size-16" id="search-box" matInput placeholder="Search Here..."
            [(ngModel)]="search_global" (ngModelChange)="searchSubject.next(search_global)" />
          <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
              alt=""></mat-icon>
        </mat-form-field>
        <div matTooltip="Sort By" class="btn custom-filter-btn margin-right-14 d-flex align-items-center font-size-16"
          (click)="openFiltersModal('sort')">
          <img src="assets/images/sort_logo.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Sort By</span> -->
        </div>
        <div matTooltip="Filter" class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="openFiltersModal('filters')">
          <img src="assets/images/filter_log.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Filter By</span> -->
        </div>
        <button class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="clearCustomFilter()" *ngIf="selectedSortOptions || column_filters.length > 0"
          [disabled]="isSelected.multiple">
          <span class="limit-line-length-1">Clear filters</span>

        </button>
        <div matTooltip="Export" class=" btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
          (click)="downloadCompanies()">
          <img src="assets/images/newUI/CRM/export_icon.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Export</span> -->
        </div>
        <div *ngIf="featuresData?.create_company" matTooltip="Import"
          (click)="importModalDialog(importExportModel,'import')"
          class=" btn custom-filter-btn d-flex align-items-center font-size-16 cursor margin-right-14">
          <img src="assets/images/newUI/CRM/import.svg" alt="">
          <!-- <span class="ms-2 limit-line-length-1">Import</span> -->
        </div>
        <div matTooltip="Uploaded Files" [routerLink]="['/crm/import-files']" [queryParams]="{type: 'company'}"
          class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14 cursor">
          <img src="assets/images/newUI/CRM/uploaded_files.svg" width="20" alt="">

        </div>

        <div class=" btn custom-filter-btn px-2 margin-right-14 d-flex align-items-center contacts-filter-lable">
          <!-- <span class="me-3 " (click)="checkedContacts()">{{contactsJson.Filter_label}}</span> -->
          <span mat-raised-button class="menuTrigger  material-icons cursor" [matMenuTriggerFor]="filter_search_menu"
            matTooltip="Table Columns">filter_list</span>

          <mat-menu #filter_search_menu="matMenu" class="tableColumnLists customised-sec">
            <mat-form-field (click)="$event.stopPropagation()" appearance="outline" id="inputEle"
              class="px-3 py-2 mat-hint-none searching-box">
              <input (keyup)="onColumnFilterSearch($event)" [(ngModel)]="columnFilterSearch" style="outline: none"
                matInput placeholder="Search Column" />
              <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                  alt=""></mat-icon>
            </mat-form-field>
            <div class="list-sec" (click)="$event.stopPropagation()">
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div cdkDrag *ngFor="let item of tableColumns;let i = index">
                  <button mat-menu-item id="{{ i }}"
                    [disabled]="['company_id','name','tags'].includes(item?.field_name)"
                    (click)="$event.stopPropagation()"
                    *ngIf="item.field_name !== 'ccode' && item.field_name !== 'assignee'">
                    <mat-checkbox [checked]="item?.status" (change)="selectFilterLable($event,item,i)"
                      class="text-capitalize">{{(item?.field_name!.split("_").join(" ") | titlecase) ||
                      ''}}</mat-checkbox>
                  </button>
                </div>
              </div>
              <div *ngIf="tableColumnNoResFound" class="d-flex align-items-center justify-content-center"
                style="height:100%">
                No Result Found
              </div>
            </div>
            <div class="d-flex justify-content-end apply-btn-sec px-3">
              <button class="btn btn-primary d-flex align-items-center font-size-13" [disabled]="tableColumnNoResFound"
                (click)="!tableColumnNoResFound && onUpdateTableColumns()">Apply
                & Save</button>
            </div>
          </mat-menu>
        </div>
        <button class="btn btn-primary d-flex align-items-center margin-right-14 font-size-14"
          *ngIf="featuresData?.create_company" [disabled]="isSelected.multiple" (click)="openAddCompanyModal()">
          <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
          <span class="ms-1 limit-line-length-1 text-left">{{'Company'}}</span>
        </button>
        <button class="btn btn-primary d-flex align-items-center font-size-14 me-1"
          *ngIf="loggedInUser.data.role === 'ADMIN'" [disabled]="isSelected.multiple" (click)="openTaskBoardPreview()">
          <img src="assets/images/newUI/CRM/add-icon.svg" alt="">
          <span class="ms-1 limit-line-length-1 text-left">Stage</span>
        </button>
      </div>
    </div>
  </div>
  <div class="custom-table-div-crm-height d-flex flex-column new-design-padding justify-content-between company-screen">
    <div class="table-responsive table-height fixed-table">
      <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="7" [fromLocation]="'CRM'"></app-table-skeleton>
      <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100" *ngIf="!tableSkeletonLoader">
        <thead>
          <tr class="table-header-color">
            <th scope="col">
              <mat-checkbox (change)="$event ? masterToggle(currentPage) : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" class="mat_checkbox"></mat-checkbox>
            </th>
            <ng-container *ngFor="let col of originalTableColumnLists">
              <th *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')" scope="col"
                class="text-capitalize">
                {{col.field_name!.split("_").join(" ")}}</th>
            </ng-container>
            <th class="" style="min-width: 60px;">
              Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="!display_no_data">
          <tr class="cursor" *ngFor="let item of companiesListArray; let i = index" [routerLink]="['../crm-details']"
            [queryParams]="{ id: item.id, showtype: 'company'}">
            <td style="padding: 16px !important;">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(item) && individualRecordToogle(item) : null"
                [checked]="selection.isSelected(item)" class="mat_checkbox"></mat-checkbox>
            </td>
            <ng-container *ngFor="let col of originalTableColumnLists">
              <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                style="max-width: 200px;"
                [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '100px' : '150px'}">
                <div *ngIf="col.system_field">
                  <span *ngIf="col.field_name === 'phone_number'">{{item[col.field_name] ?
                    item.ccode :
                    ''}}&nbsp;{{item[col.field_name] || 'NA'}}</span>
                  <span class="limit-line-length-1" *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
                    || '-') + ' ' +
                    (item?.owner_info?.lname || '')}}</span>
                  <div *ngIf="col.field_name === 'tags'" class="d-flex">
                    <p class="text-name m-0" *ngIf="!item['tag_name']" style="width: -webkit-fill-available;">NA</p>
                    <p class="text-name text-wrap w-100 cursor" style="margin-bottom: 0px !important;">
                      <app-table-popover [inputType]="'dropdown'" [inputValue]="item?.tag_name" [formName]="'tags'"
                        [labelName]="'Tag'" [dropDownItems]="tagsListArray" [selectedItem]="item.tags"
                        (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </p>
                  </div>
                  <div *ngIf="col.field_name === 'channel'">
                    <p *ngIf="item['channel']" style="background-color: #FCEED8;color: #73321B;"
                      class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                      <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                        [style]="{'background-color': '#73321B'}"></span>
                      <span class="limit-line-length-1 word-break"> {{item['channel']}}</span>
                    </p>
                    <p class="text-name m-0" *ngIf="!item['channel']">NA</p>
                  </div>
                  <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
                    {{item.created_at ? (item.created_at | dateConversion) : 'NA'}}
                  </span>
                  <span class="limit-line-length-1" *ngIf="col.field_name === 'company'">{{item.company_name ||
                    'NA'}}</span>
                  <span class="limit-line-length-1"
                    *ngIf="col.field_name !== 'phone_number' && col.field_name !== 'name' && col.field_name !== 'tags' && col.field_name !== 'channel' && col.data_type !== 'DATE' && col.field_name !== 'ccode' && col.field_name !== 'owner' && col.field_name !== 'company'"
                    [innerHTML]="item[col.field_name]
          || 'NA'"></span>
                  <span *ngIf="col.field_name === 'name'">
                    <app-table-popover [inputType]="'text'" [inputValue]="item?.name" [labelName]="'Name'"
                      [formName]="'name'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                  </span>
                </div>
                <div *ngIf="!col.system_field">
                  <ng-container *ngFor="let customField of item?.custom_company_fields">
                    <ng-container *ngIf="customField.label === col.field_name">
                      <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
                        {{customField.data ? (customField.data | dateConversion) : 'NA'}}
                      </span>
                      <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
                        {{customField.data
                        | dateConversion }} {{ customField.data | date: 'h:mm a' || 'NA'}}
                      </span>
                      <span *ngIf="col.data_type === 'DATE_RANGE'">
                        <span class="limit-line-length-1"
                          *ngIf="customField.data && customField.data.split('&').length > 1">
                          {{customField?.data.split("&")[0].split("-").reverse().join("/") + ' - ' +
                          customField?.data.split("&")[1].split("-").reverse().join("/")}}
                        </span>
                        <span *ngIf="!customField.data">
                          NA
                        </span>
                      </span>
                      <span class="limit-line-length-1" *ngIf="col.data_type === 'PHONE_NUMBER'">
                        {{customField?.data?.custom_ccode ?
                        customField?.data?.custom_ccode :
                        ''}}&nbsp;{{customField?.data?.custom_phone_number || 'NA'}}
                      </span>
                      <span class="limit-line-length-1" *ngIf="col.data_type === 'FILE'">
                        {{customField?.data?.file_name || 'NA'}}
                      </span>
                      <span class="limit-line-length-1"
                        *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'"
                        [innerHTML]="customField.data || 'NA'">

                      </span>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <!-- <td class="home-text-color">
              <app-table-popover [inputType]="'text'" [inputValue]="item?.name" [labelName]="'Name'" [formName]="'name'"
                (update)="onUpdatePopOver($event, item)"></app-table-popover>
            </td>
            <td>{{item.email ? item.email : "NA"}}</td>
            <td>
              <div class="d-flex align-items-center">
                <span *ngIf="item?.phonenumber">{{item.ccode}}&nbsp;</span>
                <app-table-popover [inputType]="'text'" [cCode]="item?.ccode ? item?.ccode :''"
                  [inputValue]="item?.phonenumber" [labelName]="'Mobile Number'" [formName]="'phonenumber'"
                  (update)="onUpdatePopOver($event, item)"></app-table-popover>
              </div>
            </td> -->
            <!-- <td>
              <app-table-popover [inputType]="'text'" [inputValue]="item?.website" [labelName]="'Website'"
                [formName]="'website'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
            </td> -->
            <!-- <td>{{item.tags}}</td> -->
            <!-- <td>Total contacts</td> -->
            <!-- <td>
              <app-table-popover [inputType]="'dropdown'" [inputValue]="item?.country" [labelName]="'Country'"
                [formName]="'country'" [dropDownItems]="countryjson" [selectedItem]="item.country"
                (update)="onUpdatePopOver($event, item)"></app-table-popover>
            </td> -->
            <!-- <td>
              <app-table-popover [inputType]="'dropdown'"
                [editEnable]="(item.owner === loggedInUser.data.id || loggedInUser.data.role === 'ADMIN')"
                [inputValue]="item.owner_info?.fname + ' ' + item.owner_info?.lname"
                [inputValue]="(item?.owner_info?.fname || '-') + ' ' + (item?.owner_info?.lname || '')"
                [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
                [selectedItem]="item.owner" (update)="onUpdatePopOver($event, item)"></app-table-popover>
            </td> -->

            <!-- <td class="text-capitalize"><span class="py-1 px-2 border-radius-4 w-100 d-flex"
                [ngStyle]="{'background-color': item?.channel ? 'rgba(233, 247, 255, 1)' : ''}">{{
                item.channel ? (item.channel.replace("_", " ") |
                titlecase) : "NA" }}</span></td> -->
            <!-- <td>{{item.updated_at| date: 'd/M/yy, h:mm a'}}</td> -->
            <!-- <td>followers</td>
                    <td>{{item.city}}</td>
                    <td>Number Of Employees</td>
                    <td>Annual Revenue</td>
                    <td>Skype</td> -->
            <!-- <td>Other Phone</td> -->
            <!-- <td>Other Email</td> -->
            <!-- <td>Linkedin</td>
                    <td>Twitter</td>
                    <td>Instagram</td>
                    <td>Facebook</td>
                    <td>State</td>-->
            <!-- <td>{{item.zipcode}}</td> -->
            <!--<td>{{item.address}}</td>
                    <td>Address Line 2</td>
                    <td>Shipping City</td>
                    <td>Shipping State</td>
                    <td>Shipping Country</td>
                    <td>Shipping Zipcode</td>
                    <td>Shipping Address Line 1</td>
                    <td>Shipping Address Line 2</td>
                    <td>Total Activities</td>
                    <td>Done Activities</td>
                    <td>Activities To Do</td>
                    <td>Next Activities Date</td>
                    <td>Last Activities Date</td>
                    <td>Lost Deals</td>
                    <td>Open Deals</td>
                    <td>Closed Deals</td>
                    <td>Industry</td> -->
            <!-- <td>{{(item.created_at | dateConversion) || '-'}}</td> -->
            <td (click)="$event.stopPropagation()" [ngStyle]="{'cursor':!isSelected.multiple ? 'pointer' : 'auto'}">
              <div class="dropdown" ngbDropdown>
                <img class="action-btn bg-white border-radius-8" width="32"
                  src="assets/images/newUI/CRM/action_icon.svg" style="z-index: 99;"
                  [ngStyle]="{'opacity':!isSelected.multiple ? '1' : '0.5'}"
                  [matMenuTriggerFor]="!isSelected.multiple ? menuadd : null" alt="">
                <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                  <button mat-menu-item (click)="openAddCompanyModal(item)" class="py-2">
                    Edit
                  </button>
                  <button mat-menu-item (click)="confirmDelete(item.id)" class="py-2">
                    Delete
                  </button>
                </mat-menu>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
    </div>
    <div>
      <mat-paginator [length]="companiesObj.total" [pageSize]="20" [pageIndex]="pageIndex" (page)="page($event)"
        aria-label="Select page" hidePageSize="true" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>

<ng-template #importExportModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">
      {{companiesJson.label_import}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="" *ngIf="stepper === 1">
      <div class="contact-dotted-border text-center mb-3">
        <input type="file" class="form-control" (change)="onFileChange($event)" accept=".csv" />
      </div>
      <a class="cursor" (click)="downloadSampleFile()">Download Sample
        File</a>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary" [disabled]="!enableImportBtn" (click)="importCompanies()">
          {{companiesJson.label_import}}
        </button>
      </div>
    </div>
    <div class="" *ngIf="stepper === 2">
      <h6 class="fw-600">What do you want to do with the records in the file?</h6>
      <mat-radio-group [(ngModel)]="operation">
        <mat-radio-button value="CREATE">Add as new Company</mat-radio-button>
        <mat-radio-button value="UPDATE">Update existing Company only</mat-radio-button>
        <mat-radio-button value="BOTH">Both</mat-radio-button>
      </mat-radio-group>

      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-light me-2" (click)="modal.dismiss('cross click')">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="fieldsMapping()">
          Next
        </button>
      </div>
    </div>
  </div>
</ng-template>