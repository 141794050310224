import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FILEEXTENSIONS, NOTESJSON } from '../../../../core/data/crm_modal_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/core/service/common.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { PageEvent } from '@angular/material/paginator';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ErrorLoadImage, customDateTimeConversion } from 'src/app/core/common/common-functions';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-notes-crm',
  templateUrl: './notes-crm.component.html',
  styleUrls: ['./notes-crm.component.scss']
})
export class NotesCrmComponent implements OnInit, AfterViewInit, OnInit {
  @Input() is_task!: string;
  @Input() details: any;
  @Input() sourceUserInfo!: any;
  notesJson = NOTESJSON[0];
  notes_filter = 'All Notes';
  contentExpand = false;
  notesTitleDisplay = false;
  notesTitle!: string;
  notesContent!: string;
  visabilityOnHover = false;
  editNoteId!: string;
  attachedFile: any;
  fileChosen: any;
  attachedFileDisplay = false;
  thumbnail: any;
  presignedurl = environment.preSendUrl;
  selected_id!: string;
  selected_type!: string;
  currentPageNotesList: any = [];
  copyCurrentPageNotesList: any = [];
  totalNotesList: any = [];
  CurrentPage = 1;
  files: any;
  divInputBox: any;
  loggedInUser: any;
  notes_total_count!: number;
  pageEvent!: PageEvent;
  showEdit = false;
  currentPage = 0;
  sourceDetails: any;
  fileExtensionsType = FILEEXTENSIONS;
  fileExtensions = '';
  selectedIndex = -1;
  userLists: any[] = [];
  mentionConfig: any = {
    triggerChar: '@',
    maxItems: 10,
    labelKey: 'fname',
    mentionSelect: (mention: any) => {
      return mention['fname'] + " " + mention['lname'] + " ";
    }
  };
  mention: any[] = [];
  visibility: any;
  notesId = '';
  readOnly = false;

  constructor(private route: ActivatedRoute, private location: Location, private service: CommonService, private awsUtilService: AwsUtilsService, private confirmationMsz: ConfirmationMszService, private crm_service: CrmService, private settingServices: SettingsService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    if (this.is_task === 'task') {
      this.selected_id = this.details;
      this.selected_type = this.is_task;
      this.sourceDetails = this.sourceUserInfo;
      this.getAllStaffInfo();
      this.getNotes(this.selected_id, 1);
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.selected_id = params.id;
        this.selected_type = params.showtype;
        this.readOnly = params.contact_active === 'false' ? true : false;
        console.log();
        if (this.selected_id) {
          const notesId = new URLSearchParams(window.location.search).get('noteId');
          if (params?.noteId && notesId) {
            this.notesId = params?.noteId;
            this.getNotesById(params.noteId);
          } else {
            this.notesId = '';
            this.getNotes(this.selected_id, 1);
          }
          if (this.selected_type === 'contact') this.getContactName(this.selected_id);
          else if (this.selected_type === 'lead') this.getLeadsName(this.selected_id);
          else if (this.selected_type === 'company') this.getCompanyName(this.selected_id);
        }
      });
    }
  }

  ngAfterViewInit() {
    this.divInputBox = document.getElementById('NAmyContent');
  }

  clearFilter() {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace(/[?&]noteId=[^&]+/, '').replace(/[?&]noteId=[^&]+/, '');
    this.location.replaceState(updatedUrl);
    this.notesId = '';
    this.getNotes(this.selected_id, 1);
  }

  getNotesById(id: string) {
    this.service.getNotesById(id).subscribe({
      next: (res: any) => {
        this.currentPageNotesList = [res?.data];
        this.parseNotesDataFromList();
      },
      error: (err) => {
        if (err && err?.data.length === 0) {
          console.log(err);
          this.clearFilter();
        }
      }
    });
  }

  getNotes(id: string, page: number) {
    this.service.getAllNotes(id, page).subscribe({
      next: (resp: any) => {
        this.notes_total_count = resp.total;
        this.currentPageNotesList = resp.data;
        this.parseNotesDataFromList();
        // this.totalNotesList.push(...this.currentPageNotesList);
      }
    });
  }
  parseNotesDataFromList() {
    this.copyCurrentPageNotesList = JSON.parse(JSON.stringify(this.currentPageNotesList));
    this.currentPageNotesList.forEach((element: any) => {
      const inputString = element.notes;
      const timeString = element?.updated_at || element?.created_at;
      element['customTime'] = customDateTimeConversion(new Date(timeString).getTime());
      const regex = /{[^{}]*}/g;
      const modifiedString = inputString && inputString.replace(regex, (match: string) => {
        try {
          const jsonObject: any = JSON.parse(match);
          if (Object.keys(jsonObject).length !== 0 && (jsonObject.fname || jsonObject.lname)) {
            const searchString = jsonObject.fname + ' ' + jsonObject.lname;
            return '<span " style="color: #0072f0;">' +
              searchString + `</span>`;
          } else {
            return match;
          }
        } catch (error) {
          console.error('');
          return match;
        }
      });
      element.notes = modifiedString;
    });
  }

  getAllStaffInfo() {
    this.settingServices.getStaff_info().subscribe((response: any) => {
      const ownersInfo = response?.data.find((elem: any) => elem.id === this.sourceDetails.owner);
      ownersInfo && this.userLists.push(ownersInfo);
      this.sourceDetails.assignee.forEach((elemId: any) => {
        const usersInfo = response?.data.find((elem: any) => {
          if (elem.id !== this.loggedInUser['data'].id) {
            return elem.id === elemId;
          } else {
            return;
          }
        });
        usersInfo && this.userLists.push(usersInfo);
      });
    });
    console.log(this.userLists);
  }

  itemSelected(ev: any) {
    this.mention.push(ev);
    setTimeout(() => {
      const searchString = ev.fname + ' ' + ev.lname + ' ';
      const regx = new RegExp(searchString, 'g');
      this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(
        regx,
        '<span class="mention" style="color: #0072f0;" contenteditable="false">' + ev.fname + ' ' + ev.lname + ' ' + `</span>`
      );
      this.notesContent = this.divInputBox.innerText;
      const range = document.createRange();
      range.selectNodeContents(this.divInputBox);
      range.collapse(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 10);
  }
  handleInput() {
    this.notesContent = this.divInputBox.innerText;
  }

  onErrorLoadImage(event: any, target: any, file: any,url:any) {
    if(url){
      event.target.src = ErrorLoadImage(target, file, url);
    }
  }

  getExtensions(data: any) {
    const filtereData: any = this.fileExtensionsType.find((ele: any) => ele.type === data);
    return filtereData['file'];
  }

  expandContent(event: any) {
    this.contentExpand = true;
    const divElement = event.target as HTMLElement;
    divElement.focus();
  }

  displayNotesTitle() {
    this.notesTitleDisplay = true;
  }

  saveNotes(index: number) {
    this.notesTitleDisplay = false;
    const body: any = {
      source_id: this.selected_id,
      source_type: this.selected_type,
      notes: this.notesContent,
      info: {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      }
    };
    let modifiedStr: string = this.notesContent + ' ';
    this.mention.forEach((element: any) => {
      const searchedStr = element['fname'] + ' ' + element['lname'];
      if (modifiedStr.includes(searchedStr)) {
        const obj = {
          id: element.id,
          fname: element.fname,
          lname: element.lname
        };
        const test = modifiedStr.replace(searchedStr, JSON.stringify(obj));
        modifiedStr = test;
      }
    });
    if (this.editNoteId) {
      body["id"] = this.editNoteId;
      body["file_name"] = this.fileChosen ? this.fileChosen : '';
      body["media_url"] = this.attachedFile ? (this.presignedurl + this.attachedFile) : this.thumbnail;
      this.service.updateNotes({ ...body, notes: modifiedStr }).subscribe(() => {
        this.mention = [];
        this.notesId ? this.getNotesById(this.notesId) : this.getNotes(this.selected_id, 1);
        this.notesContent = '';
        this.editNoteId = '';
        this.fileChosen = '';
        this.attachedFile = '';
        this.files = null;
        this.showEdit = false;
        this.contentExpand = false;
        this.updateDivBox('NA');
        const pTag = document.getElementById(index + 'pTag');
        pTag && (pTag.style.display = 'block');
      });
    } else if (this.selected_id) {
      body["file_name"] = this.files ? this.files.name : '';
      body["media_url"] = this.attachedFile ? (this.presignedurl + this.attachedFile) : '';
      body['is_visible'] = false;
      this.service.createNotes({ ...body, notes: modifiedStr }).subscribe({
        next: () => {
          // console.log("notes create - ", resp);
          this.mention = [];
          this.notesId ? this.clearFilter() : this.getNotes(this.selected_id, 1);
          this.notesContent = '';
          this.editNoteId = '';
          this.fileChosen = '';
          this.attachedFile = '';
          this.files = null;
          this.showEdit = false;
          this.contentExpand = false;
          this.updateDivBox('NA');
          const pTag = document.getElementById(index + 'pTag');
          pTag && (pTag.style.display = 'block');
        }
      });
    }

  }

  cancel(index: number) {
    this.updateDivBox('NA');
    const pTag = document.getElementById(index + 'pTag');
    pTag && (pTag.style.display = 'block');
    this.contentExpand = false;
    this.showEdit = false;
    this.notesTitleDisplay = false;
    this.notesContent = '';
    this.mention = [];
    this.editNoteId = '';
    this.divInputBox.innerHTML = '';
    this.fileChosen = '';
    this.thumbnail = '';
  }

  updateDivBox(index: any) {
    setTimeout(() => {
      this.divInputBox = document.getElementById(index + 'myContent');
      this.divInputBox.innerHTML = '';
      this.contentExpand = false;
      this.notesContent = '';
      this.selectedIndex = -1;
    });
  }

  notesFileUpload(e: any) {
    this.thumbnail = '';
    this.files = e.target.files[0];
    // this.thumbnail = e.target.files[0].name;
    // this.thumbnail = '';

    if (this.files.size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    // console.log(e.target.files[0]);
    this.fileChosen = this.files.name;
    this.awsUtilService.getUploadFileLink(e.target.files[0].type,e.target.files[0].name.split('.')[1]).subscribe((resp) => {
      // console.log("first api - ", resp);
      this.fileExtensions = e.target.files[0].name;
      this.thumbnail = resp.data.file_name;
      this.attachedFile = resp.data.file_name;
      this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  removeFile() {
    this.fileChosen = '';
    this.thumbnail = '';
  }

  editNotes(note: any, index: number) {
    this.showEdit = true;
    this.editNoteId = note.id;
    if (note.media_url) {
      this.thumbnail = note.media_url;
      this.fileChosen = note.file_name;
    }
    this.selectedIndex = index;
    const pTag = document.getElementById(index + 'pTag');
    pTag && (pTag.style.display = 'none');
    setTimeout(() => {
      this.divInputBox = document.getElementById(index + 'myContent');
      // ele && ele.scrollTo({ top: 0, behavior: 'smooth' });
      const dummyText = note.notes;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = dummyText;
      this.notesContent = tempElement.innerText;
      this.divInputBox.innerHTML = this.notesContent;
      const inputString = this.copyCurrentPageNotesList[index].notes;
      const regex = /{[^{}]*}/g;
      const jsonObjects = inputString && inputString.match(regex);
      if (jsonObjects) {
        jsonObjects.forEach((element: any) => {
          try {
            const parsedObject = JSON.parse(element);
            const searchString = parsedObject.fname + ' ' + parsedObject.lname;
            const regx = new RegExp(searchString, 'g');
            this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(regx,
              '<span class="mention" style="color: #0072f0;" contenteditable=false>' + searchString + `</span>`);
            this.mention.push(parsedObject);
          } catch (error) {
            console.error('');
          }
        });
      }
    });
    this.contentExpand = true;
  }

  deleteNote(id: any) {
    this.updateDivBox('NA');
    this.showEdit = false;
    this.service.deleteNotes(id).subscribe(() => {
      this.notesId ? this.clearFilter() : this.getNotes(this.selected_id, 1);
    });
  }

  confirmDelete(noteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteNote(noteId);
      }
    });
  }

  toggleVisibility(e: any, data: any) {
    if (!this.showEdit) {
      this.visibility = e.target.checked;
      this.service.updateNotes(data).subscribe(() => {
        // this.notesId ? this.getNotesById(this.notesId) : this.getNotes(this.selected_id, 1);
      });
    }
  }

  onScroll(): void {
    this.getNotes(this.selected_id, ++this.CurrentPage);
  }

  getContactName(id: string) {
    this.crm_service.getContactById(this.selected_id).subscribe({
      next: (resp: any) => {
        this.sourceDetails = resp.data[0];
        this.getAllStaffInfo();
      }
    });
  }

  getLeadsName(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        this.sourceDetails = resp.data[0];
        this.getAllStaffInfo();
        if(this.sourceDetails.type === 'PIPELINE') this.readOnly = true;
      }
    });
  }

  getCompanyName(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (resp: any) => {
        this.sourceDetails = resp.data[0];
        this.getAllStaffInfo();
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getNotes(this.selected_id, this.currentPage);
  }

  ngOnDestroy(): void {
    this.clearFilter();
  }

}
