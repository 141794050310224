<div class="meeting-inner-screen newUI mt-1 col-12 d-flex ">
    <div class="meeting-info border-radius-16 col-3 me-3 height-webkit-fill">
        <div class="main-info border-radius-16 d-flex align-items-center flex-column px-3 pt-3">
            <div style="width: 100px; height: 100px;"
                class="border-radius-50 mb-2 overflow-hidden d-flex align-items-center justify-content-center">
                <img src="assets/images/newUI/HOME/meeting_icon.svg" alt="" width="80" height="80">
            </div>
            <div class="main-info-details d-flex align-items-center flex-column py-1" *ngIf="calendardata">
                <p class="label-text-clr font-size-16 fw-600 m-0">{{calendardata?.booking_name}}</p>
                <div
                    class="px-2 bg-medium fw-600 d-flex align-items-center justify-content-center width-fit-content mt-2">
                    <mat-icon class="d-flex align-items-center">query_builder</mat-icon>
                    <p class="m-0">
                        {{calendardata.duration}} min</p>
                </div>
                <p class="m-0 font-size-13 fw-400 mt-2 pt-1 text-center" style="color: #5A5A5A;word-break: break-all;"
                    [innerHTML]="calendardata.description"></p>
            </div>
        </div>
        <div class="" *ngIf="calendardata">
            <ng-container
                *ngTemplateOutlet="list_view;context:{title:'Date',titleValue:calendardata?.startStr ? (calendardata?.startStr | dateConversion) : '',col:true}"></ng-container>
            <ng-container
                *ngTemplateOutlet="list_view;context:{title:'Starts At',titleValue:calendardata?.start_time,col:true}"></ng-container>
            <ng-container
                *ngTemplateOutlet="list_view;context:{title:'Time Zone',titleValue:calendardata?.staff_timezone,col:true}"></ng-container>
        </div>
        <div class="meeting-action-button p-3 d-flex align-items-center justify-content-between" *ngIf="!disabledMetting">
            <button (mouseenter)="onCustomerMouseEnter()" (cdkCopyToClipboardCopied)="onCustomerClipboardCopy($event)"
                    matTooltip="Copy to clipboard" matTooltipPosition="above" #tp="matTooltip"
                    [cdkCopyToClipboard]="calendardata?.booking_details[0]?.medium_type === 'worke_url' ? calendardata?.booking_details[0].customer_twilio_meeting_url : calendardata?.booking_details[0].meeting_link"
                class="copy-link font-size-12 label-text-clr fw-500 py-2 d-flex align-items-center justify-content-center">
                <mat-icon style="width: 12px; height: 12px;" class="me-1 font-size-12 fw-600"
                    >content_copy</mat-icon>
                    <span class="limit-line-length-1">Copy Link</span>
                
            </button>
            <div style="width: 47%;"
                [matTooltip]="isMeetingEnabled ? '' : 'Meeting link will active 10 minutes prior to the scheduled time.'">
                <button
                    class="join-link btn font-size-12 w-100  label-text-clr fw-500 py-2 d-flex align-items-center justify-content-center"
                    [disabled]="!isMeetingEnabled">
                    <mat-icon style="color: white;width: 16px; height: 16px;"
                        class="me-1 font-size-16 fw-600">videocam</mat-icon>
                    <a [href]="calendardata?.booking_details[0]?.medium_type === 'worke_url' ?
                calendardata?.booking_details[0].customer_twilio_meeting_url :
                calendardata?.booking_details[0].meeting_link" class="text-decoration-none" style="color: white;"
                        target="_blank"> <span class="limit-line-length-1">Join Now</span></a>
                </button>
            </div>
        </div>
    </div>
    <div class="meeting-details col-9 border-radius-16 ms-1 flex-shrink">
        <div class="meeting-details-header py-2 px-3 d-flex align-items-center justify-content-between">
            <p class="m-0 font-size-18 fw-600" style="color: #232323;">Meeting Details</p>
            <div class="d-flex align-items-center">
                <button (click)="openModal(reschedule_modal)" [disabled]="!calendardata?.is_contact_active"
                    *ngIf="calendardata?.booking_status !== 'REJECTED' && calendardata?.booking_status !== 'COMPLETED' && calendardata?.booking_status !== 'NO_SHOW'"
                    class="btn border-radius-8 bg-white limit-line-length-1 d-flex align-items-center me-3">
                    <img src="assets/images/newUI/HOME/reload_icon.svg" class="me-2" alt="">
                    Reschedule Now
                </button>
            </div>
        </div>
        <div class="meeting-details-info auto-hide-scroll-bar overflow-auto" style="height: calc(100vh - 190px);">
            <div>
                <div class="d-flex align-items-center p-3 mt-1">
                    <img src="assets/images/newUI/HOME/contact_icon.svg" alt="">
                    <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Contact Details</p>
                </div>
                <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Booked By',titleValue:calendardata?.name || ''}"></ng-container>
                <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Email Address',titleValue:calendardata?.email || ''}"></ng-container>
                <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Guest Email',titleValue:calendardata?.guest_email[0]?.mail || ''}"></ng-container>
                <ng-container
                    *ngTemplateOutlet="list_view;context:{title:'Phone Number',titleValue:calendardata?.phone_number ? calendardata?.ccode + ' ' + calendardata?.phone_number :''}"></ng-container>
            </div>
            <div>
                <div>
                    <div class="d-flex align-items-center p-3 mt-1">
                        <img src="assets/images/newUI/HOME/calender_icon.svg" alt="">
                        <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Booking Details</p>
                    </div>
                    <ng-container
                        *ngTemplateOutlet="list_view;context:{title:'Booked On',titleValue:calendardata?.created_at | dateConversion}"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="list_view;context:{title:'Status',titleValue:calendardata?.booking_status}"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="list_view;context:{title:'Staff Assigned',titleValue:calendardata?.staff_name ? (calendardata?.staff_name | titlecase) : ''}"></ng-container>
                </div>
            </div>
            <div>
                <div class="reschedule" *ngIf="calendardata?.reschedule_history?.length > 0">
                    <div class="d-flex align-items-center p-3 mt-1">
                        <img src="assets/images/newUI/CRM/calender_icons.svg" class="me-1" alt="">
                        <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Scheduled History</p>
                    </div>
                </div>
                <div class="d-flex align-items-center px-3 py-3"
                    *ngFor="let history of calendardata?.reschedule_history;let i = index"
                    [ngClass]="{'border-bottom': (i+1) < calendardata?.reschedule_history.length}">
                    <p class="fw-700 d-flex align-items-center">
                        {{history?.date | dateConversion}}
                    </p>
                    <span>:</span>
                    <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">
                        {{history?.start_time}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #list_view let-title="title" let-value="titleValue" let-color="color" let-colValue="col">
    <div class="no-gutters bottom-border p-3 cursor d-flex">
        <div class="info-accord" [ngClass]="colValue ? 'col-6' : 'col-2'">
            <p class="text-inner-info m-0 limit-line-length-1" [matTooltip]="title">{{title}}</p>
        </div>
        <!-- <div class="col-md-1"></div> -->
        <div class="info-accord d-flex" [ngClass]="colValue ? 'col-6' : 'col-10'">
            <div class="text-name d-flex text-wrap w-100">
                <span class="pe-1 fw-600">:</span>
                <p class="text-name text-ellipsis cursor m-0" [matTooltip]="value"
                    [ngClass]="title === 'Final Price' ? 'fw-900' : 'fw-500'"
                    *ngIf="title !== 'Status' && title !== 'Staff Assigned'">
                    {{value}}
                </p>
                <div *ngIf="title === 'Status'">
                    <mat-form-field appearance="outline" class="status-select mat-hint-none"
                        *ngIf="value==='WAITING_FOR_ACCEPTANCE'">
                        <mat-icon class="d-inline-flex align-items-center p-0 ps-3"
                            *ngIf="value !=='WAITING_FOR_ACCEPTANCE'"
                            [ngClass]="{'dot-approved': value === 'ACCEPTED','dot-pending': value === 'NO_SHOW','dot-rejected': value === 'REJECTED'}"
                            style="font-size: 9px;" matPrefix>circle</mat-icon>
                        <mat-select [(ngModel)]="calendardata.booking_status" placeholder="Select"
                            (selectionChange)="onStatusChange()" [disabled]="!calendardata?.is_contact_active">
                            <mat-option value="ACCEPTED">Accept</mat-option>
                            <mat-option value="REJECTED">Reject</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="status-select mat-hint-none"
                        *ngIf="value === 'ACCEPTED'">
                        <mat-icon class="d-inline-flex align-items-center p-0 px-2"
                            [ngClass]="{'dot-approved': value === 'ACCEPTED','dot-pending': value === 'NO_SHOW','dot-rejected': value === 'REJECTED'}"
                            style="font-size: 9px; width: auto; height: auto;" matPrefix>circle</mat-icon>
                        <mat-select [(ngModel)]="calendardata.booking_status" (selectionChange)="onStatusChange()"
                            [disabled]="!calendardata?.is_contact_active">
                            <mat-option value="ACCEPTED">Accepted</mat-option>
                            <mat-option value="COMPLETED">Completed</mat-option>
                            <mat-option value="NO_SHOW">No Show</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="value!=='ACCEPTED' && value!=='WAITING_FOR_ACCEPTANCE'"
                        class="badge-soft-info  fw-500 text-center font-size-14 px-3 text-capitalize text-ellipsis"
                        [ngClass]="{'bg-green':value === 'COMPLETED','bg-high':value === 'REJECTED','bg-medium':value === 'NO_SHOW'}">
                        {{value | titlecase}}
                    </div>
                </div>
                <div *ngIf="title === 'Staff Assigned'" class="d-flex align-items-center"
                    [ngClass]="value ? 'assigned-staff' :''">
                    <img src="assets/images/newUI/HOME/contact_icon.svg" alt="" *ngIf="value">
                    <p class="m-0 ps-2 font-size-14 fw-600" [ngStyle]="{'color':value ? '#73321B' : ''}">{{value ||
                        'NA'}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>




<!-- 
<div class="mb-0">
    <div class="p-3">
        <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
            <div class="">
                <p class="fw-600">{{calendardata?.booking_name}}</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-8 border-right-1">
                <div class="mt-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div class="d-flex align-items-center mb-2 cursor"
                            (click)="onNaviagtetoContactInnerScreen(calendardata?.contact_id)">
                            <mat-icon style="color: #00000059;">account_circle</mat-icon>
                            <p class="mb-0 ms-2">{{calendardata?.name}}</p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">mail_outline</mat-icon>
                            <p class="mb-0 ms-2"> {{calendardata?.email}},{{calendardata?.guest_email[0]?.mail}}</p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">call</mat-icon>
                            <p class="mb-0 ms-2" *ngIf="calendardata?.phone_number">
                                {{calendardata?.ccode}}</p>
                            <p class="mb-0 ms-1">{{calendardata?.phone_number}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="me-2">
                            <label class="text-light mb-0 font-size-13">Assigned Staff</label>
                            <p class="fw-600 mb-0 d-flex align-items-center justify-content-end">
                                {{calendardata?.staff_name ? (calendardata?.staff_name | titlecase) : 'NA'}}
                            </p>
                        </div>
                        <mat-icon>account_circle</mat-icon>
                    </div>
                </div>

                <mat-divider class="mx-2"></mat-divider>
                <div class="my-3 border-0 d-flex align-items-center justify-content-between">
                    <div>
                        <label class="mb-1 font-size-14">Booked at</label>
                        <p class="fw-600 mb-0">{{calendardata?.created_at | dateConversion}}</p>
                    </div>
                    <div>
                        <p class="mb-1">Status</p>
                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="calendardata?.booking_status==='WAITING_FOR_ACCEPTANCE'">
                            <mat-icon class="d-inline-flex align-items-center p-0 ps-3"
                                *ngIf="calendardata?.booking_status!=='WAITING_FOR_ACCEPTANCE'"
                                [ngClass]="{'dot-approved': calendardata?.booking_status === 'ACCEPTED','dot-pending': calendardata?.booking_status === 'NO_SHOW','dot-rejected': calendardata?.booking_status === 'REJECTED'}"
                                style="font-size: 9px;" matPrefix>circle</mat-icon>
                            <mat-select [(ngModel)]="calendardata.booking_status" placeholder="Select" class="pt-1"
                                (selectionChange)="onStatusChange()" [disabled]="!calendardata?.is_contact_active">
                                <mat-option value="ACCEPTED">Accept</mat-option>
                                <mat-option value="REJECTED">Reject</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="calendardata?.booking_status==='ACCEPTED'">
                            <mat-icon class="d-inline-flex align-items-center p-0 ps-3"
                                [ngClass]="{'dot-approved': calendardata?.booking_status === 'ACCEPTED','dot-pending': calendardata?.booking_status === 'NO_SHOW','dot-rejected': calendardata?.booking_status === 'REJECTED'}"
                                style="font-size: 9px;" matPrefix>circle</mat-icon>
                            <mat-select [(ngModel)]="calendardata.booking_status" class="pt-1"
                                (selectionChange)="onStatusChange()" [disabled]="!calendardata?.is_contact_active">
                                <mat-option value="ACCEPTED">Accepted</mat-option>
                                <mat-option value="COMPLETED">Completed</mat-option>
                                <mat-option value="NO_SHOW">No Show</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div *ngIf="calendardata?.booking_status!=='ACCEPTED' && calendardata?.booking_status!=='WAITING_FOR_ACCEPTANCE'"
                            class="badge-soft-info border-radius-4 fw-600 text-center font-size-12 p-2 text-capitalize text-ellipsis"
                            style="max-width: 150px;"
                            [ngClass]="{'bg-low':calendardata?.booking_status === 'COMPLETED','bg-high':calendardata?.booking_status === 'REJECTED','bg-medium':calendardata?.booking_status === 'NO_SHOW'}">
                            {{calendardata?.booking_status | titlecase}}
                        </div>
                    </div>
                </div>
                <mat-divider class="mx-2"></mat-divider>
                <div class="my-3 border-0 d-flex align-items-center justify-content-between">
                    <div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mb-2 pb-1"
                                [matTooltip]="isMeetingEnabled ? '' : 'Meeting link will active 10 minutes prior to the scheduled time.'">
                                <button class="btn btn-primary ng-star-inserted px-0" [disabled]="!isMeetingEnabled">
                                    <a [href]="calendardata?.booking_details[0]?.medium_type === 'worke_url' ? calendardata?.booking_details[0].staff_twilio_meeting_url : calendardata?.booking_details[0].meeting_link"
                                        class="px-3 py-2 text-decoration-none" style="color: white;"
                                        target="_blank">Join Now</a>
                                </button>
                            </div>
                        </div>
                        <h6 class="mt-2 fw-600">Customer Join URL
                        </h6>
                        <div class="d-flex justify-content-between align-items-center">

                            <p class="border-light-1 border-radius-6 px-3 py-2 mb-0 me-2 text-light fw-600 text-break"
                                style="width: 450px;">
                                {{calendardata?.booking_details[0]?.medium_type === 'worke_url' ?
                                calendardata?.booking_details[0].customer_twilio_meeting_url :
                                calendardata?.booking_details[0].meeting_link}}</p>
                            <mat-icon style="color:#00000080; cursor:pointer" (mouseenter)="onCustomerMouseEnter()"
                                (cdkCopyToClipboardCopied)="onCustomerClipboardCopy($event)"
                                matTooltip="Copy to clipboard" matTooltipPosition="above" #customer="matTooltip"
                                [cdkCopyToClipboard]="calendardata?.booking_details[0]?.medium_type === 'worke_url' ? calendardata?.booking_details[0].customer_twilio_meeting_url : calendardata?.booking_details[0].meeting_link">content_copy</mat-icon>
                        </div>
                        <p>{{calendardata?.description}}</p>
                    </div>
                    <div style="min-width: 225px;">
                        <div class="d-flex align-items-center justify-content-end">
                            <mat-icon class="d-inline-flex align-items-center ms-2"
                                style="color:#0d543d;font-size: 16px;">calendar_today</mat-icon>
                            <p class="mb-0">Date & time</p>
                        </div>
                        <p class="my-2 fw-700 d-flex align-items-center justify-content-end">
                            {{calendardata?.startStr | dateConversion}}
                        </p>
                        <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">
                            {{calendardata?.start_time}}
                        </p>
                        <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">
                            {{calendardata?.staff_timezone}}
                        </p>
                        <div class="mt-2" *ngIf="calendardata?.reschedule_history?.length > 0">
                            <mat-accordion class="template-accord">
                                <mat-expansion-panel [expanded]="false" class="expansion">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="list-main fw-600 font-size-16 d-flex align-items-center text-primary my-3"
                                                mat-list-item>
                                                <mat-icon class="d-inline-flex align-items-center ms-2"
                                                    style="color:#0d543d;font-size: 16px;">calendar_today</mat-icon>
                                                <p class="mb-0">Scheduled History</p>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ng-container>
                                        <div class=""
                                            *ngFor="let history of calendardata?.reschedule_history;let i = index"
                                            [ngClass]="{'border-bottom': (i+1) < calendardata?.reschedule_history.length}">
                                            <p class="my-2 fw-700 d-flex align-items-center justify-content-end">
                                                {{history?.date | dateConversion}}
                                            </p>
                                            <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">
                                                {{history?.start_time}}
                                            </p>
                                        </div>
                                    </ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div style="overflow-x: hidden;height: 500px;">
                    <div class="p-3">
                        <p class="fw-700">Calendar details</p>
                        <p class="fw-700">{{calendardata?.booking_name}}</p>
                        <button class="btn btn-primary" type="button" (click)="openModal(reschedule_modal)" [disabled]="!calendardata?.is_contact_active"
                            *ngIf="calendardata?.booking_status !== 'REJECTED' && calendardata?.booking_status !== 'COMPLETED' && calendardata?.booking_status !== 'NO_SHOW'">Reschedule</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<ng-template #reschedule_modal let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title fw-600" id="modal-basic-title">Reschedule</h6>
        <mat-icon class="cursor" (click)="modal.dismiss('cross click')">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="calendarForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="fw-600 mb-2 ">Date <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" placeholder="Select Date" matInput [matDatepicker]="picker"
                            formControlName="date" [min]="minDate" (dateChange)="getSlots()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['date'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['date'].errors['required']">Date
                                {{errorJson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 mb-3" *ngIf="slotsData.length !== 0 || noslots">
                    <label class="fw-600 mb-2 ">Time <small class="text-danger">*</small></label><br>
                    <div class="d-inline-block mb-2" *ngFor="let item of slotsData; let i = index">
                        <input style="outline: none" class="btn-check" type="radio" value="{{item.startTime}}"
                            placeholder="Time" id="{{ i+1 }}" formControlName="start_time" />
                        <label for="{{i+1}}" [ngClass]="{'disable': currentTime >= item.startTime}"
                            class="btn btn-light border-1 mx-1 rounded btn-sm btn-md">{{item.startTime}}</label>
                    </div>
                    <div class="error d-flex mt-2" *ngIf="noslots">No Slots Available</div>
                    <div class="error d-flex mt-2" *ngIf="submitted && f['start_time'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <span *ngIf="f['start_time'].errors['required']"> Time
                            {{errorJson.required}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('cross click')">Cancel</button>
        <button type="button" class="btn btn-primary ms-5" (click)="rescheduleCalendar()">Reschedule</button>
    </div>
</ng-template>