import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, debounceTime, takeUntil } from 'rxjs';
import { COMPANIESJSON } from 'src/app/core/data/companies_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { ErrorLoadImage, maximumNumberAllowed, onOnlyNumbers, onParseDomValue, onlyNumberDecimal, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent implements OnInit, AfterViewInit {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChildren('customEditorComponents') customEditorComponents!: QueryList<CKEditorComponent>;
  countryjson = countryData;
  @Input() company: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  companiesJson = COMPANIESJSON[0];
  errorjson = ERRORJSON[0];
  channels = CHANNELS_LIST;
  staffMembers: any = [];
  contactListArray: any = [];
  companyForm!: FormGroup;
  submitted = false;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  info: any = {};
  loading = false;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  selectContact: any;
  newEmail = false;
  loggedInUser: any;
  assignee_departments: any = [];
  tagsListArray: any[] = [];

  customFieldsArray: any[] = [];
  systemFieldsArray: any[] = [];
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  searchSubject: Subject<any> = new Subject<any>();
  presignedurl = environment.preSendUrl;
  editorComponentLists: any[] = [];
  ckeditorLimitLists: any = {};

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private service: CrmService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private _customFields: CustomFieldsService,
    private awsUtilService: AwsUtilsService,
    private toaster: ToastrService
  ) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.term.length >= 3) {
        this.service.getContactsBySearch(searchTerm.term).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (searchTerm.term.length === 0) {
        this.getContactsList();
      }
    });
  }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getContactsList();
    this.organisationMembersList();
    this.intilizeForm();
    this.getTags();
    this.companyForm.get('search')!.valueChanges.subscribe((change: any) => {
      if (change?.length >= 3) {
        this.service.getContactsBySearch(change).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (change?.length === 0) {
        this.getContactsList();
      }
    });


    if (!this.company) {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    } else if (this.company) {
      const dataWithoutTags = onParseDomValue(this.company?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
    }
    this.getCustomFieldsData();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
    this.editorComponentLists = [];
    this.customEditorComponents.changes.subscribe((components: QueryList<CKEditorComponent>) => {
      components.forEach((editorComponent: any) => {
        this.editorComponentLists.push(editorComponent);
      });
    });
  }

  keyDownCustomField(event: any, decimalPoint: number, type: string) {
    return onlyNumberDecimal(event, decimalPoint, type);
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }


  custEditorkeyEvent(editor: any, editorComponents: any, index: any) {
    const edittedItem = this.editorComponentLists.find((elem: any) => elem.id === editorComponents.id);
    if (edittedItem) {
      const inputData = edittedItem.editorInstance.getData();
      const dataWithoutTags = onParseDomValue(inputData);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { actualLimit: dataWithoutTags.length || 0 });
    }
  }

  onCustReady(editor: any, index: any) {
    const object = {
      [index]: {
        maxLimit: 1000,
        actualLimit: 0,
        type: false
      }
    };
    Object.assign(this.ckeditorLimitLists, object);
    const dataWithoutTags = onParseDomValue(editor.data.get());
    this.ckeditorLimitLists[index]['actualLimit'] = dataWithoutTags?.length || 0;
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const edittedItem = this.editorComponentLists.find((elem: any) => elem.editorInstance.id === editor.id);
      const contentLength = pasteEventInCkeditor(edittedItem, pasteContent);
      this.ckeditorLimitLists[index] = Object.assign(this.ckeditorLimitLists[index], { ...contentLength });
    });
  }

  keyDownEvent(event: any, maxLn: number) {
    const maxNum = maximumNumberAllowed(event, maxLn);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  preventDefault() {
    return false;
  }

  intilizeForm() {
    this.companyForm = new FormGroup({
      id: new FormControl(this.company ? this.company.id : ''),
      name: new FormControl(this.company?.name ? this.company.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      email: new FormControl(this.company?.email ? this.company.email : '', Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)),//eslint-disable-line 
      phonenumber: new FormControl(this.company?.phonenumber ? this.company.phonenumber : ''
        // , Validators.compose([Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])
      ),
      // contact : new FormControl(this.company ? this.company.contact : ''),
      address: new FormControl(this.company?.address ? this.company.address : ''),
      owner: new FormControl(this.company?.owner ? this.company.owner : this.loggedInUser.data.id),
      assignee: new FormControl(this.company?.assignee ? this.company.assignee : []),
      city: new FormControl(this.company?.city ? this.company.city : ''),
      country: new FormControl(this.company?.country ? this.company.country : ''),
      zipcode: new FormControl(this.company?.zipcode ? this.company.zipcode : ''
        // , Validators.compose([Validators.pattern("[0-9]+"), Validators.minLength(0), Validators.maxLength(9)])
      ),
      website: new FormControl(this.company?.website ? this.company.website : '', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')),
      description: new FormControl(this.company?.description ? this.company.description : ''),
      channel: new FormControl(this.company?.channel ? this.company.channel : 'manual'),
      contact_id: new FormControl(this.company?.contact_id ? this.company.contact_id : ''),
      contact_name: new FormControl(this.company?.contact_name ? this.company.contact_name : ''
        // , [Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]
      ),
      contact_email: new FormControl(this.company?.contact_email ? this.company.contact_email : ''
        // , Validators.compose([Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])
      ),//eslint-disable-line
      contact_number: new FormControl(this.company?.contact_number ? this.company.contact_number : ''
        // , Validators.compose([Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])
      ),
      contact_ccode: new FormControl(this.company?.contact_ccode ? this.company.contact_ccode : this.authService.getCompanyData().ccode),
      tags: new FormControl(this.company ? this.company.tags : ''),
      related_contact: new FormControl({ value: '', disabled: true }),
      organisation: new FormControl(''),
      search: new FormControl(''),
      ccode: new FormControl(this.company?.ccode ? this.company.ccode : this.authService.getCompanyData().ccode, [Validators.required]),
      custom_company_fields: new FormArray([]),
      system_company_fields: new FormArray([])
    });
    if (this.company) {
      // this.companyForm.controls['email'].disable();
      this.companyForm.controls['channel'].disable();
      this.companyForm.controls['contact_name'].disable();
      this.companyForm.controls['contact_number'].disable();
      this.companyForm.controls['contact_ccode'].disable();
      this.companyForm.controls['contact_email'].disable();
      this.companyForm.controls['contact_id'].disable();
      if (this.company.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
        this.companyForm.controls["assignee"].disable();
        this.companyForm.controls["owner"].disable();
      }
    }
  }

  // createNewEmail = (option: any) => ({ email: option, phonenumber: '' });

  get f() {
    return this.companyForm.controls;
  }

  onSubmit() {
    const inValidCkEditor = Object.entries(this.ckeditorLimitLists).find((ckeditorLimit: any) => ckeditorLimit[1]?.actualLimit > ckeditorLimit[1]?.maxLimit);
    if (!inValidCkEditor) {
      console.log("create company - ", this.companyForm);
      const submit_value = this.companyForm.value;
      submit_value.custom_company_fields.forEach((obj: any, i: number) => {
        if (obj.data_type === "DATE_RANGE") {
          if (obj.end) {
            obj.data = this.convert(obj.start) + '&' + this.convert(obj.end);
            this.custom_company_fields.controls[i].patchValue({ data: obj.data });
          } else if (obj.start) {
            obj.data = this.convert(obj.start);
            this.companyForm?.value.custom_company_fields.controls[i].setValue(obj.data);
          }
        } else if (obj.data_type === "PHONE_NUMBER") {
          obj.data = { custom_ccode: obj.start, custom_phone_number: obj.end };
        }
        // if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
        //   obj.data = "NA";
        // }
      });

      this.submitted = true;
      if (this.companyForm.valid) {
        this.loading = true;
        this.assignee_departments = [];
        let valid_assignee: any;
        let valid_owner: any;

        if (this.companyForm.controls['assignee'].status === 'DISABLED') {
          valid_assignee = this.companyForm.controls['assignee'].value;
        } else {
          valid_assignee = this.companyForm.value.assignee;
        }

        if (this.companyForm.controls['owner'].status === 'DISABLED') {
          valid_owner = this.companyForm.controls['owner'].value;
        } else {
          valid_owner = this.companyForm.value.owner;
        }

        if (valid_assignee.length !== 0) {
          this.staffMembers.forEach((item: any) => {
            valid_assignee.filter((assigne: any) => {
              if (assigne === item.id) {
                this.assignee_departments.push(item.department_info.id ? item.department_info.id : item.utype === 'ADMIN' ? this.loggedInUser.data.organisation : '');
              }
            });
          });
        }
        const owner_department = this.staffMembers.filter((member: any) => member.id === valid_owner);

        submit_value.system_company_fields.forEach((obj: any) => {
          if (obj.data === "" || (!obj.data && typeof obj.data !== 'boolean')) {
            obj.data = "NA";
          }
        });
        this.companyForm.value.system_company_fields.forEach((fields: any) => {
          submit_value[fields.label] = fields.data;
        });
        delete submit_value.system_company_fields;

        if (this.companyForm.value.id) {
          let info_body, valid_mailId;
          // if (this.companyForm.controls["email"].status === 'DISABLED') {
          //   valid_mailId = this.companyForm.controls["email"].value;
          // }
          if (Object.keys(this.info).length === 0) {
            info_body = this.company.info;
          } else {
            info_body = this.info;
          }
          this.service.updateCompany({ ...submit_value, assignee: valid_assignee, owner: valid_owner, info: info_body, assignee_department: this.assignee_departments ? this.assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '' }).pipe(finalize(() => this.loading = false)).subscribe({
            next: () => {
              this.passBack();
              this.ngbActiveModal.dismiss();
            }
          });
        } else {
          if (this.newEmail) {
            this.createContact(owner_department);
          } else {
            this.createCompany(owner_department);
          }
        }
      }
    }
  }

  createCompany(owner_department: any) {
    const submit_value = this.companyForm.value;
    submit_value.custom_company_fields.forEach((obj: any) => {
      if (obj.data === "") {
        obj.data = "NA";
      }
    });
    // const owner_department = this.staffMembers.filter((member: any) => member.id === this.companyForm.value.owner);
    this.service.createCompany({
      ...submit_value, info: this.info, contact_email: this.selectContact ? this.selectContact[0].email : '', contact_number: this.selectContact ? this.selectContact[0].phone_number : '', contact_ccode: this.selectContact ? this.selectContact[0].ccode : '',
      contact_name: this.selectContact ? this.selectContact[0].first_name : '', assignee_department: this.assignee_departments ? this.assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : ''
    }).pipe(finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.passBack();
        this.ngbActiveModal.dismiss();
      }
    });
  }

  createContact(owner_department: any) {

    // const owner_department = this.staffMembers.filter((member: any) => member.id === this.companyForm.value.owner);
    this.settingsService.getStaff_info().subscribe((data: any) => {
      const loggedMember = data.data.filter((staff: any) => staff.id === this.user_details.data.id);
      const body = {
        custom_contact_fields: [],
        phone_number: this.companyForm.value.contact_number,
        ccode: this.companyForm.value.contact_ccode,
        email: this.companyForm.value.contact_email?.toLowerCase(),
        first_name: this.companyForm.value.contact_name,
        info: {
          owner_info: {
            fname: loggedMember[0].fname,
            lname: loggedMember[0].lname
          }
        },
        owner: this.user_details.data.id,
        owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : owner_department[0].utype === 'ADMIN' ? this.loggedInUser.data.organisation : '',
        channel: 'manual',
        company_id: '',
        company_name: ''
      };
      this.service.createContact(body).pipe(finalize(() => this.loading = false)).subscribe({
        next: (resp: any) => {
          this.newEmail = false;
          this.selectContact = [resp.data];
          this.companyForm.patchValue({ contact_id: resp.data?.id, contact_name: resp.data.first_name });
          this.createCompany(owner_department);
        }
      });
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getContactsList() {
    this.service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
      }
    });
  }

  addNewName = (name: any) => ({ first_name: name, id: '' });

  // searchContact(term: any) {
  //   if (term.term.length >= 3) {
  //     this.service.getContactsBySearch(term.term).subscribe((result: any) => {
  //       this.contactListArray = result.data;
  //     });
  //   } else if (term.term.length === 0) {
  //     this.getContactsList();
  //   }
  // }

  onContactChange(data: any) {
    console.log(data, "data");
    if (data?.id) {
      this.newEmail = false;
      this.selectContact = this.contactListArray.filter((contact: any) => contact.id === data.id);
      this.companyForm.patchValue({
        contact_email: this.selectContact[0].email, contact_number: this.selectContact[0].phone_number, contact_ccode: this.selectContact[0].ccode,
        contact_name: this.selectContact[0].first_name, contact_id: this.selectContact[0].id
      });
      this.companyForm.controls['contact_number'].disable();
      this.companyForm.controls['contact_ccode'].disable();
      this.companyForm.controls['contact_email'].disable();
      this.companyForm.controls['contact_id'].setValidators(Validators.compose([Validators.required]));
      this.companyForm.controls['contact_id'].updateValueAndValidity();
    } else {
      this.newEmail = true;
      this.companyForm.patchValue({ contact_id: data.id, contact_name: data.first_name });
      this.companyForm.controls['contact_id'].clearValidators();
      this.companyForm.controls['contact_id'].updateValueAndValidity();
    }
  }

  clearContactFields(e: any) {
    console.log(e, "clear event");
    this.companyForm.controls['contact_number'].enable();
    this.companyForm.controls['contact_ccode'].enable();
    this.companyForm.controls['contact_email'].enable();
    this.companyForm.patchValue({ contact_email: '', contact_id: '', contact_number: '', contact_ccode: '', contact_name: '' });
  }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
    if(this.companyForm.value?.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN'){
      this.companyForm.controls['assignee'].disable();
    } else {
      this.companyForm.controls['assignee'].enable();
    }
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  passBack() {
    this.passEntry.emit(true);
  }

  getTags() {
    this.settingsService.getTags('company').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  //*******************************Custom Fields START******************************************** *//

  getCustomFieldsData() {
    this._customFields.getCompanyModelFields().subscribe({
      next: (resp: any) => {
        this.customFieldsArray = resp.data.custom_company_templates;


        this.systemFieldsArray = Object.entries(resp.data);
        // eslint-disable-next-line no-shadow
        let count = 0;
        this.systemFieldsArray.map((field: any) => {
          if (!field[1].is_default && field[1].on_form) {
            this.system_company_fields.push(this.custom_fields({ ...field[1], label: field[0], data: this.company ? this.company[field[0]] : "" }));
            if (field[1].is_mandatory) {
              this.system_array(count).controls['data'].setValidators(Validators.required);
              this.system_array(count).controls['data'].updateValueAndValidity();
            }
            count++;
          }
        });

        if (this.customFieldsArray) {
          // eslint-disable-next-line no-shadow
          let count = 0;
          for (const data of this.customFieldsArray) {
            if (data.on_form) {
              this.addfieldsData(data);
              if (data.is_mandatory) {
                this.custom_array(count).controls['data'].setValidators(Validators.required);
                this.custom_array(count).controls['data'].updateValueAndValidity();
              }
              count++;
            }
          }
        }
        if (this.company) {
          this.addFieldValues();
        }
      }
    });
  }

  get custom_company_fields(): FormArray {
    return this.companyForm.get("custom_company_fields") as FormArray;
  }

  get system_company_fields(): FormArray {
    return this.companyForm.get("system_company_fields") as FormArray;
  }

  addfieldsData(data: any) {
    this.custom_company_fields.push(this.custom_fields(data));
  }

  addFieldValues() {
    const copyCustomFiledArray = this.customFieldsArray.filter((elem: any) => elem.on_form);
    copyCustomFiledArray.forEach(async (field: any, i) => {
      const index = this.company.custom_company_fields.findIndex(function (item: any) {
        return item.custom_company_template === field.id;
      });

      // console.log('OuterIndex',i,'Inner Index',index);
      // console.log(field, this.company.custom_company_fields);
      if (index >= 0) {
        if (this.company.custom_company_fields[index].data_type === 'DATE_RANGE') {
          this.custom_company_fields.controls[i].patchValue({
            start: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data.split('&')[0] : "",
            end: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data.split('&')[1] : "",
            data: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data : "",
            id: this.company.custom_company_fields[index].id
          });
        } else if (this.company.custom_company_fields[index].data_type === 'PHONE_NUMBER') {
          this.custom_company_fields.controls[i].patchValue({
            start: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data?.custom_ccode : "",
            end: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data.custom_phone_number : "",
            data: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data : "",
            id: this.company.custom_company_fields[index].id
          });
        } else {
          this.custom_company_fields.controls[i].patchValue({
            data: this.company.custom_company_fields[index].data !== "NA" ? this.company.custom_company_fields[index].data : "",
            id: this.company.custom_company_fields[index].id
          });
        }
      }
    });
  }

  custom_fields(data: any): FormGroup {

    const tempData = data?.data ?
      (data.data !== "NA" ?
        (data.data_type === 'CHECK_BOX' ?
          [] : data.data_type === 'RADIO_BUTTON' ?
            data.data ? true : false : data.data) : "") : data?.data === false ? false : "";
    return new FormGroup({
      id: new FormControl(""),
      label: new FormControl(data?.label ? data.label : ''),
      data_type: new FormControl(data?.data_type ? data.data_type : ''),
      options: new FormControl(data?.options ? data.options : ''),
      on_form: new FormControl(data?.on_form ? data.on_form : true),
      is_inner_screen: new FormControl(data?.is_inner_screen ? data.is_inner_screen : false),
      data: new FormControl(tempData),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false),
      is_active: new FormControl(data?.is_active ? data.is_active : true),
      custom_company_template: new FormControl(data?.id ? data.id : ''),
      company: new FormControl(this.company?.id),
      organisation: new FormControl(this.loggedInUser.data.organisation),
      start: new FormControl(''),
      end: new FormControl('')
    });
  }

  custom_array(count: number): any {
    return this.custom_company_fields.controls[count] as FormArray;
  }

  system_array(count: number): any {
    return this.system_company_fields.controls[count] as FormArray;
  }

  toggleCheckbox(value: string, i = 0) {
    const selectedOptionsControl = this.custom_array(i).get('data');
    const currentValue = selectedOptionsControl.value;


    if (currentValue.includes(value)) {
      selectedOptionsControl.setValue(currentValue.filter((item: any) => item !== value));
    } else {
      currentValue.push(value);
      selectedOptionsControl.setValue(currentValue);
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getFieldValidity(arrayName: any, i: number) {
    this.companyForm.markAllAsTouched();
    return (<FormArray>this.companyForm.get(arrayName)).controls[i].invalid;
  }

  //custom type File upload function
  onFileUpload(ev: any, index: number) {
    if (!ev.target.files[0]) return;
    if (ev.target.files[0].size > 15 * 1048576) {
      this.toaster.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(ev.target.files[0].type, ev.target.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        this.custom_company_fields.controls[index].patchValue({ data: { path: this.presignedurl + resp.data.file_name, file_name: ev.target.files[0].name } });
        this.awsUtilService.uploadFile(ev.target.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
          }
        });
      }
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }
}
