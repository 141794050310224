import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, debounceTime } from 'rxjs';
import { onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { TaskBoardService } from 'src/app/core/service/task-board.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {
  @Input() task: any = {};
  @Input() stage!: string;
  @Input() is_global!: boolean;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  taskForm!: FormGroup;
  submitted = false;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  errorJson = ERRORJSON[0];
  staffMembers: any = [];
  related_to: any = [];
  stagesList: any = [];
  info: any = {};
  selected_id!: string;
  selected_type!: string;
  pre_selected = false;
  previous_stage!: string;
  minDate = new Date();
  source_name: any;
  loggedInUser: any;
  tagsListArray: any;
  featuresData: any;
  selectedContactDetails: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  disabledFields = false;

  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  private routeSubscription!: Subscription;
  constructor(private route: ActivatedRoute, private service: TaskBoardService, private settingsService: SettingsService, private client_service: ClientsService, private crm_Service: CrmService, private client_Service: ClientsService, private ngbActiveModal: NgbActiveModal, private authService: AuthService) { }

  ngOnInit() {
    this.routeSubscription = this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.getTags();
      this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      this.getFeatues();
      // console.log(this.selected_id, this.selected_type, this.is_global);
      if (this.selected_id && !this.is_global) {
        if (this.selected_type !== 'deal') this.getStages(this.selected_id);
        if (this.selected_type === 'lead') {
          this.getLeadsById(this.selected_id);
        } else if (this.selected_type === 'contact') {
          this.getContactsById(this.selected_id);
        } else if (this.selected_type === 'company') {
          this.getCompaniesById(this.selected_id);
        } else if (this.selected_type === 'project') {
          this.getProjectById(this.selected_id);
        } else if (this.selected_type === 'deal') {
          this.getCardById();
          this.getStages(params.pipeline_id);
        }
      }
    });
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.intilizeForm();
    this.previous_stage = this.taskForm.value.current_stage;
    console.log(this.task);

    if (this.stage) {
      this.taskForm.patchValue({ current_stage: this.stage });
    }
    if (this.is_global) {
      this.taskForm.controls['related_to_type'].setValidators(Validators.required);
    }
    // this.organisationMembersList();
    this.taskForm.get('search')!.valueChanges.pipe(debounceTime(300)).subscribe((change: any) => {
      // if (change.length >= 3) {
      if (this.selected_type === 'lead' && this.is_global === true) {
        this.searchLeads(change);
      } else if (this.selected_type === 'contact' && this.is_global === true) {
        this.searchContact(change);
      } else if (this.selected_type === 'company' && this.is_global === true) {
        this.searchCompanies(change);
      } else if (this.selected_type === 'project' && this.is_global === true) {
        this.searchProjects(change);
      }
      // }
    });

    if (!this.task?.id) {
      this.info = this.loggedInUser.data;
    } else if (this.task?.id) {
      const dataWithoutTags = onParseDomValue(this.task?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
      this.onRelatedTochange({ value: this.task.source_type });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }
  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }


  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  intilizeForm() {
    this.taskForm = new FormGroup({
      id: new FormControl(this.task ? this.task.id : ''),
      name: new FormControl(this.task ? this.task.name : '', [Validators.required]),
      priority: new FormControl(this.task ? this.task.priority : 'Medium', [Validators.required]),
      source_id: new FormControl(this.task ? this.task.source_id : '', [Validators.required]),
      assignee: new FormControl(this.task ? this.task.assignee : []),
      due_date: new FormControl(this.task ? this.task.due_date : ''),
      current_stage: new FormControl(this.task ? this.task.current_stage : '', [Validators.required]),
      description: new FormControl(this.task ? this.task.description : ''),
      client_visibility: new FormControl(this.task ? (this.task.client_visibility ? this.task.client_visibility : false) : false),
      related_to_type: new FormControl(this.task ? this.task.source_type : ''),
      search: new FormControl(''),
      source_name: new FormControl({ value: '', disabled: true }),
      owner: new FormControl(this.task ? this.task.owner : '', [Validators.required]),
      owner_department: new FormControl(this.task ? this.task.owner_department : ''),
      tags: new FormControl(this.task ? this.task.tags : '')
    });

    if (this.task && this.task.id) {
      this.taskForm.controls["source_id"].disable();
      this.taskForm.controls["related_to_type"].disable();
      if (this.taskForm.value.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
        this.taskForm.controls["assignee"].disable();
        this.taskForm.controls["owner"].disable();
        this.disabledFields = true;
      } else {
        this.disabledFields = false;
      }
    } 
  }

  addStaffToList(member: any) {
    this.info = member;
  }

  get f() {
    return this.taskForm.controls;
  }

  onTaskSubmit() {
    this.submitted = true;
    // console.log(this.taskForm);
    if (this.taskForm.valid) {
      const owner_department: any = [];
      const staff_info_array: any = [];
      let valid_assignee: any;
      let valid_owner: any;
      let related_to_type:any;
      let source_id:any;


      if (this.taskForm.controls['assignee'].status === 'DISABLED') {
        valid_assignee = this.taskForm.controls['assignee'].value;
        this.taskForm.controls['assignee']?.value?.forEach((item: any) => {
          const staff_obj = this.staffMembers.filter((member: any) => member.id === item);
          // console.log(staff_obj);
          staff_info_array.push(staff_obj[0]);
          if (staff_obj[0].department_info?.id) {
            if (!owner_department.includes(staff_obj[0].department_info?.id)) owner_department.push(staff_obj[0].department_info?.id);
          }

        });
      } else {
        valid_assignee = this.taskForm.value.assignee;
        this.taskForm.value?.assignee?.forEach((item: any) => {
          const staff_obj = this.staffMembers.filter((member: any) => member.id === item);
          // console.log(staff_obj);
          staff_info_array.push(staff_obj[0]);
          if (staff_obj[0].department_info?.id) {
            if (!owner_department.includes(staff_obj[0].department_info?.id)) owner_department.push(staff_obj[0].department_info?.id);
          }

        });
      }

      if (this.taskForm.controls['owner'].status === 'DISABLED') {
        valid_owner = this.taskForm.controls['owner'].value;
      } else {
        valid_owner = this.taskForm.value.owner;
      }
      if (this.taskForm.controls['source_id'].status === 'DISABLED') {
        source_id = this.taskForm.controls['source_id'].value;
      } else {
        source_id = this.taskForm.value.owner;
      }
      if (this.taskForm.controls['related_to_type'].status === 'DISABLED') {
        related_to_type = this.taskForm.controls['related_to_type'].value;
      } else {
        related_to_type = this.taskForm.value.owner;
      }
      // console.log(this.taskForm.value);

      if (this.taskForm.value.id) {
        this.service.updateTask(this.selected_id, this.selected_type, { ...this.taskForm.value, info: { staff_info_array }, assignee_department: owner_department, assignee: valid_assignee ? valid_assignee : [], owner: valid_owner, related_to_type: related_to_type, source_id: source_id }).subscribe({
          next: (resp: any) => {
            this.passBack(resp.data.current_stage);
            // console.log("previous - ", this.previous_stage, "current - ", resp.data.current_stage);

            this.ngbActiveModal.dismiss();
          }
        });
      } else {
        this.service.createTask(this.taskForm.value.source_id, this.selected_type, { ...this.taskForm.value, source_name: this.source_name, info: { staff_info_array }, assignee_department: owner_department, assignee: valid_assignee ? valid_assignee : [], owner: valid_owner, related_to_type: related_to_type, source_id: source_id }).subscribe({
          next: (resp: any) => {
            this.passBack(resp.data.current_stage);
            // console.log("previous - ", this.previous_stage, "current - ", resp.data.current_stage);

            this.ngbActiveModal.dismiss();
          }
        });
      }
    }

  }

  selectedSource(id: string, name: string, source_data: any) {
    this.getStages(id);
    this.source_name = name;
    let staffIds: any = [];

    if (this.selected_type === 'contact') {
      this.selectedContactDetails = source_data;
      console.log(this.selectedContactDetails);
      if ((this.selectedContactDetails.owner_info.id !== this.loggedInUser.data.id && !this.selectedContactDetails.assignee.includes(this.loggedInUser.data.id)) && this.loggedInUser.data.role !== 'ADMIN') {
        this.taskForm.controls["assignee"].disable();
        this.taskForm.controls["owner"].disable();
        this.disabledFields = true;
      } else {
        this.taskForm.patchValue({ owner: this.loggedInUser.data.id });
        this.disabledFields = false;
      }
    }
    // console.log(source_data, "selected source");

    // if (this.selected_type === 'project') {
    //   staffIds = source_data.staff_ids;
    // } else {
    staffIds = source_data?.assignee;
    // }
    if (!staffIds.includes(source_data.owner)) {
      staffIds.push(source_data.owner);
    }
    if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
  }

  getStages(id: string) {
    this.service.getStages(id, this.selected_type).subscribe({
      next: (resp: any) => {
        this.stagesList = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  searchLeads(name: string) {
    if (name?.length >= 3) {
      this.crm_Service.getLeadsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
          if (!this.is_global) {
            this.taskForm.patchValue({ source_id: this.selected_id });
          }
        }
      });
    } else if (name?.length === 1) {
      this.getEnquiryList();
    }
  }

  getLeadsById(source_id: string) {
    this.crm_Service.getLeadById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].requirement_title });
        this.source_name = resp.data[0].requirement_title;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;
        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getSourceStaffList(body: any) {
    this.service.getStaffForSource({ id: body }).subscribe({
      next: (resp: any) => {
        // console.log(resp, "ONLY SOURCE STAFF");
        this.staffMembers = resp.data;
        const current_user = this.staffMembers.filter((ele: any) => ele.id === this.loggedInUser.data.id);
        if (current_user) this.taskForm.controls['owner'].setValue(current_user[0].id);
      }
    });
  }

  searchContact(name: string) {
    if (name?.length >= 3) {
      this.crm_Service.getContactsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
          if (!this.is_global) {
            this.taskForm.patchValue({ source_id: this.selected_id });
          }
        }
      });
    } else if (name?.length === 1) {
      this.getContactsList();
    }
  }

  getContactsById(source_id: string) {
    this.crm_Service.getContactById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].first_name + " " + resp.data[0].last_name });
        this.source_name = resp.data[0].first_name + " " + resp.data.last_name;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;

        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }

        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  searchCompanies(name: string) {
    if (name?.length >= 3) {
      this.crm_Service.getCompaniesBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
          if (!this.is_global) {
            this.taskForm.patchValue({ source_id: this.selected_id });
          }
        }
      });
    } else if (name?.length === 1) {
      this.getCompaniesList();
    }
  }

  getCompaniesById(source_id: string) {
    this.crm_Service.getCompanyById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].name });
        this.source_name = resp.data[0].name;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;
        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  searchProjects(name: string) {
    if (name?.length >= 3) {
      this.client_Service.getAllProjectsBySearch(name).subscribe({
        next: (resp: any) => {
          this.related_to = resp.data;
          if (!this.is_global) {
            this.taskForm.patchValue({ source_id: this.selected_id });
          }
        }
      });
    } else if (name?.length === 1) {
      this.getProjectList();
    }
  }

  getProjectById(source_id: string) {
    this.client_Service.getProjectDetailsById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data.id, source_name: resp.data.name });
        this.source_name = resp.data.name;
        let staffIds: any = [];
        if (resp.data.assignee) staffIds = resp.data.assignee;
        if (!staffIds.includes(resp.data.owner)) {
          staffIds.push(resp.data.owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getContactsList() {
    this.crm_Service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;

      }
    });
  }
  getCompaniesList() {
    this.crm_Service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }
  getEnquiryList() {
    this.crm_Service.getAllLeads(1, 'ACTIVE').subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }
  getProjectList() {
    this.crm_Service.getProjectDropdownList().subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  getCardById() {
    this.crm_Service.getCardsById(this.selected_id).subscribe((cards: any) => {
      this.taskForm.patchValue({ source_id: cards?.data[0].id, source_name: cards?.data[0].contact_first_name });
      let staffIds: any = [];
      staffIds = cards.data[0].assignee;
      if (!staffIds.includes(cards.data[0].owner)) {
        staffIds.push(cards.data[0].owner);
      }
      if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
    });
  }

  // getAllPipelines() {
  //   this.crm_Service.getPipelines().subscribe({
  //     next: (resp: any) => {
  //       this.related_to = resp.data;
  //     }
  //   });
  // }

  cancelTaskModal() {
    this.ngbActiveModal.dismiss();
  }

  passBack(stage_id: string) {
    this.passEntry.emit({ update: true, current_stage: stage_id, previous_stage: this.previous_stage });
  }

  onRelatedTochange(ev: any) {
    this.selectedContactDetails = null;
    switch (ev.value) {
      case 'lead':
        this.featuresData?.enquiry && this.getEnquiryList();
        break;
      case 'contact':
        this.featuresData?.contact && this.getContactsList();
        break;
      case "company":
        this.featuresData?.company && this.getCompaniesList();
        break;
      case "project":
        this.featuresData?.projects && this.getProjectList();
        break;
      default:
        break;
    }

    this.selected_type = ev.value;
    // if (this.selected_type === 'deal') this.getAllPipelines();
  }

  selectedOwner(owner_data: any) {
    if (this.taskForm.value?.owner !== this.loggedInUser.data.id && this.loggedInUser.data.role !== 'ADMIN') {
      this.taskForm.controls['assignee'].disable();
    } else {
      this.taskForm.controls['assignee'].enable();
    }
    // console.log(owner_data.department_info.id, "oner data");
    this.taskForm.patchValue({ owner_department: owner_data?.department_info ? owner_data.department_info.id : '' });
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  getTags() {
    this.settingsService.getClientTags('task').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
