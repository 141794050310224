<div class=" h-28rem overflow-auto" style="height: calc(100vh - 224px);">
    <div class="d-flex justify-content-between flex-column w-100 h-100">
        <div class="table-responsive mt-3">
            <table class="table table-sm align-middle table-nowrap table-hover mb-0">
                <thead>
                    <tr>
                        <th scope="col">{{projectJson.col_one_label}}</th>
                        <th scope="col">{{projectJson.col_two_label}}</th>
                        <th scope="col">{{projectJson.col_three_label}}</th>
                        <th scope="col">{{projectJson.col_four_label}}</th>
                        <th scope="col">{{projectJson.col_five_label}}</th>
                    </tr>
                </thead>
                <!-- -->

                <tbody>
                    <tr class="cursor" *ngFor="let item of projectsArray; let i = index"
                        [routerLink]="['../../clients/client-managment']"
                        [queryParams]="{ id: item?.id, showtype: 'project'}">
                        <td class="py-2">
                            {{ item.name ? item.name : "NA" }}
                        </td>
                        <td>
                            {{ item.project_status ? item.project_status : "NA" }}
                        </td>

                        <td>{{ item.client_name ? item.client_name : "NA" }}</td>
                        <td>
                            {{ item.cost ? item.cost : "NA" }}
                        </td>
                        <td *ngIf="item.due_date">
                            {{ item.due_date | dateConversion}}
                        </td>
                        <td *ngIf="!item.due_date">NA</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <mat-paginator [length]="totalCount" [pageSize]="20" (page)="page($event)" aria-label="Select page"
            hidePageSize="true" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>